import React, { useEffect, useState } from "react";
import { Container, Modal, Form, Button, Row, Col } from "react-bootstrap";
import { Formik, Field } from "formik";
import { Select, FormControl, InputLabel, MenuItem } from "@mui/material";
import * as Yup from "yup";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { RootState } from "../../../../store/store";
import { getUserAsync } from "../../../../slices/auth/authSlice";
import {
	getAllLabourAsync,
	getAllMaterialsAsync,
	getAllStagesAsync,
} from "slices/settings/settingsSlice";

import { toast } from "react-toastify";

interface CreateStoreRequestValues {
	company_id: string;
	labour_type_id: string;
	stage_id: string;
	material_id: string;
	quantity: string;
	collected_by: string;
}
interface CreateStoreRequestProps {
	closeModal: () => void;
	show: boolean;
	onCreateSuccess: () => void;
}
const CreateStoreRequest: React.FC<CreateStoreRequestProps> = ({
	show,
	closeModal,
	onCreateSuccess,
}) => {
	const dispatch = useAppDispatch();

	const companyId = localStorage.getItem("userCompanyID");
	const materials = useAppSelector(
		(state: RootState) => state.settings.materials,
	);

	const labourTypes = useAppSelector(
		(state: RootState) => state.settings.labourTypes,
	);

	const stages = useAppSelector((state: RootState) => state.settings.stages);

	useEffect(() => {
		dispatch(getUserAsync());
	}, [dispatch]);

	useEffect(() => {
		if (companyId) {
			dispatch(getAllMaterialsAsync(companyId));
		}
	}, [dispatch, companyId]);

	useEffect(() => {
		if (companyId) {
			dispatch(getAllLabourAsync(companyId));
		}
	}, [companyId, dispatch]);

	useEffect(() => {
		if (companyId) {
			dispatch(getAllStagesAsync(companyId));
		}
	}, [dispatch, companyId]);

	return (
		<Container fluid className="">
			<Modal size="lg" show={show} onHide={closeModal} centered>
				<div className="px-3 py-2">
					<Formik
						initialValues={{
							company_id: companyId || "",
							material_id: "",
							labour_type_id: "",
							stage_id: "",
							quantity: "",
							collected_by: "",
						}}
						validationSchema={Yup.object().shape({
							company_id: Yup.string().required("Required"),
							material_id: Yup.string().required("Required"),
							labour_type_id: Yup.string().required("Required"),
							stage_id: Yup.string().required("Required"),
							quantity: Yup.string().required("Required"),
							collected_by: Yup.string().required("Required"),
						})}
						onSubmit={(values: CreateStoreRequestValues, { setSubmitting }) => {
							console.log(values);

							// dispatch(
							// 	createBudgetAsync({
							// 		...values,
							// 		company_id: companyId || "",
							// 	}),
							// )
							// 	.unwrap()
							// 	.then((response) => {
							// 		toast.success("Store request created successfully");

							// 		onCreateSuccess();
							// 		closeModal();
							// 	})
							// 	.catch((error) => {
							// 		const errorMessage = Object.values(error.message)
							// 			.flat()
							// 			.join(" ");
							// 		toast.error(errorMessage);
							// 	})
							// 	.finally(() => {
							// 		setSubmitting(false);
							// 	});
						}}
					>
						{({
							handleSubmit,
							isSubmitting,
							handleChange,
							handleBlur,
							touched,
							errors,
							values,
						}) => (
							<Form onSubmit={handleSubmit}>
								<Modal.Header className="text-start mt-2" closeButton>
									<h5> Create store request </h5>
								</Modal.Header>
								<Modal.Body>
									<Row>
										<Col md={6} className="mt-1 w-100">
											<label className="mt-1">
												Material <span className="text-danger">*</span>
												<p></p>
											</label>
											<FormControl fullWidth>
												<InputLabel>Select material</InputLabel>
												<Select
													label="Select material"
													name="material_id"
													value={values.material_id}
													onChange={handleChange}
												>
													{materials.map((material) => (
														<MenuItem key={material.id} value={material.id}>
															{material.material_name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
											<div className="invalid-feedback d-block">
												{touched.material_id && errors.material_id}
											</div>
										</Col>
									</Row>
									<Row>
										<Col md={6} className="mt-1 w-100">
											<label className="mt-1">
												Labour type <span className="text-danger">*</span>
												<p></p>
											</label>
											<FormControl fullWidth>
												<InputLabel>Select labour type</InputLabel>
												<Select
													label="Select material"
													name="labour_type_id"
													value={values.labour_type_id}
													onChange={handleChange}
												>
													{labourTypes.map((labour) => (
														<MenuItem key={labour.id} value={labour.id}>
															{labour.labour_type}
														</MenuItem>
													))}
												</Select>
											</FormControl>
											<div className="invalid-feedback d-block">
												{touched.material_id && errors.material_id}
											</div>
										</Col>
									</Row>
									<Row>
										<Col md={6} className="mt-1 w-100">
											<label className="mt-1">
												Stage <span className="text-danger">*</span>
												<p></p>
											</label>
											<FormControl fullWidth>
												<InputLabel>Select stage</InputLabel>
												<Select
													label="Select stage"
													name="stage_id"
													value={values.stage_id}
													onChange={handleChange}
												>
													{stages.map((stage) => (
														<MenuItem key={stage.id} value={stage.id}>
															{stage.stage}
														</MenuItem>
													))}
												</Select>
											</FormControl>
											<div className="invalid-feedback d-block">
												{touched.stage_id && errors.stage_id}
											</div>
										</Col>
									</Row>
									<Row>
										<Col md={6} className="mt-1 w-100">
											<label className="mt-1">
												<p>
													Quantity <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="number"
												name="quantity"
												placeholder="Enter a number"
												className={`fluent-input ${
													touched.quantity && errors.quantity
														? "is-invalid"
														: ""
												}`}
												min={0}
												// Making sure only positive numbers are allowed
												onKeyPress={(
													e: React.KeyboardEvent<HTMLInputElement>,
												) => {
													const regex = /^[0-9]$/;
													if (!regex.test(e.key)) {
														e.preventDefault();
													}
												}}
												required
											/>
										</Col>
									</Row>
									<Row>
										<Col md={6} className="mt-1 w-100">
											<label className="mt-1">
												<p>
													Collected by <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="collected_by"
												placeholder="Enter a name"
												className={`fluent-input ${
													touched.collected_by && errors.collected_by
														? "is-invalid"
														: ""
												}`}
												required
											/>
										</Col>
									</Row>

									<Modal.Footer className="mt-3">
										<Row>
											<Col md={6}>
												<div className=" mt-1">
													<Button onClick={closeModal} className="cancel-btn">
														<span className="text-center">Cancel </span>{" "}
													</Button>
												</div>
											</Col>
											<Col md={6}>
												<div className=" mt-1">
													<Button
														type="submit"
														className=" border-0 create-btn bg-color-1"
													>
														<span className="text-center">Create </span>{" "}
													</Button>
												</div>
											</Col>
										</Row>
									</Modal.Footer>
								</Modal.Body>
							</Form>
						)}
					</Formik>
				</div>
			</Modal>
		</Container>
	);
};

export default CreateStoreRequest;
