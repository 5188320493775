import React, { useState, useEffect } from 'react';
import SettingsLayout from '../settings-layout';
import { Table, Button, Row, Col, Dropdown } from 'react-bootstrap';
import CreateVendor from './modals/create-vendor';
import EditVendor from './modals/edit-vendor';
import ViewVendor from './modals/view-vendor';
import Action from "../../../assets/images/action-vector.png";
import { getAllVendorsAsync, getAllBanksAsync } from '../../../slices/settings/settingsSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { RootState } from '../../../store/store';
import CloseButton from "../../../components/close-button/close-button";


interface VendorsProps { }

const Vendors: React.FC<VendorsProps> = () => {
    const dispatch = useAppDispatch();
    const vendors = useAppSelector(state => state.settings.vendors)
    const companyId = useAppSelector((state: RootState) => state.auth.user?.company_id || '');
    const [selectedVendorId, setSelectedVendorId] = useState<string | null>(null);

    const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
    const handleOpenCreateModal = () => setCreateModalOpen(true);
    const handleCloseCreateModal = () => setCreateModalOpen(false);

    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
    const handleOpenEditModal = (vendorId: string) => {
        setSelectedVendorId(vendorId);
        setEditModalOpen(true);
    }
    const handleCloseEditModal = () => {
        setEditModalOpen(false);
        setSelectedVendorId(null);
    }
    const [viewModalOpen, setViewModalOpen] = useState<boolean>(false);
    const handleOpenViewModal = (vendorId: string) => {
        setSelectedVendorId(vendorId);
        setViewModalOpen(true);
    }
    const handleCloseViewModal = () => {
        setViewModalOpen(false);
        setSelectedVendorId(null);
    }

    const [dropdownOpen, setDropdownOpen] = useState<{ [key: number]: boolean }>({});

    const toggleDropdown = (index: number) => {
        setDropdownOpen(prevState => ({ ...prevState, [index]: !prevState[index] }));
    };

    const [refreshData, setRefreshData] = useState(false);

    useEffect(() => {
        if (companyId) {
            dispatch(getAllVendorsAsync(companyId));
            dispatch(getAllBanksAsync());
        }
    }, [companyId, dispatch]);

    useEffect(() => {
        if (refreshData) {
            dispatch(getAllVendorsAsync(companyId));
            dispatch(getAllBanksAsync());
        }
    }, [refreshData, companyId, dispatch]);

    const handleCreateSuccess = () => {
        setRefreshData(true);
    };
    const handleEditSuccess = () => {
        setRefreshData(true);
    };

    console.log("Vendors:", vendors);

    return (
        <div>
            <SettingsLayout>
                <div className='pt-5 p-5'>
                    <div className='w=100 mb-5 d-flex justify-content-between align-items-center'>
                        <h4 className='text-color-2'>Vendors</h4>
                        <CloseButton />
                    </div>

                    <Row className='my-2'>
                        <Col md={12}>
                            <Button onClick={handleOpenCreateModal} className='py-3 px-5 border-0 bg-color-1 float-end'>
                                <span className='text-center'>Add vendor</span>
                            </Button>
                        </Col>
                    </Row>
                    <Table borderless responsive className='mt-5 request-table'>
                        <thead >
                            <tr>
                                <th className='text-color-1 bg-color-3'>S/N</th>
                                <th className='text-color-1 bg-color-3'>Vendor </th>
                                <th className='text-color-1 bg-color-3'>Email</th>
                                <th className='text-color-1 bg-color-3'> Phone number</th>
                                <th className='text-color-1 bg-color-3'>Action</th>

                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(vendors) && vendors.length > 0 ? (
                                vendors.map((vendor, index) => (
                                    <tr key={vendor.id}>
                                        <td>{index + 1}</td>
                                        <td>{vendor.vendor_name} </td>
                                        <td>{vendor.vendor_email}</td>
                                        <td>{vendor.vendor_phone_number}</td>

                                        <td style={{ padding: '0', verticalAlign: 'middle' }}>
                                            <Dropdown show={dropdownOpen[index]} onToggle={() => toggleDropdown(index)}>
                                                <Dropdown.Toggle className="dropdown-toggle" variant="transparent" style={{ background: 'none', border: 'none', boxShadow: 'none' }}>
                                                    <img src={Action} alt="Action" className="action-icon mb-3" style={{ margin: '0', padding: '0' }} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => handleOpenEditModal(vendor.id)}>Edit</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleOpenViewModal(vendor.id)}>View</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={6} className="text-center">No vendors found.</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
                <CreateVendor show={createModalOpen} closeModal={handleCloseCreateModal} onCreateSuccess={handleCreateSuccess} />
                <EditVendor show={editModalOpen} closeModal={handleCloseEditModal} vendorId={selectedVendorId!} onEditSuccess={handleEditSuccess} />
                <ViewVendor show={viewModalOpen} closeModal={handleCloseViewModal} vendorId={selectedVendorId!} />
            </SettingsLayout>
        </div>
    );
};

export default Vendors;
