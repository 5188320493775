import React, { useState, useEffect } from "react";
import {
	Container,
	Card,
	Button,
	Image,
	Row,
	Col,
	Dropdown,
} from "react-bootstrap";
import Logo from "../../assets/images/logo.png";
import Add from "../../assets/images/add-vector.png";
import Action from "../../assets/images/action-vector.png";
import ProjectImg from "../../assets/images/project-img.png";
import CreateProject from "./modals/create-project";
import EditProject from "./modals/edit-project";
import { Link } from "react-router-dom";
import Settings from "../../assets/images/settings-vector.png";
import Logout from "../../assets/images/logout-vector.png";
import { logoutAsync } from "../../slices/auth/authSlice";
import { getAllProjectsAsync } from "../../slices/project/projectSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store/store";
import { toast } from "react-toastify";

const Projects: React.FC = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const projects = useAppSelector((state) => state.project.projects);
	const companyId = useAppSelector(
		(state: RootState) => state.auth.user?.company_id || "",
	);
	const [selectedProjectId, setSelectedProjectId] = useState<string | null>(
		null,
	);
	const [createModalOpen, setCreateModalOpen] = useState(false);
	const [editModalOpen, setEditModalOpen] = useState(false);
	const [dropdownOpen, setDropdownOpen] = useState<{ [key: number]: boolean }>(
		{},
	);

	const handleOpenCreateModal = () => setCreateModalOpen(true);
	const handleOpenEditModal = (projectId: string) => {
		setSelectedProjectId(projectId);
		setEditModalOpen(true);
	};
	const handleCloseCreateModal = () => setCreateModalOpen(false);
	const handleCloseEditModal = () => {
		setEditModalOpen(false);
		setSelectedProjectId(null);
	};

	const toggleDropdown = (index: number) => {
		setDropdownOpen((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};

	const handleLogout = () => {
		dispatch(logoutAsync()).then(() => {
			navigate("/sign-in");
			localStorage.clear();
			toast.success("Logged out successfully");
		});
	};
	useEffect(() => {
		if (companyId) {
			dispatch(getAllProjectsAsync(companyId));
		}
	}, [companyId, dispatch]);

	const [refreshData, setRefreshData] = useState(false);

	useEffect(() => {
		if (refreshData) {
			dispatch(getAllProjectsAsync(companyId));
			setRefreshData(false);
		}
	}, [refreshData, companyId, dispatch]);

	const handleCreateSuccess = () => {
		setRefreshData(true);
	};

	return (
		<Container>
			<div className="float-end mt-3">
				<Link to="/settings-profile" className="link-styles">
					<Image src={Settings} />
					<span className="mx-2">Settings</span>
				</Link>
				<Button className="logout-btn border-0" onClick={handleLogout}>
					<Image src={Logout} />
					<span className="mx-2">Logout</span>
				</Button>
			</div>
			<div className="text-center p-5">
				<Image src={Logo} width={180} height={63} />
			</div>
			<Row>
				<Col md={3} xs={6} className="mt-2">
					<Card className="create-project-card pt-5">
						<Card.Body className="d-flex flex-column justify-content-center align-items-center position-relative">
							<div className="text-center">
								<Button onClick={handleOpenCreateModal} className="add-btn ">
									<Image src={Add} />
								</Button>
							</div>
							<div className="position-absolute bottom-0 text-center w-100 mb-3">
								<small className="fw-bold">Create new project</small>
							</div>
						</Card.Body>
					</Card>
				</Col>
				{Array.isArray(projects) && projects.length > 0 ? (
					projects.map((project, index) => (
						<Col key={project.id} md={3} xs={6} className="mt-2">
							<Card
								className="project-card"
								onClick={() => localStorage.setItem("projectId", project.id)}
							>
								<Card.Body>
									<Link to={`/dashboard/${project.id}`} className="link-styles">
										<Image src={ProjectImg} className="project-img" />
									</Link>

									<Row>
										<Col>
											<div className="mt-4">
												<h6>{project.project_name}</h6>
											</div>
										</Col>
										<Col className="d-flex justify-content-end mt-3">
											<Dropdown
												show={dropdownOpen[index]}
												onToggle={() => toggleDropdown(index)}
												className="project-dropdown"
											>
												<Dropdown.Toggle
													className="dropdown-toggle"
													variant="transparent"
													style={{
														background: "none",
														border: "none",
														boxShadow: "none",
													}}
												>
													<Image src={Action} className="action-icon" />
												</Dropdown.Toggle>
												<Dropdown.Menu>
													<Dropdown.Item
														onClick={() => {
															handleOpenEditModal(project.id);
														}}
													>
														Edit
													</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Col>
					))
				) : (
					<div>No projects found.</div>
				)}
			</Row>
			<CreateProject
				show={createModalOpen}
				closeModal={handleCloseCreateModal}
				onCreateSuccess={handleCreateSuccess}
			/>
			<EditProject
				show={editModalOpen}
				closeModal={handleCloseEditModal}
				projectId={selectedProjectId!}
			/>
		</Container>
	);
};

export default Projects;
