import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { Inventory, ConfirmAnInventoryData, GetAllInventoryResponse, GetConfirmedInventoryResponse, 
    // GetAnInventoryResponse,
     ConfirmAnInventoryResponse, GetLowStockResponse } from "../../services/inventory/inventoryService";
import { getAllInventory, getConfirmedInventory,
    // getAnInventory,
     confirmAnInventory, getLowStock } from "../../services/inventory/inventoryService";

export interface InventoryState {
    confirmInventoryResponse: ConfirmAnInventoryResponse | null;
    getAllInventoryResponse: GetAllInventoryResponse | null;
    getConfirmedInventoryResponse: GetConfirmedInventoryResponse | null;
    // getAnInventoryResponse: GetAnInventoryResponse | null;
    getLowStockResponse: GetLowStockResponse | null;
    inventory?: Inventory [] | null;
    confirmedInventory?: ConfirmAnInventoryData [] | null;
    inventory_item_id?: string | null;
    error?: string | null;
}

const initialState: InventoryState = {
    confirmInventoryResponse: null,
    getAllInventoryResponse: null,
    getConfirmedInventoryResponse: null,
    // getAnInventoryResponse: null,
    getLowStockResponse: null,
    inventory: null,
    confirmedInventory: null,
    inventory_item_id: null,
    error: null,
}

export const confirmAnInventoryAsync = createAsyncThunk(
    'inventory/confirmInventory',
    async (data: ConfirmAnInventoryData, {dispatch, rejectWithValue}) => {
        const response = await confirmAnInventory(data);
        return response;
    }
);

export const getAllInventoryAsync = createAsyncThunk(
    'inventory/getAllInventory',
    async ({ company_id, projectId }: { company_id: string, projectId: string }) => {
        const response = await getAllInventory(company_id, projectId);
        return response;
    }
);

export const getConfirmedInventoryAsync = createAsyncThunk(
    'inventory/getConfirmedInventory',
    async ({ company_id, projectId }: { company_id: string, projectId: string }) => {
        const response = await getConfirmedInventory(company_id, projectId);
        return response;
    }
);

export const getLowStockAsync = createAsyncThunk(
    'inventory/getLowStock',
    async (company_id: string) => {
        const response = await getLowStock(company_id);
        return response;
    }
);

const inventorySlice = createSlice({
    name: 'inventory',
    initialState,
    reducers: {
        setInventoryItemId: (state, action) => {
            state.inventory_item_id = action.payload;
        },
        setInventory: (state, action) => {
            state.inventory = action.payload;
        },
        setConfirmedInventory: (state, action) => {
            state.confirmedInventory = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(confirmAnInventoryAsync.fulfilled, (state, action) => {
                state.confirmInventoryResponse = action.payload;
                state.error = null;
            })
            .addCase(confirmAnInventoryAsync.rejected, (state, action) => {
                state.confirmInventoryResponse = null;
                state.error = action.error.message;
            })
            .addCase(getAllInventoryAsync.fulfilled, (state, action) => {
                state.getAllInventoryResponse = action.payload;
                state.error = null;
            })
            .addCase(getAllInventoryAsync.rejected, (state, action) => {
                state.getAllInventoryResponse = null;
                state.error = action.error.message;
            })
            .addCase(getConfirmedInventoryAsync.fulfilled, (state, action) => {
                state.getConfirmedInventoryResponse = action.payload;
                state.error = null;
            })
            .addCase(getConfirmedInventoryAsync.rejected, (state, action) => {
                state.getConfirmedInventoryResponse = null;
                state.error = action.error.message;
            })
            .addCase(getLowStockAsync.fulfilled, (state, action) => {
                state.getLowStockResponse = action.payload;
                state.error = null;
            })
            .addCase(getLowStockAsync.rejected, (state, action) => {
                state.getLowStockResponse = null;
                state.error = action.error.message;
            });
    },
});

export const { setInventoryItemId, setInventory, setConfirmedInventory } = inventorySlice.actions;

export default inventorySlice.reducer;
