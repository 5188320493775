import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Container, Table, Button } from 'react-bootstrap';
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import {
    getAPettyRequestAsync,
    setPettyRequestId,
} from '../../../../slices/requests/requestsSlice';
import ConfirmPayUtilityRequest from "./confirm-pay-utility-request";
import { RootState } from "../../../../store/store";
import { getUserAsync } from "../../../../slices/auth/authSlice";


interface PayUtilityRequestProps {
    closeModal: () => void;
    show: boolean;
    pettyRequestId: string;
    projectId: string;
}

const PayUtilityRequest: React.FC<PayUtilityRequestProps> = ({ closeModal, show, pettyRequestId, projectId }) => {
    const formatCurrency = (amount: number): string => {
        return `₦${amount.toLocaleString('en-US')}`;
    };
    const dispatch = useAppDispatch();
    const companyId = useAppSelector(
        (state: RootState) => state.auth.user?.company_id || "",
    );
    const pettyRequestData = useAppSelector((state: any) => state.requests.pettyRequestData) || {};
    const [loading, setLoading] = useState(true);
    const [selectedPettyRequestId, setSelectedPettyRequestId] = useState<string | null>(null);
    const [confirmPayUtilityRequestModalOpen, setConfirmPayUtilityRequestModalOpen] = useState<boolean>(false);
    const handleOpenConfirmPayUtilityRequestModal = (pettyRequestId: string) => {
        setSelectedPettyRequestId(pettyRequestId);
        setConfirmPayUtilityRequestModalOpen(true);
        closeModal();
    };

    const handleCloseConfirmPayUtilityRequestModal = () => setConfirmPayUtilityRequestModalOpen(false);
    useEffect(() => {
        dispatch(getUserAsync());
    }, [dispatch]);

    useEffect(() => {
        if (pettyRequestId) {
            dispatch(setPettyRequestId(pettyRequestId));
            console.log("Fetching petty cash request with ID:", pettyRequestId);
            dispatch(getAPettyRequestAsync({ companyId, id: pettyRequestId, projectId })).then(() =>
                setLoading(false),
            );
        } else {
            console.log("pettyRequestId is not set");
        }
    }, [dispatch, companyId, pettyRequestId, projectId]);

    if (loading) {
        return null;
    }
    console.log("pettyRequestData", pettyRequestData);

    return (
        <Container>
            <Modal size="lg" show={show} onHide={closeModal} style={{ maxHeight: "100vh", overflowY: "auto" }}>
                <div className='p-3'>
                    <Modal.Header className='text-start'>
                        <h4>Pay petty cash request</h4>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className='mt-3 pb-3'>
                            <Col md={8} className="col-separator">
                                <Table className="rectangle" borderless responsive >
                                    <thead >
                                        <tr>
                                            <td className='text-color-7'>S/N</td>
                                            <td className='text-color-7'>Title </td>
                                            <td className='text-color-7'>Receiver name </td>
                                            <td className='text-color-7'>Amount </td>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr key={pettyRequestData?.petty_cash_requests?.id}>
                                            <td className='text-color-7'>1</td>
                                            <td className='text-color-7'>{pettyRequestData?.petty_cash_requests?.title} </td>
                                            <td className='text-color-7'>{pettyRequestData?.petty_cash_requests?.receiver_name}</td>
                                            <td className='text-color-7'>{formatCurrency(parseFloat(pettyRequestData?.petty_cash_requests?.amount))}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col md={4}>
                                <Row className="mt-1">
                                    <small>Request initiated by Mahirson Corp </small>
                                    <small>22/04/24 4:38 PM</small>
                                </Row>
                                <Row className="mt-1">
                                    <small>Request review pending  </small>
                                    <small>22/04/24 4:38 PM</small>
                                </Row>
                                <Row className="mt-1">
                                    <small>Request approval pending  </small>
                                    <small>22/04/24 4:38 PM</small>
                                </Row>
                            </Col>
                        </Row>
                        <Modal.Footer className='mt-5'>
                            <Row >
                                <Col md={6}>
                                    <div className=' mt-1'>
                                        <Button onClick={closeModal} className='cancel-btn'>
                                            <span className="text-center">Cancel </span>                                        </Button>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className=' mt-1'>
                                        <Button type='submit' className=' border-0 create-btn bg-color-1' onClick={() => handleOpenConfirmPayUtilityRequestModal(pettyRequestId)}>
                                            <span className="text-center">Pay </span>                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Modal.Footer>
                    </Modal.Body>
                </div>
            </Modal>
            <ConfirmPayUtilityRequest show={confirmPayUtilityRequestModalOpen} closeModal={handleCloseConfirmPayUtilityRequestModal} projectId={projectId!} pettyRequestId={selectedPettyRequestId!} />
        </Container>
    )
}

export default PayUtilityRequest