import React from "react";

import closeButton from "../../assets/images/close-button.svg"

import { Link } from "react-router-dom";

const CloseButton: React.FC = () => {
    return (
        <Link to="/projects">
            <img src={closeButton} alt="close" />
        </Link>
    )
        
    
}

export default CloseButton
