import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Container, Table } from 'react-bootstrap';
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import {
    getAMaterialRequestAsync, setMaterialRequestId
} from '../../../../slices/requests/requestsSlice';
import { RootState } from "../../../../store/store";
import { format, isValid } from 'date-fns';
import { Stepper, Step, StepLabel } from "@mui/material";

interface ViewMaterialRequestProps {
    closeModal: () => void;
    show: boolean;
    materialRequestId: string;
    projectId: string;
}

const ViewMaterialRequest: React.FC<ViewMaterialRequestProps> = ({ closeModal, show, materialRequestId, projectId }) => {
    const formatCurrency = (amount: number): string => `₦${amount.toLocaleString('en-US')}`;
    const formatDateTime = (dateTime: string | Date): string => {
        const date = new Date(dateTime);
        return isValid(date) ? format(date, "dd/MM/yy h:mm a") : "Invalid Date";
    };

    const dispatch = useAppDispatch();
    const companyId = useAppSelector((state: RootState) => state.auth.user?.company_id || "");
    const materialRequestData = useAppSelector((state: any) => state.requests.materialRequestData);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (materialRequestId) {
            dispatch(setMaterialRequestId(materialRequestId));
            dispatch(getAMaterialRequestAsync({ companyId, id: materialRequestId, projectId })).then(() => setLoading(false));
        }
    }, [dispatch, companyId, materialRequestId, projectId]);

    if (loading) {
        return null;
    }

    const reviewers = materialRequestData?.reviews || [];

    const dynamicSteps = reviewers.map((reviewer: any, index: number) => ({
        status: 'reviewed',
        label: 'Reviewed by',
        user: reviewer.user,
        date: reviewer.date,
    })).concat([
        {
            status: 'approved',
            label: 'Approved by',
            user: materialRequestData.approver?.user,
            date: materialRequestData.approver?.date,
        }
    ]);

    const steps = [
        { status: 'initiated', label: 'Initiated by', user: materialRequestData.material_requests.created_by, date: materialRequestData.material_requests.created_on },
        ...dynamicSteps
    ];

    return (
        <Container>
            <Modal className="modal-container" size="lg" show={show} onHide={closeModal} style={{ maxHeight: "100vh", overflowY: "auto" }}>
                <div className='px-3 '>
                    <Modal.Header className='text-start'>
                        <h4 className="confirm-header">View material request</h4>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className='mt-2 pb-2'>
                            <Col md={8} className="col-separator">
                                <Table className="rectangle" borderless responsive>
                                    <thead>
                                        <tr>
                                            <td className='text-color-7'>S/N</td>
                                            <td className='text-color-7'>Material </td>
                                            <td className='text-color-7'>Quantity </td>
                                            <td className='text-color-7'>Cost </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {materialRequestData.material_requests.materials.map((material: any, index: any) => (
                                            <tr key={material.id}>
                                                <td className='text-color-7'>{index + 1}</td>
                                                <td className='text-color-7'>{material.material_name}</td>
                                                <td className='text-color-7'>{material.quantity}</td>
                                                <td className='text-color-7'>{formatCurrency(parseFloat(material.unit_cost))}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                            <Col md={4}>
                                <Stepper orientation="vertical" activeStep={steps.findIndex(step => step.status === 'approved')}>
                                    {steps.map((step, index) => (
                                        <Step key={index}>
                                            <StepLabel>
                                                <div>
                                                    {step.label}: {step.user}
                                                </div>
                                                <div>{formatDateTime(step.date)}</div>
                                            </StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Col>
                        </Row>
                    </Modal.Body>
                </div>
            </Modal>
        </Container>
    );
}

export default ViewMaterialRequest;
