import React, { useEffect, useState } from "react";
import { Container, Modal } from "react-bootstrap";

interface ViewOrderProps {
	show: boolean;
	closeModal: () => void;
}
const ViewOrder: React.FC<ViewOrderProps> = ({ show, closeModal }) => {
	return (
		<Container fluid className="">
			<Modal size="lg" show={show} onHide={closeModal} centered className="p-3">
				<Modal.Header closeButton>
					<Modal.Title>View confirm order</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p className="text-right w-100">Qty remaining: 300</p>
					<p>
						Qty received: 100 <br />
						Received by Mashirsson Corp <br />
						22/04/24 4:38 PM
					</p>
					<p>
						Qty received: 100 <br />
						Received by Mashirsson Corp <br />
						23/04/24 4:38 PM
					</p>
				</Modal.Body>
			</Modal>
		</Container>
	);
};

export default ViewOrder;
