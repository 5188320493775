import axios from 'axios';

axios.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export interface CreateApprovalLevelData {
    id: string;
    company_id: string;
    user_id: string;
}
export interface CreateApprovalLevelResponse {
    data: {
        project_id: string;
        approval_type: string;
        updated_at: string;
        created_at: string;
        id: number;
    };
    message: string;
}

export interface Approver {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  email_verified_at: string | null;
  phone_number: string;
  company_id: number;
  created_at: string;
  updated_at: string;
  role_id: number;
  system_role: string;
  status_id: number;
  created_by: string;
  project_id?: number;
  approval_type?: string;
  level?: number;

 
}

export interface Level {
  level: number;
  user_ids: number[];
  approval_type: 'Approval' | 'Review';
}



export interface CreateCustomApprover {
  levels: Level[];
  projectId: string;
  company_id: number | string;
  
  
 
}
export interface CustomApprovalLevelData {
  level: number;
  project_id: number;
  approval_type: 'Approval' | 'Review';
  company_id: number;
  updatedAt: string; 
  created_at: string;
  updated_at: string;
  id: number;
}

export interface CustomApproverResponse {
   message: string;
   data: Approver[]
   
    

}

export interface ApproversResponse {
  message: string;
  data: Approver[];
}


export const createApprovalLevel = async (data: CreateApprovalLevelData): Promise<CreateApprovalLevelResponse> => {
    const { id, company_id, user_id } = data;
    const response = await axios.post<CreateApprovalLevelResponse>(
        `https://syteboxx-api.remsys.com.ng/api/v1/config/company/${id}/create-approver`,
        { company_id, user_id }
    );
    return response.data;
};


export const getAllCompanyApprovers = async (id: string): Promise<Approver[]> => {
    const response = await axios.get<ApproversResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/config/company/${id}/approvers`);
    return response.data.data;
}

export const createCustomApprovaer = async (data: CreateCustomApprover): Promise<CustomApproverResponse> => {
    const { projectId, company_id, levels } = data;
    const response = await axios.post<CustomApproverResponse>(
        `https://syteboxx-api.remsys.com.ng/api/v1/projects/${projectId}/create-approval-levels`,
        { levels }, 
        {
            params: { company_id } 
        }
    );
    return response.data;
};

export const getAllProjectReviewers = async (projectId: string, companyId: number): Promise<Approver[]> => {
  const response = await axios.get<ApproversResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/projects/${projectId}/reviewers`, {
    params: { company_id: companyId }
  });
  return response.data.data;
}


export const getAllProjectsApprover = async (projectId: string, companyId: number): Promise<Approver[]> => {
  const response = await axios.get<ApproversResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/projects/${projectId}/approvers`, {
    params: { company_id: companyId }
  });
  return response.data.data;
}
