import React from "react";
import { Formik, Form, Field } from "formik";
import { Container, Card, Button, Row, Col } from "react-bootstrap";
import errorIcon from "../../assets/images/error-icon.svg";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { signupAsync } from "../../slices/auth/authSlice";
import { toast } from "react-toastify";

interface SignUpValues {
	first_name: string;
	last_name: string;
	email: string;
	phone_number: string;
	company_name: string;
	company_size: string;
	password: string;
	confirm_password: string;
	terms: boolean;
}

const SignUp: React.FC = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const signUpResponse = useAppSelector(
		(state: any) => state.auth.signUpResponse,
	);

	if (!signUpResponse?.error) {
		console.log("success");
		navigate("/user-verification");
	}
	const handlePhoneNumberChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const { value } = event.target;
		let numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters
		if (numericValue.length > 11) {
			numericValue = numericValue.slice(0, 11); // Truncate to 11 digits
		}
		event.target.value = numericValue;
	};

	return (
		<Container fluid>
			<div className="auth-bg">
				<Card
					className="auth-card-2 text-center px-5 py-3 fixed-bottom "
					style={{ maxHeight: "100vh", overflowY: "auto" }}
				>
					<h4 className="">Create company account</h4>
					<div className="text-start">
						<Formik
							initialValues={{
								first_name: "",
								last_name: "",
								email: "",
								phone_number: "",
								company_name: "",
								company_size: "",
								password: "",
								confirm_password: "",
								terms: false,
							}}
							validationSchema={Yup.object().shape({
								first_name: Yup.string().required("First name is required"),
								last_name: Yup.string().required("Last name is required"),
								email: Yup.string()
									.email("Invalid email")
									.required("Email is required"),
								phone_number: Yup.string()
									.matches(
										/^(070|080|081|090|091)[0-9]{8}$/,
										"Please enter a valid 11 digit phone number in Nigerian format e.g 08012345678",
									)
									.required("Please enter a valid 11 digit phone number"),

								company_name: Yup.string().required("Company name is required"),
								company_size: Yup.string().required("Company size is required"),
								password: Yup.string()
									.required("No password provided.")
									.min(8, "Must be at least 8 characters"),
								confirm_password: Yup.string()
									.oneOf([Yup.ref("password"), ""], "Passwords do not match")
									.required("Please confirm password."),
								terms: Yup.boolean().oneOf(
									[true],
									"You must accept the terms and conditions",
								),
							})}
							onSubmit={(values: SignUpValues, { setSubmitting }) => {
								dispatch(signupAsync(values))
									.unwrap()
									.then((response) => {
										toast.success("User created successfully");
									})
									.catch((error) => {
										const errorMessage = Object.values(error.message).flat().join(" ");
										toast.error(errorMessage);
									})
									.finally(() => {
										setSubmitting(false);
									});
							}}
							validateOnChange
							validateOnBlur
						// validateOnSubmit
						>
							{({
								errors,
								touched,
								handleSubmit,
								isSubmitting,
								values,
								handleChange,
							}) => (
								<Form onSubmit={handleSubmit}>
									<Row className="mb-2">
										<Col md={6} className="">
											<label className="">
												<p className="mb-0">
													First name <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="first_name"
												placeholder="Please enter your first name"
												className={`fluent-input ${touched.first_name && errors.first_name
														? "is-invalid"
														: ""
													}`}
											/>
											<p className="error-message-placeholder mb-0">
												no errors
											</p>
										</Col>
										<Col md={6} className="">
											<label className="">
												<p className="mb-0">
													Last name <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="last_name"
												placeholder="Please enter your last name"
												className={`fluent-input ${touched.last_name && errors.last_name
														? "is-invalid"
														: ""
													}`}
											/>
											<p className="error-message-placeholder mb-0">
												no errors
											</p>
										</Col>
									</Row>
									<Row className="mb-2">
										<Col md={6} className="">
											<label className="">
												<p className="mb-0">
													Email address <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="email"
												name="email"
												placeholder="Please enter your email"
												className={`fluent-input ${touched.email && errors.email ? "is-invalid" : ""
													}`}
											/>
											{touched.email && errors.email ? (
												<p className="error-message mb-0 d-inline-flex align-items-center">
													<img src={errorIcon} alt="error-icon" />
													{errors.email}
												</p>
											) : (
												<p className="error-message-placeholder mb-0">
													no errors
												</p>
											)}
										</Col>
										<Col md={6} className="">
											<label className="">
												<p className="mb-0">
													Phone number <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="phone_number"
												placeholder="Please enter your phone number"
												inputMode="numeric"
												className={`fluent-input ${touched.phone_number && errors.phone_number
														? "is-invalid"
														: ""
													}`}
												onInput={handlePhoneNumberChange}
											/>
											{touched.phone_number && errors.phone_number ? (
												<p className="error-message mb-0 d-inline-flex align-items-center">
													<img src={errorIcon} alt="error-icon" />
													{errors.phone_number}
												</p>
											) : (
												<p className="error-message-placeholder mb-0">
													no errors
												</p>
											)}
										</Col>
									</Row>
									<Row className="mb-2">
										<Col md={6} className="">
											<label className="">
												<p className="mb-0">
													Company name <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="company_name"
												placeholder="Please enter your company name"
												className={`fluent-input ${touched.company_name && errors.company_name
														? "is-invalid"
														: ""
													}`}
											/>
											{touched.company_name && errors.company_name ? (
												<p className="error-message mb-0 d-inline-flex align-items-center">
													<img src={errorIcon} alt="error-icon" />
													{errors.company_name}
												</p>
											) : (
												<p className="error-message-placeholder mb-0">
													no errors
												</p>
											)}
										</Col>
										<Col md={6} className="">
											<label className="">
												<p className="mb-0">
													Company size <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												as="select"
												name="company_size"
												className={`fluent-input ${touched.company_size && errors.company_size
														? "is-invalid"
														: ""
													}`}
											>
												<option>Choose company size</option>
												<option value="1 - 5">1 - 5</option>
												<option value="6 - 10">6 - 10</option>
												<option value="11 - 20">11 - 20</option>
												<option value="21 - 50">21 - 50</option>
												<option value="50 and above">50 and above</option>
											</Field>
											{touched.company_size && errors.company_size ? (
												<p className="error-message mb-0 d-inline-flex align-items-center">
													<img src={errorIcon} alt="error-icon" />
													{errors.company_size}
												</p>
											) : (
												<p className="error-message-placeholder mb-0">
													no errors
												</p>
											)}
										</Col>
									</Row>
									<Row className="mb-2">
										<Col md={6} className="">
											<label className="">
												<p className="mb-0">
													Password <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="password"
												name="password"
												placeholder="Please enter your password"
												className={`fluent-input ${touched.password && errors.password
														? "is-invalid"
														: ""
													}`}
											/>
											{touched.password && errors.password ? (
												<p className="error-message mb-0 d-inline-flex align-items-center">
													<img src={errorIcon} alt="error-icon" />
													{errors.password}
												</p>
											) : (
												<p className="error-message-placeholder mb-0">
													no errors
												</p>
											)}
										</Col>
										<Col md={6} className="">
											<label className="">
												<p className="mb-0">
													Confirm password{" "}
													<span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="password"
												name="confirm_password"
												placeholder="Please enter confirm password"
												className={`fluent-input ${touched.confirm_password && errors.confirm_password
														? "is-invalid"
														: ""
													}`}
											/>
											{touched.confirm_password && errors.confirm_password ? (
												<p className="error-message mb-0 d-inline-flex align-items-center">
													<img src={errorIcon} alt="error-icon" />
													{errors.confirm_password}
												</p>
											) : (
												<p className="error-message-placeholder mb-0">
													no errors
												</p>
											)}
										</Col>
									</Row>
									<Row className="mb-2">
										<Col md={6} className="mt-4">
											<span>
												<Field type="checkbox" name="terms" /> I agree to
												Syteboxx's{" "}
												<span className="text-color-1">Privacy </span> and{" "}
												<span className="text-color-1"> Term of Use </span>
											</span>
											{touched.terms && errors.terms && (
												<div className="invalid-feedback">{errors.terms}</div>
											)}
										</Col>
									</Row>
									<Row className="mb-2">
										<Col md={12}>
											<div className="d-grid ">
												<Button
													type="submit"
													className="my-4 py-3 border-0 bg-color-1"
												>
													<span className="text-center">Create account</span>{" "}
												</Button>
											</div>
										</Col>
									</Row>
									<div className="text-center ">
										<Link to="/sign-in" className="link-styles">
											Already have an account?{" "}
											<span className="text-color-1"> Sign in </span>
										</Link>
									</div>
								</Form>
							)}
						</Formik>
					</div>
				</Card>
			</div>
		</Container>
	);
};

export default SignUp;
