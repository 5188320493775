import React from "react";
import Layout from "../../components/layouts/layout";
import { Table, Row, Col, Card } from 'react-bootstrap';

function DashBoard() {
	return (
		<div>
			<Layout>
				<div className="pt-5 p-3">
					<h3>Dashboard</h3>
					<>
						<Row className='mt-5'>
							<Col>
								<Card className='bg-color-5 text-color-4 border-0 p-2'>
									<p>Total budget left</p>
									<h3>N 350,000,000.00</h3>
								</Card>
							</Col>
							<Col>
								<Card className='bg-color-6 text-color-5 border-0 p-2'>
									<p>Active project</p>
									<h3>3</h3>
								</Card>
							</Col>
							<Col>
								<Card className='bg-color-7 text-color-6 border-0 p-2'>
									<p>Pending request</p>
									<h3>1</h3>
								</Card>
							</Col>
						</Row>
						<Row className="mt-5">
							<h6 className="text-color-1">Task due</h6>
						</Row>

						<Table borderless responsive className='mt-3'>
							<thead>
								<tr>
									<th className='text-color-1 bg-color-3'>Task name </th>
									<th className='text-color-1 bg-color-3'>Assigned to </th>
									<th className='text-color-1 bg-color-3'>Planned start date </th>
									<th className='text-color-1 bg-color-3'>Planned end date </th>
									<th className='text-color-1 bg-color-3'>Due in</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Site setup</td>
									<td>Fatima Sani</td>
									<td>05/01/24</td>
									<td>22/07/24</td>
									<td>7 days</td>
								</tr>
							</tbody>
							<tbody>
								<tr>
									<td>Foundation </td>
									<td>Fatima Sani</td>
									<td>05/01/24</td>
									<td>22/07/24</td>
									<td>7 days</td>
								</tr>
							</tbody>
							<tbody>
								<tr>
									<td>Structure </td>
									<td>Fatima Sani</td>
									<td>05/01/24</td>
									<td>22/07/24</td>
									<td>7 days</td>
								</tr>
							</tbody>
							<tbody>
								<tr>
									<td>Site setup</td>
									<td>Fatima Sani</td>
									<td>05/01/24</td>
									<td>22/07/24</td>
									<td>7 days</td>
								</tr>
							</tbody>
							<tbody>
								<tr>
									<td>Site setup</td>
									<td>Fatima Sani</td>
									<td>05/01/24</td>
									<td>22/07/24</td>
									<td>7 days</td>
								</tr>
							</tbody>
						</Table>
					</>
				</div>
			</Layout>
		</div>
	);
}

export default DashBoard;
