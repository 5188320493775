import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import SettingsLayout from "../settings-layout";
import Nav from "react-bootstrap/Nav";
import { Row, Image, Button } from "react-bootstrap";
import profileLogo from "../../../assets/images/user-logo.svg";

import pen from "../../../assets/images/pen.svg";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import {
	getCompanyProfileAsync,
	updateCompanyProfileAsync,
	updateProfileAsync,
} from "../../../slices/settings/settingsSlice";
import { getUserAsync } from "../../../slices/auth/authSlice";

import { toast } from "react-toastify";
import CloseButton from "../../../components/close-button/close-button";
export interface updatePersonalProfile {
	first_name: string;
	last_name: string;
	email: string;
	phone_number: string;
}
export interface updateCompanyProfile {
	company_name: string;
	company_email: string;
	company_phone_number: string;
	// company_size: string;
	// company_address: string;
	// company_state: string;
	// company_country: string;
}

function Profile() {
	const [activeTab, setActiveTab] = useState<string>("personal");

	const dispatch = useAppDispatch(); // Redux dispatch function

	const companyProfile = useAppSelector(
		(state) => state.settings.companyProfile,
	);
	const user = useAppSelector((state) => state.auth.user);
	const [companyId, setCompanyId] = useState<string>("");

	const updateProfileResponse = useAppSelector(
		(state) => state.settings.updateProfileResponse,
	);
	const updateCompanyProfileResponse = useAppSelector(
		(state) => state.settings.updateCompanyProfileResponse,
	);

	useEffect(() => {
		dispatch(getUserAsync());
	}, [dispatch]);

	useEffect(() => {
		setActiveTab("personal");
		// Fetch company profile when component mounts
		if (user !== null && user !== undefined) {
			dispatch(getCompanyProfileAsync(user.company_id));
			setCompanyId(user.company_id);
		}
	}, [dispatch, user]);

	const personalProfileData = {
		first_name: user?.first_name || "First Name",
		last_name: user?.last_name || "Last Name",
		email: user?.email || "Email",
		phone_number: user?.phone_number || "Number",
	};
	const [firstNameValue, setFirstNameValue] = useState<string>("loading...");
	const [lastNameValue, setLastNameValue] = useState<string>("loading...");
	const [emailValue, setEmailValue] = useState<string>("loading...");
	const [phoneValue, setPhoneValue] = useState<string>("loading...");

	useEffect(() => {
		if (user !== null && user !== undefined) {
			setFirstNameValue(user.first_name);
			setLastNameValue(user.last_name);
			setEmailValue(user.email);
			setPhoneValue(user.phone_number);
		}
	}, [user]);

	const companyProfileData = {
		id: user?.company_id,
		company_name: companyProfile?.company_name || "Company Name",
		company_email: companyProfile?.company_email || "Company Email",
		company_size: companyProfile?.company_size || "Company Size",
		company_phone_number:
			companyProfile?.company_phone_number || "Company Phone Number",
		company_address: companyProfile?.company_address || "Company Address",
		company_state: companyProfile?.company_state || "Company State",
		company_country: companyProfile?.company_country || "Company Country",
	};
	const [companyNameValue, setCompanyNameValue] = useState<string>(
		companyProfileData.company_name,
	);
	const [companyEmailValue, setCompanyEmailValue] = useState<string>(
		companyProfileData.company_email,
	);
	const [companyphoneValue, setCompanyPhoneValue] = useState<string>(
		companyProfileData.company_phone_number,
	);
	useEffect(() => {
		if (companyProfile !== null && companyProfile !== undefined) {
			setCompanyNameValue(companyProfile.company_name);
			setCompanyEmailValue(companyProfile.company_email);
			setCompanyPhoneValue(companyProfile.company_phone_number);
		}
	}, [companyProfile]);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const tabOrder: string[] = ["personal", "company"];

	const handleTabSelect = (key: string | null) => {
		if (key) {
			setActiveTab(key);
		}
	};

	const handlePhoneNumberChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const { value } = event.target;
		let numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters
		if (numericValue.length > 11) {
			numericValue = numericValue.slice(0, 11); // Truncate to 11 digits
		}
		event.target.value = numericValue;
	};

	return (
		<div>
			<SettingsLayout>
				<div className="pt-5 p-5">
					<div className="w-100 mb-5 d-inline-flex justify-content-between align-items-center">
						<h4 className="text-color-2">Profile</h4>
						<CloseButton />
					</div>

					<Nav
						variant="underline"
						activeKey={activeTab}
						onSelect={handleTabSelect}
						className="settings-nav px-3 py-2"
					>
						<Nav.Item>
							<Nav.Link
								className="py-1 px-3 rounded fw-light mr-1"
								eventKey="personal"
								style={{
									border: "none",
									textDecoration: "none",
									color: activeTab === "personal" ? "white" : "darkgray",
									background:
										activeTab === "personal" ? "#0F6CBD" : "transparent",
								}}
							>
								Personal profile
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link
								className="py-1 px-3 rounded fw-light"
								eventKey="company"
								style={{
									border: "none",
									color: activeTab === "company" ? "white" : "darkgray",
									textDecoration: "none",
									background:
										activeTab === "company" ? "#0F6CBD" : "transparent",
								}}
							>
								Company profile
							</Nav.Link>
						</Nav.Item>
					</Nav>
					<div>
						{activeTab === "personal" && (
							<div className="d-flex flex-column w-70 p-2">
								<h5 className="fw-bold">About</h5>
								<br />
								<div className="border p-2 w-30 pt-3 pb-3 d-flex justify-content-center align-items-center">
									<Image
										src={profileLogo}
										className="w-50"
										alt="profile logo"
									/>
								</div>
								<div className="w-30 fs-7 d-inline-flex justify-content-between mt-2 align-items-center pointer">
									<p className="m-0">Change avatar</p>
									<img src={pen} alt="profile logo" className="ml-3" />
								</div>
								<br />
								<Formik
									enableReinitialize
									initialValues={{
										first_name: firstNameValue,
										last_name: lastNameValue,
										email: emailValue,
										phone_number: phoneValue,
									}}
									validationSchema={Yup.object().shape({
										first_name: Yup.string().required("First name is required"),
										last_name: Yup.string().required("Last name is required"),
										email: Yup.string()
											.email("Invalid email")
											.required("Email is required"),
										phone_number: Yup.string()
											.matches(
												/^(070|080|081|090|091)[0-9]{8}$/,
												"Please enter a valid 11 digit phone number in Nigerian format e.g 08012345678",
											)
											.required("Phone number is required"),
									})}
									onSubmit={(values, { setSubmitting, resetForm }) => {
										const updatedPersonalProfile = {
											id: `${user?.id}`,
											company_id: `${user?.company_id}`,
											...Object.keys(values).reduce((acc, key) => {
												if (
													values[key as keyof typeof values] !== "" &&
													values[key as keyof typeof values] !==
														personalProfileData[
															key as keyof typeof personalProfileData
														]
												) {
													acc[key as keyof typeof values] =
														values[key as keyof typeof values];
												}
												return acc;
											}, {} as typeof values),
										};
										dispatch(updateProfileAsync(updatedPersonalProfile))
											.then(() => {
												toast.success(updateProfileResponse?.message);
												dispatch(getUserAsync());
												resetForm();
											})
											.catch((error) => {
												console.error(
													"Error updating personal profile:",
													error,
												);
												toast.error("An error occurred while updating profile");
											})
											.finally(() => {
												setSubmitting(false);
											});
									}}
								>
									{({
										errors,
										touched,
										handleSubmit,
										values,
										handleChange,
										handleBlur,
										isSubmitting,
										dirty,
									}) => (
										<Form className="w-100">
											<Row className="w-100 d-flex align-items-center">
												<div className="col">
													<label
														htmlFor="first_name"
														className="text-gray mb-1"
													>
														First name
													</label>
													<Field
														type="text"
														name="first_name"
														id="first_name"
														placeholder={`${personalProfileData.first_name}`}
														className={`fluent-input ${
															touched.first_name && errors.first_name
																? "is-invalid"
																: ""
														}`}
													/>
												</div>
												<div className="col">
													<label
														htmlFor="last_name"
														className="text-color-3 mb-1"
													>
														Last name
													</label>
													<Field
														type="text"
														name="last_name"
														id="last_name"
														placeholder={`${personalProfileData.last_name}`}
														className={`fluent-input ${
															touched.last_name && errors.last_name
																? "is-invalid"
																: ""
														}`}
													/>
												</div>
											</Row>
											<Row className="w-100 d-flex align-items-center mt-4">
												<div className="col">
													<label htmlFor="email" className="text-color-3 mb-1">
														Email address
													</label>
													<Field
														type="text"
														name="email"
														id="email"
														placeholder={`${personalProfileData.email}`}
														className={`fluent-input ${
															touched.email && errors.email ? "is-invalid" : ""
														}`}
													/>
													{errors.email && touched.email ? (
														<p className="text-danger">{errors.email}</p>
													) : (
														<p className="transparent">{errors.email}</p>
													)}
												</div>
												<div className="col">
													<label
														htmlFor="phone_number"
														className="text-color-3 mb-1"
													>
														Phone number
													</label>
													<Field
														onChange={(
															event: React.ChangeEvent<HTMLInputElement>,
														) => {
															handlePhoneNumberChange(event);
															handleChange(event);
														}}
														type="text"
														name="phone_number"
														id="phone_number"
														placeholder={personalProfileData.phone_number}
														className={`fluent-input ${
															touched.phone_number && errors.phone_number
																? "is-invalid"
																: ""
														}`}
													/>
													{errors.phone_number && touched.phone_number ? (
														<p className="text-danger">{errors.phone_number}</p>
													) : (
														<p className="transparent">{errors.phone_number}</p>
													)}
												</div>
											</Row>
											<Row className="w-100 px-2">
												<Button
													className={`mt-4 text-light bg-black-1 w-30`}
													onClick={() => handleSubmit()}
													disabled={!dirty || isSubmitting}
												>
													{`${
														isSubmitting ? "Saving changes..." : "Save changes"
													} `}
												</Button>
											</Row>
										</Form>
									)}
								</Formik>
							</div>
						)}

						{activeTab === "company" && (
							<div className="d-flex flex-column w-70 p-2">
								<h5 className="fw-bold">About</h5>
								<br />
								<div className="border p-2 w-30 pt-3 pb-3 d-flex justify-content-center align-items-center">
									<Image
										src={profileLogo}
										className="w-50"
										alt="profile logo"
									/>
								</div>
								<div className="w-30 fs-7 d-inline-flex justify-content-between mt-2 align-items-center pointer">
									<p className="m-0">Change logo</p>
									<img src={pen} alt="profile logo" className="ml-3" />
								</div>
								<br />
								<Formik
									enableReinitialize
									initialValues={{
										company_name: companyNameValue,
										company_email: companyEmailValue,
										company_phone_number: companyphoneValue,
									}}
									validationSchema={Yup.object().shape({
										company_name: Yup.string().required(
											"Company name is required",
										),
										company_email: Yup.string()
											.email("Invalid email")
											.required("Company email cannot be empty"),
										company_phone_number: Yup.string()
											.matches(
												/^(070|080|081|090|091)[0-9]{8}$/,
												"Please enter a valid 11 digit phone number in Nigerian format e.g 08012345678",
											)
											.required("Company phone number cannot be empty"),
									})}
									onSubmit={(values, { setSubmitting, resetForm }) => {
										const updatedCompanyProfile = {
											id: `${user?.id}`,
											company_id: `${user?.company_id}`,
											...Object.keys(values).reduce((acc, key) => {
												if (
													values[key as keyof typeof values] !== "" &&
													values[key as keyof typeof values] !==
														companyProfileData[
															key as keyof typeof companyProfileData
														]
												) {
													acc[key as keyof typeof values] =
														values[key as keyof typeof values];
												}
												return acc;
											}, {} as typeof values),
										};
										dispatch(updateCompanyProfileAsync(updatedCompanyProfile))
											.then(() => {
												toast.success("Company updated successfully");
												dispatch(getCompanyProfileAsync(companyId));
												resetForm();
											})
											.catch((error) => {
												console.error("Error updating company profile:", error);
												toast.error(
													"An error occurred while updating company profile",
												);
											})
											.finally(() => {
												setSubmitting(false);
											});
									}}
								>
									{({
										errors,
										touched,
										handleSubmit,
										values,
										handleChange,
										handleBlur,
										dirty,
										isSubmitting,
									}) => (
										<Form className="w-100">
											<Row className="w-100 d-flex align-items-center">
												<div className="col">
													<label
														htmlFor="company_name"
														className="text-color-3 mb-1"
													>
														Company name
													</label>
													<Field
														type="text"
														name="company_name"
														id="company_name"
														placeholder={`${companyProfileData.company_name}`}
														className={`fluent-input ${
															touched.company_name && errors.company_name
																? "is-invalid"
																: ""
														}`}
													/>
													<p style={{ color: "transparent" }}>
														{errors.company_email}
													</p>
												</div>
												<div className="col">
													<label
														htmlFor="company_email"
														className="text-color-3 mb-1"
													>
														Company email
													</label>
													<Field
														type="text"
														name="company_email"
														id="company_email"
														placeholder={`${companyProfileData.company_email}`}
														className={`fluent-input ${
															touched.company_email && errors.company_email
																? "is-invalid"
																: ""
														}`}
													/>
													{errors.company_email && touched.company_email ? (
														<p className="text-danger">
															{errors.company_email}
														</p>
													) : (
														<p style={{ color: "transparent" }}>
															{errors.company_email}
														</p>
													)}
												</div>
											</Row>
											<Row className="w-100 d-flex align-items-center mt-4">
												<div className="col">
													<label
														htmlFor="company_phone_number"
														className="text-color-3 mb-1"
													>
														Company phone number
													</label>
													<Field
														type="text"
														name="company_phone_number"
														id="company_phone_number"
														placeholder={`${companyProfileData.company_phone_number}`}
														className={`fluent-input ${
															touched.company_phone_number &&
															errors.company_phone_number
																? "is-invalid"
																: ""
														}`}
														onChange={(
															event: React.ChangeEvent<HTMLInputElement>,
														) => {
															handlePhoneNumberChange(event);
															handleChange(event);
														}}
													/>
													{errors.company_phone_number &&
													touched.company_phone_number ? (
														<p className="text-danger">
															{errors.company_phone_number}
														</p>
													) : (
														<p style={{ color: "transparent" }}>
															{errors.company_phone_number}
														</p>
													)}
												</div>
											</Row>
											<Row className="w-100 px-2">
												<Button
													className={`mt-4 text-light bg-black-1 w-30`}
													onClick={() => handleSubmit()}
													disabled={!dirty || isSubmitting}
												>
													{`${
														isSubmitting ? "Saving changes..." : "Save changes"
													} `}
												</Button>
											</Row>
										</Form>
									)}
								</Formik>
							</div>
						)}
					</div>
				</div>
			</SettingsLayout>
		</div>
	);
}

export default Profile;
