import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Container, Table, Button } from 'react-bootstrap';
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import {
    getAMaterialRequestAsync, setMaterialRequestId
} from '../../../../slices/requests/requestsSlice';
import ConfirmReviewMaterialRequest from "./confirm-review-material-request";
import { RootState } from "../../../../store/store";
import { format, isValid } from 'date-fns';

interface ReviewMaterialRequestProps {
    closeModal: () => void;
    show: boolean;
    materialRequestId: string;
    projectId: string;
}

const ReviewMaterialRequest: React.FC<ReviewMaterialRequestProps> = ({ closeModal, show, materialRequestId, projectId }) => {
    const formatCurrency = (amount: number): string => {
        return `₦${amount.toLocaleString('en-US')}`;
    };
    const formatDateTime = (dateTime: string | Date): string => {
        const date = new Date(dateTime);
        return isValid(date) ? format(date, "dd/MM/yy h:mm a") : "Invalid Date";
    };
    const dispatch = useAppDispatch();
    const companyId = useAppSelector(
        (state: RootState) => state.auth.user?.company_id || "",
    );
    const materialRequestData = useAppSelector((state: any) => state.requests.materialRequestData);
    const [loading, setLoading] = useState(true);
    const [selectedMaterialRequestId, setSelectedMaterialRequestId] = useState<string | null>(null);

    const [confirmReviewMaterialRequestModalOpen, setConfirmReviewMaterialRequestModalOpen] = useState<boolean>(false);
    const handleOpenConfirmReviewMaterialRequestModal = (materialRequestId: string) => {
        setSelectedMaterialRequestId(materialRequestId);
        setConfirmReviewMaterialRequestModalOpen(true);
        closeModal();
    };
    const handleCloseConfirmReviewMaterialRequestModal = () => setConfirmReviewMaterialRequestModalOpen(false);

    useEffect(() => {
        if (materialRequestId) {
            dispatch(setMaterialRequestId(materialRequestId));
            console.log("Fetching material request with ID:", materialRequestId);
            dispatch(getAMaterialRequestAsync({ companyId, id: materialRequestId, projectId })).then(() =>
                setLoading(false),
            );
        } else {
            console.log("materialRequestId is not set");
        }
    }, [dispatch, companyId, materialRequestId, projectId]);

    if (loading) {
        return null;
    }
    console.log("materialRequestData", materialRequestData);

    return (
        <Container>
            <Modal className="modal-container" size="lg" show={show} onHide={closeModal} style={{ maxHeight: "100vh", overflowY: "auto" }}>
                <div className='px-3 '>
                    <Modal.Header className='text-start'>
                        <h4 className="confirm-header">Review material request</h4>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className='mt-2 pb-2'>
                            <Col md={8} className="col-separator">
                                <Table className="rectangle" borderless responsive>
                                    <thead>
                                        <tr>
                                            <td className='text-color-7'>S/N</td>
                                            <td className='text-color-7'>Material </td>
                                            <td className='text-color-7'>Quantity </td>
                                            <td className='text-color-7'>Cost </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {materialRequestData.material_requests.materials.map((material: any, index: any) => (
                                            <tr key={material.id}>
                                                <td className='text-color-7'>{index + 1}</td>
                                                <td className='text-color-7'>{material.material_name}</td>
                                                <td className='text-color-7'>{material.quantity}</td>
                                                <td className='text-color-7'>{formatCurrency(parseFloat(material.unit_cost))}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                            <Col md={4}>
                                <Row className="mt-3">
                                    <small>Request initiated by {materialRequestData.material_requests.created_by}</small>
                                    <small>{formatDateTime(materialRequestData.material_requests.created_on)}</small>
                                </Row>
                                <Row className="mt-5">
                                    <small>Request review pending  </small>
                                    <small>22/04/24 4:38 PM</small>
                                </Row>
                                <Row className="mt-5">
                                    <small>Request approval pending  </small>
                                    <small>22/04/24 4:38 PM</small>
                                </Row>
                            </Col>
                        </Row>
                        <Modal.Footer className='mt-3'>
                            <Row >
                                <Col md={6}>
                                    <div className=' mt-1'>
                                        <Button onClick={closeModal} className='cancel-btn'>
                                            <span className="text-center">Cancel </span>                                        </Button>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className=' mt-1'>
                                        <Button type='submit' className=' border-0 create-btn bg-color-1' onClick={() => handleOpenConfirmReviewMaterialRequestModal(materialRequestId)}>
                                            <span className="text-center"> Review</span>                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Modal.Footer>
                    </Modal.Body>
                </div>
            </Modal>
            <ConfirmReviewMaterialRequest show={confirmReviewMaterialRequestModalOpen} closeModal={handleCloseConfirmReviewMaterialRequestModal} projectId={projectId!} materialRequestId={selectedMaterialRequestId!} />
        </Container>
    );
}

export default ReviewMaterialRequest;
