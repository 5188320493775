import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    ApprovedMaterialRequests, ApprovedLabourRequest, ApprovedPettyRequest,
    PayApprovedMaterialRequestsData, PayApprovedMaterialRequestsResponse, PayApprovedLabourRequestsData, PayApprovedLabourRequestsResponse, PayApprovedPettyRequestsData, PayApprovedPettyRequestsResponse,
    getAllApprovedMaterialRequests, getAllApprovedLabourRequests, getAllApprovedPettyRequests,
    payApprovedMaterialRequest, payApprovedLabourRequest, payApprovedPettyRequest,
} from "../../services/accounts/accountsService";
import { GetAMaterialRequestResponse, GetALabourRequestResponse, GetAPettyRequestResponse, MaterialRequest, LabourRequest, PettyRequest } from "../../services/requests/requestsService";

export interface AccountState {
    companyId?: string | undefined;
    payApprovedMaterialRequestsResponse?: PayApprovedMaterialRequestsResponse | null;
    payApprovedLabourRequestsResponse?: PayApprovedLabourRequestsResponse | null;
    payApprovedPettyRequestsResponse?: PayApprovedPettyRequestsResponse | null;
    getAMaterialRequestResponse?: GetAMaterialRequestResponse | null;
    getALabourRequestResponse?: GetALabourRequestResponse | null;
    getAPettyRequestResponse?: GetAPettyRequestResponse | null;
    approvedMaterialRequests: ApprovedMaterialRequests[] | null;
    materialRequestData?: MaterialRequest | null;
    materialRequestId?: string;
    approvedLabourRequests: ApprovedLabourRequest[] | null;
    labourRequestData?: LabourRequest | null;
    labourRequestId?: string;
    approvedPettyRequests: ApprovedPettyRequest[] | null;
    pettyRequestData?: PettyRequest | null;
    pettyRequestId?: string;
}

const initialState: AccountState = {
    companyId: "",
    payApprovedMaterialRequestsResponse: null,
    payApprovedLabourRequestsResponse: null,
    payApprovedPettyRequestsResponse: null,
    getAMaterialRequestResponse: null,
    getALabourRequestResponse: null,
    getAPettyRequestResponse: null,
    approvedMaterialRequests: null,
    materialRequestData: null,
    materialRequestId: "",
    approvedLabourRequests: null,
    labourRequestData: null,
    labourRequestId: "",
    approvedPettyRequests: null,
    pettyRequestData: null,
    pettyRequestId: "",
}

export const payApprovedMaterialRequestAsync = createAsyncThunk<PayApprovedMaterialRequestsResponse, PayApprovedMaterialRequestsData>(
    'accounts/payApprovedMaterialRequest',
    async (payApprovedMaterialRequestsData: PayApprovedMaterialRequestsData, { rejectWithValue }) => {
        try {
            const response = await payApprovedMaterialRequest(payApprovedMaterialRequestsData);
            return response;
        } catch (error: any) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ error: true, message: "An unexpected error occurred" });
        }
    }
);
export const getAllApprovedMaterialRequestsAsync = createAsyncThunk(
    'accounts/getAllApprovedMaterialRequests',
    async ({ companyId, projectId }: { companyId: string; projectId: string }) => {
        try {
            console.log('Company ID:', companyId);
            const approvedMaterialRequests = await getAllApprovedMaterialRequests(companyId, projectId);
            return approvedMaterialRequests;
        } catch (error) {
            console.error('Error fetching approved material requests:', error);
            throw error;
        }
    }
);
export const payApprovedLabourRequestAsync = createAsyncThunk<PayApprovedLabourRequestsResponse, PayApprovedLabourRequestsData>(
    'accounts/payApprovedLabourRequest',
    async (payApprovedLabourRequestsData: PayApprovedLabourRequestsData, { rejectWithValue }) => {
        try {
            const response = await payApprovedLabourRequest(payApprovedLabourRequestsData);
            return response;
        } catch (error: any) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ error: true, message: "An unexpected error occurred" });
        }
    }
);
export const getAllApprovedLabourRequestsAsync = createAsyncThunk(
    'accounts/getAllApprovedLabourRequests',
    async ({ companyId, projectId }: { companyId: string; projectId: string }) => {
        try {
            console.log('Company ID:', companyId);
            const approvedLabourRequests = await getAllApprovedLabourRequests(companyId, projectId);
            return approvedLabourRequests;
        } catch (error) {
            console.error('Error fetching approved labour requests:', error);
            throw error;
        }
    }
);
export const payApprovedPettyRequestAsync = createAsyncThunk<PayApprovedPettyRequestsResponse, PayApprovedPettyRequestsData>(
    'accounts/payApprovedPettyRequest',
    async (payApprovedPettyRequestsData: PayApprovedPettyRequestsData, { rejectWithValue }) => {
        try {
            const response = await payApprovedPettyRequest(payApprovedPettyRequestsData);
            return response;
        } catch (error: any) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ error: true, message: "An unexpected error occurred" });
        }
    }
);
export const getAllApprovedPettyRequestsAsync = createAsyncThunk(
    'accounts/getAllApprovedPettyRequests',
    async ({ companyId, projectId }: { companyId: string; projectId: string }) => {
        try {
            console.log('Company ID:', companyId);
            const approvedPettyRequests = await getAllApprovedPettyRequests(companyId, projectId);
            return approvedPettyRequests;
        } catch (error) {
            console.error('Error fetching approved petty cash requests:', error);
            throw error;
        }
    }
);

const accountsSlice = createSlice({
    name: "accounts",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(payApprovedMaterialRequestAsync.fulfilled, (state, action) => {
            state.payApprovedMaterialRequestsResponse = action.payload;
        });
        builder.addCase(getAllApprovedMaterialRequestsAsync.fulfilled, (state, action) => {
            state.approvedMaterialRequests = action.payload;
        });
        builder.addCase(payApprovedLabourRequestAsync.fulfilled, (state, action) => {
            state.payApprovedLabourRequestsResponse = action.payload;
        });
        builder.addCase(getAllApprovedLabourRequestsAsync.fulfilled, (state, action) => {
            state.approvedLabourRequests = action.payload;
        });
        builder.addCase(payApprovedPettyRequestAsync.fulfilled, (state, action) => {
            state.payApprovedPettyRequestsResponse = action.payload;
        });
        builder.addCase(getAllApprovedPettyRequestsAsync.fulfilled, (state, action) => {
            state.approvedPettyRequests = action.payload;
        });
    }
})

export default accountsSlice.reducer;


