import React, { useState, useEffect } from "react";
import Layout from "../../../components/layouts/layout";
import { Nav, Table, Dropdown, Button, Card, Row, Col } from "react-bootstrap";
import Action from "../../../assets/images/action-vector.png";
import ConfirmOrder from "./modals/confirm-order";
import { useParams } from "react-router-dom";
import searchIcon from "../../../assets/images/search-icon.svg";
import CreateStoreRequest from "./modals/create-store-request";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/store";
import {
	getAllInventoryAsync,
	getConfirmedInventoryAsync,
} from "slices/inventory/inventorySlice";
import ViewOrder from "./modals/view-order";

function Inventory() {
	const dispatch = useAppDispatch();
	// const inventory = useAppSelector((state:RootState) => state.inventory.inventories)

	const [activeTab, setActiveTab] = useState<string>("confirm-order");
	const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

	const inventory = useAppSelector(
		(state: RootState) => state.inventory.inventory,
	);
	const confirmedInventory = useAppSelector(
		(state: RootState) => state.inventory.confirmedInventory,
	);
	const companyId = localStorage.getItem("userCompanyID");
	const { projectId } = useParams<{ projectId: string }>();

	const [selectedInventoryItemId, setSelectedInventoryItemId] = useState<
		string | null
	>(null);

	const [taskDropdownOpen, setTaskDropdownOpen] = useState<{
		[key: number]: boolean;
	}>({});

	const toggleTaskDropdown = (index: number) => {
		setTaskDropdownOpen((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};

	const tabOrder: string[] = [
		"confirm-order",
		"material-list",
		"store-request",
		"usage",
	];

	const handleTabSelect = (key: string | null) => {
		if (key) {
			setActiveTabIndex(tabOrder.indexOf(key));
			setActiveTab(key);
		}
	};

	const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
	const handleOpenCreateModal = () => setCreateModalOpen(true);
	const handleCloseCreateModal = () => setCreateModalOpen(false);

	const [confirmOrderDropdownOpen, setConfirmOrderDropdownOpen] =
		useState<boolean>(false);
	const toggleConfirmOrderDropdown = () =>
		setConfirmOrderDropdownOpen(!confirmOrderDropdownOpen);

	const [confirmOrderModalOpen, setConfirmOrderModalOpen] =
		useState<boolean>(false);
	const handleOpenConfirmOrderModal = (inventoryItemId: string) => {
		setSelectedInventoryItemId(inventoryItemId);
		setConfirmOrderModalOpen(true);
	};
	const handleCloseConfirmOrderModal = () => {
		setConfirmOrderModalOpen(false);
		setSelectedInventoryItemId(null);
	};

	const [viewOrderModalOpen, setViewOrderModalOpen] = useState<boolean>(false);
	const handleOpenViewOrderModal = (inventoryItemId: string) => {
		setSelectedInventoryItemId(inventoryItemId);
		setViewOrderModalOpen(true);
	};
	const handleCloseViewOrderModal = () => {
		setViewOrderModalOpen(false);
		setSelectedInventoryItemId(null);
	};

	const [refreshData, setRefreshData] = useState(false);

	useEffect(() => {
		setActiveTab("confirm-order");
	}, []);

	useEffect(() => {
		if (companyId && projectId) {
			dispatch(
				getAllInventoryAsync({ company_id: companyId, projectId: projectId }),
			);
		}
	}, [companyId, dispatch, projectId]);

	useEffect(() => {
		if (companyId && projectId) {
			dispatch(
				getConfirmedInventoryAsync({
					company_id: companyId,
					projectId: projectId,
				}),
			);
		}
	}, [companyId, dispatch, projectId]);

	useEffect(() => {
		if (refreshData && companyId && projectId) {
			dispatch(
				getConfirmedInventoryAsync({
					company_id: companyId,
					projectId: projectId,
				}),
			);
		}
	}, [companyId, dispatch, projectId, refreshData]);

	useEffect(() => {
		if (refreshData && companyId && projectId) {
			dispatch(
				getAllInventoryAsync({
					company_id: companyId,
					projectId: projectId,
				}),
			);
		}
	}, [companyId, dispatch, projectId, refreshData]);

	const handleCreateSuccess = () => {
		setRefreshData(true);
	};
	const handleConfirmSuccess = () => {
		setRefreshData(true);
	};

	const getStatusColor = (status: any) => {
		switch (status.toLowerCase()) {
			case "confirmed":
				return "green";
			case "pending":
				return "goldenrod";
			default:
				return "gray";
		}
	};

	return (
		<div>
			<Layout>
				<div className="pt-5 p-3">
					<h3>Inventory</h3>

					<Nav
						variant="underline"
						activeKey={activeTab}
						onSelect={handleTabSelect}
						className="profile-nav"
					>
						<Nav.Item>
							<Nav.Link
								eventKey="confirm-order"
								style={{
									color:
										activeTab === "confirm-order" ? " #0F6CBD " : " #747487",
									textDecoration: "none",
									borderBottom:
										activeTab === "confirm-order"
											? "3px solid #0F6CBD "
											: "2px solid transparent",
								}}
							>
								Confirm order
							</Nav.Link>
						</Nav.Item>
						<Nav.Item className="">
							<Nav.Link
								eventKey="material-list"
								style={{
									color:
										activeTab === "material-list" ? " #0F6CBD " : " #747487",
									textDecoration: "none",
									borderBottom:
										activeTab === "material-list"
											? "3px solid #0F6CBD "
											: "2px solid transparent",
								}}
							>
								Material list
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link
								eventKey="store-request"
								style={{
									color:
										activeTab === "store-request" ? " #0F6CBD " : " #747487",
									textDecoration: "none",
									borderBottom:
										activeTab === "store-request"
											? "3px solid #0F6CBD "
											: "2px solid transparent",
								}}
							>
								Store request
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link
								eventKey="usage"
								style={{
									color: activeTab === "usage" ? " #0F6CBD " : " #747487",
									textDecoration: "none",
									borderBottom:
										activeTab === "usage"
											? "3px solid #0F6CBD "
											: "2px solid transparent",
								}}
							>
								Usage
							</Nav.Link>
						</Nav.Item>
					</Nav>
					<div>
						{activeTab === "confirm-order" && (
							<div className="pt-5">
								<Table borderless responsive className="mt-5">
									<thead>
										<tr>
											<th className="text-color-1 bg-color-3">S/N</th>
											<th className="text-color-1 bg-color-3">Date </th>
											<th className="text-color-1 bg-color-3">Material </th>
											<th className="text-color-1 bg-color-3">Qty ordered </th>
											<th className="text-color-1 bg-color-3">Qty received </th>
											<th className="text-color-1 bg-color-3">Qty remaining</th>
											<th className="text-color-1 bg-color-3">Status</th>
											<th className="text-color-1 bg-color-3"></th>
										</tr>
									</thead>
									<tbody>
										{inventory?.map((item, index) => (
											<tr key={item.id}>
												<td style={{ verticalAlign: "middle" }}>
													{inventory.indexOf(item) + 1}
												</td>
												<td style={{ verticalAlign: "middle" }}>{item.date}</td>
												<td style={{ verticalAlign: "middle" }}>
													{item.material}
												</td>
												<td style={{ verticalAlign: "middle" }}>
													{item.qty_ordered}
												</td>
												<td style={{ verticalAlign: "middle" }}>
													{item.qty_received}
												</td>
												<td style={{ verticalAlign: "middle" }}>
													{item.qty_remaining}
												</td>
												<td
													style={{
														color: getStatusColor(item.status),
														verticalAlign: "middle",
													}}
												>
													{item.status}
												</td>
												<td style={{ verticalAlign: "middle" }}>
													<Dropdown
														show={taskDropdownOpen[index]}
														onToggle={() => toggleTaskDropdown(index)}
													>
														<Dropdown.Toggle
															className="dropdown-toggle"
															variant="transparent"
															style={{
																background: "none",
																border: "none",
																boxShadow: "none",
															}}
														>
															<img
																src={Action}
																alt="Action"
																className="action-icon"
																style={{ margin: "0", padding: "0" }}
															/>
														</Dropdown.Toggle>
														<Dropdown.Menu>
															<Dropdown.Item
																onClick={() =>
																	handleOpenViewOrderModal(item.id)
																}
															>
																View
															</Dropdown.Item>
															<Dropdown.Item
																onClick={() =>
																	handleOpenConfirmOrderModal(item.id)
																}
															>
																Confirm
															</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</td>
											</tr>
										))}
									</tbody>
								</Table>
							</div>
						)}
						{activeTab === "material-list" && (
							<div className="pt-5">
								{/* <div className="w-100 d-inline-flex justify-content-between align-items-end"> */}
								<Row className='mt-5'>
									<Col md={2}>
										<Card className='bg-color-5 text-color-4 border-0 p-2'>
											<small>Material count</small>
											<h3>7</h3>
										</Card>
									</Col>
									<Col md={2}>
										<Card className='bg-color-6 text-color-5 border-0 p-2'>
											<small>Total material qty  </small>
											<h3>3</h3>
										</Card>
									</Col>
									<Col md={2}>
										<Card className='bg-color-7 text-color-6 border-0 p-2'>
											<small> Total out of stock</small>
											<h3>1</h3>
										</Card>
									</Col>
									<Col md={6}>
										<div className="mx-2 border border-1 rounded py-1 px-2 d-inline-flex justify-content-between align-items-center">
											<input
												type="search"
												placeholder="Search for material"
												className="bg-transparent border-0"
												style={{ outline: "0" }}
											/>
											<img src={searchIcon} alt="search icon" />
										</div>
									</Col>
								</Row>
								{/* </div> */}
								<Table borderless responsive className="mt-5">
									<thead>
										<tr>
											<th className="text-color-1 bg-color-3">S/N</th>
											<th className="text-color-1 bg-color-3">
												Material name{" "}
											</th>
											<th className="text-color-1 bg-color-3">Unit in stock</th>
											<th className="text-color-1 bg-color-3">Status</th>
										</tr>
									</thead>
									<tbody>
										{inventory?.map((item, index) => (
											<tr key={item.id}>
												<td style={{ verticalAlign: "middle" }}>
													{inventory.indexOf(item) + 1}
												</td>
												<td style={{ verticalAlign: "middle" }}>{item.date}</td>
												<td style={{ verticalAlign: "middle" }}>
													{/* {item.material} */}
												</td>
												<td style={{ verticalAlign: "middle" }}>
													{/* {item.qty_remaining} */}
												</td>
												<td
													style={{
														color: getStatusColor(item.status),
														verticalAlign: "middle",
													}}
												>
													{/* {item.status} */}
												</td>
											</tr>
										))}
									</tbody>
								</Table>
							</div>
						)}
						{activeTab === "store-request" && (
							<div className="pt-5">
								<div className="w-100 d-inline-flex justify-content-end align-items-center">
									<Button
										onClick={handleOpenCreateModal}
										className="border-0 bg-color-1 float-end"
									>
										<span className="text-center">Create store request</span>
									</Button>
									<div className="mx-2 border border-1 rounded py-1 px-2 d-inline-flex justify-content-between align-items-center">
										<input
											type="search"
											placeholder="Search for material"
											className="bg-transparent border-0"
											style={{ outline: "0" }}
										/>
										<img src={searchIcon} alt="search icon" />
									</div>
								</div>
								<Table borderless responsive className="mt-5">
									<thead>
										<tr>
											<th className="text-color-1 bg-color-3">S/N</th>
											<th className="text-color-1 bg-color-3">Date </th>
											<th className="text-color-1 bg-color-3">Material </th>
											<th className="text-color-1 bg-color-3">Requested by </th>
											<th className="text-color-1 bg-color-3">Collected by </th>
											<th className="text-color-1 bg-color-3">Qty collected</th>
											<th className="text-color-1 bg-color-3">Action</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>1</td>
											<td>11/03/04 </td>
											<td>Sand</td>
											<td>Mujahid Ahmad</td>
											<td>Ahmad Muhammad</td>
											<td>400</td>
											<td style={{ padding: "0", verticalAlign: "middle" }}>
												<Dropdown
													show={confirmOrderDropdownOpen}
													onToggle={toggleConfirmOrderDropdown}
												>
													<Dropdown.Toggle
														className="dropdown-toggle"
														variant="transparent"
														style={{
															background: "none",
															border: "none",
															boxShadow: "none",
														}}
													>
														<img
															src={Action}
															alt="Action"
															className="action-icon mb-3"
															style={{ margin: "0", padding: "0" }}
														/>
													</Dropdown.Toggle>
													<Dropdown.Menu>
														<Dropdown.Item>View</Dropdown.Item>
														<Dropdown.Item
														// onClick={handleOpenConfirmOrderModal}
														>
															Confirm
														</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											</td>
										</tr>
									</tbody>
								</Table>
							</div>
						)}
						{/* Usage tab */}
						{activeTab === "usage" && <div className="pt-5"></div>}
					</div>
				</div>
				<ConfirmOrder
					show={confirmOrderModalOpen}
					closeModal={handleCloseConfirmOrderModal}
					inventoryItemId={selectedInventoryItemId}
					onConfirmSuccess={handleConfirmSuccess}
					projectId={projectId}
				/>
				<CreateStoreRequest
					show={createModalOpen}
					closeModal={handleCloseCreateModal}
					onCreateSuccess={handleCreateSuccess}
				/>
				<ViewOrder
					show={viewOrderModalOpen}
					closeModal={handleCloseViewOrderModal}
				/>
			</Layout>
		</div>
	);
}

export default Inventory;
