import React from 'react';
import { Container, Button, Image, Card } from 'react-bootstrap';
import VerifiedIcon from '../../../assets/images/verified-icon.png';
import { Link } from 'react-router-dom';

const ResetSuccessful: React.FC = () => {
    return (
        <Container fluid>
            <div className='bg-color-2 '>
                <Card className='auth-card-3'>
                    <div className='text-center p-5'>
                        <Image src={VerifiedIcon} className='mt-5' />
                        <h1 className='my-3'>Password reset successful</h1>
                        <div className='d-grid'>
                            <Button className='bg-color-1 my-3 py-3 border-0 '>
                                <Link to="/sign-in" className='link-styles text-white'>Back to Sign in</Link>
                            </Button>
                        </div>
                    </div>
                </Card>
            </div>

        </Container>
    );
};

export default ResetSuccessful;
