import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Container, Table, Button } from 'react-bootstrap';
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import {
    getALabourRequestAsync,
    setLabourRequestId,
} from '../../../../slices/requests/requestsSlice';
import ConfirmApproveLabourRequest from "./confirm-approve-labour-request";
import { RootState } from "../../../../store/store";
import { format, isValid } from 'date-fns';

interface ApproveLabourRequestProps {
    closeModal: () => void;
    show: boolean;
    labourRequestId: string;
    projectId: string;
}

const ApproveLabourRequest: React.FC<ApproveLabourRequestProps> = ({ closeModal, show, labourRequestId, projectId }) => {
    const formatCurrency = (amount: number): string => {
        return `₦${amount.toLocaleString('en-US')}`;
    };
    const formatDateTime = (dateTime: string | Date): string => {
        const date = new Date(dateTime);
        return isValid(date) ? format(date, "dd/MM/yy h:mm a") : "Invalid Date";
    };
    const dispatch = useAppDispatch();
    const companyId = useAppSelector(
        (state: RootState) => state.auth.user?.company_id || "",
    );
    const labourRequestData = useAppSelector((state: any) => state.requests.labourRequestData);
    const [loading, setLoading] = useState(true);
    const [selectedLabourRequestId, setSelectedLabourRequestId] = useState<string | null>(null);

    const [confirmApproveLabourRequestModalOpen, setConfirmApproveLabourRequestModalOpen] = useState<boolean>(false);
    const handleOpenConfirmApproveLabourRequestModal = (labourRequestId: string) => {
        setSelectedLabourRequestId(labourRequestId);
        setConfirmApproveLabourRequestModalOpen(true);
        closeModal();
    };
    const handleCloseConfirmApproveLabourRequestModal = () => setConfirmApproveLabourRequestModalOpen(false);


    useEffect(() => {
        if (labourRequestId) {
            dispatch(setLabourRequestId(labourRequestId));
            console.log("Fetching labour request with ID:", labourRequestId);
            dispatch(getALabourRequestAsync({ companyId, id: labourRequestId, projectId })).then(() =>
                setLoading(false),
            );
        } else {
            console.log("labourRequestId is not set");
        }
    }, [dispatch, companyId, labourRequestId, projectId]);

    if (loading) {
        return null;
    }
    console.log("labourRequestData", labourRequestData.Labour_requests);

    return (
        <Container>
            <Modal className="modal-container" size="xl" show={show} onHide={closeModal} style={{ maxHeight: "100vh", overflowY: "auto" }}>
                <div className='px-3'>
                    <Modal.Header className='text-start'>
                        <h4 className="confirm-header">Approve labour request</h4>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className='mt-2 pb-2'>
                            <Col md={8} className="col-separator">
                                <Table className="rectangle" borderless responsive >
                                    <thead >
                                        <tr>
                                            <td className='text-color-7'>Head name </td>
                                            <td className='text-color-7'>Labour </td>
                                            <td className='text-color-7'>Stage </td>
                                            <td className='text-color-7'>Amount </td>
                                            <td className='text-color-7'>Bank </td>
                                            <td className='text-color-7'>Acct no </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr key={labourRequestData?.Labour_requests?.id}>
                                            <td className='text-color-7'>{labourRequestData?.Labour_requests?.head_name} </td>
                                            <td className='text-color-7'>{labourRequestData?.Labour_requests?.labour_type} </td>
                                            <td className='text-color-7'>{labourRequestData?.Labour_requests?.stage}</td>
                                            <td className='text-color-7'>{formatCurrency(parseFloat(labourRequestData?.Labour_requests?.amount))}</td>
                                            <td className='text-color-7'>{labourRequestData?.Labour_requests?.bank}</td>
                                            <td className='text-color-7'>{labourRequestData?.Labour_requests?.account_number}</td>
                                        </tr>

                                    </tbody>
                                </Table>
                            </Col>
                            <Col md={4}>
                                <Row className="mt-3">
                                    <small>Request initiated by {labourRequestData?.Labour_requests?.created_by} </small>
                                    <small>{formatDateTime(labourRequestData.Labour_requests.created_on)}</small>
                                </Row>
                                <Row className="mt-5">
                                    <small>Request review pending  </small>
                                    <small>22/04/24 4:38 PM</small>
                                </Row>
                                <Row className="mt-5">
                                    <small>Request approval pending  </small>
                                    <small>22/04/24 4:38 PM</small>
                                </Row>
                            </Col>
                        </Row>
                        <Modal.Footer className='mt-3'>
                            <Row >
                                <Col md={6}>
                                    <div className=' mt-1'>
                                        <Button onClick={closeModal} className='cancel-btn'>
                                            <span className="text-center">Cancel </span>                                        </Button>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className=' mt-1'>
                                        <Button type='submit' className=' border-0 create-btn bg-color-1' onClick={() => handleOpenConfirmApproveLabourRequestModal(labourRequestId)}>
                                            <span className="text-center">Approve</span>                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Modal.Footer>
                    </Modal.Body>
                </div>
            </Modal>
            <ConfirmApproveLabourRequest show={confirmApproveLabourRequestModalOpen} closeModal={handleCloseConfirmApproveLabourRequestModal} projectId={projectId!} labourRequestId={selectedLabourRequestId!} />
        </Container>
    )
}

export default ApproveLabourRequest