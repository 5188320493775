import React, { useState, useEffect } from "react";
import SettingsLayout from "../settings-layout";
import { Nav, Table, Button, Row, Col, Dropdown } from "react-bootstrap";
import CreateMaterial from "./modals/create-material";
import EditMaterial from "./modals/edit-material";
import Action from "../../../assets/images/action-vector.png";
import CreateLabour from "./modals/create-labour";
import EditLabour from "./modals/edit-labour";
import {
	getAllMaterialsAsync,
	getAllLabourAsync,
} from "../../../slices/settings/settingsSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import CloseButton from "../../../components/close-button/close-button";

function Materials() {
	const dispatch = useAppDispatch();
	const materials = useAppSelector((state) => state.settings.materials);
	const labourTypes = useAppSelector((state) => state.settings.labourTypes);
	const companyId = useAppSelector(
		(state: RootState) => state.auth.user?.company_id || "",
	);
	const [activeTab, setActiveTab] = useState<string>("materials");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
	const [selectedMaterialId, setSelectedMaterialId] = useState<string | null>(
		null,
	);
	const [selectedLabourId, setSelectedLabourId] = useState<string | null>(null);

	const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
	const handleOpenCreateModal = () => setCreateModalOpen(true);
	const handleCloseCreateModal = () => setCreateModalOpen(false);

	const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
	const handleOpenEditModal = (materialId: string) => {
		setSelectedMaterialId(materialId);
		setEditModalOpen(true);
	};
	const handleCloseEditModal = () => {
		setEditModalOpen(false);
		setSelectedMaterialId(null);
	};

	const [createLabourModalOpen, setCreateLabourModalOpen] =
		useState<boolean>(false);
	const handleOpenCreateLabourModal = () => setCreateLabourModalOpen(true);
	const handleCloseCreateLabourModal = () => setCreateLabourModalOpen(false);

	const [editLabourModalOpen, setEditLabourModalOpen] =
		useState<boolean>(false);
	const handleOpenEditLabourModal = (labourId: string) => {
		setSelectedLabourId(labourId);
		setEditLabourModalOpen(true);
	};
	const handleCloseEditLabourModal = () => {
		setEditLabourModalOpen(false);
		setSelectedLabourId(null);
	};

	const [dropdownOpen, setDropdownOpen] = useState<{ [key: number]: boolean }>(
		{},
	);
	const [labourDropdownOpen, setLabourDropdownOpen] = useState<{
		[key: number]: boolean;
	}>({});

	const toggleDropdown = (index: number) => {
		setDropdownOpen((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};

	const toggleLabourDropdown = (index: number) => {
		setLabourDropdownOpen((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};
	const [refreshData, setRefreshData] = useState(false);

	useEffect(() => {
		if (companyId) {
			dispatch(getAllMaterialsAsync(companyId));
			dispatch(getAllLabourAsync(companyId));
		}
	}, [companyId, dispatch]);

	useEffect(() => {
		if (refreshData) {
			dispatch(getAllLabourAsync(companyId));
			setRefreshData(false);
		}
	}, [refreshData, companyId, dispatch]);
	useEffect(() => {
		if (refreshData) {
			dispatch(getAllMaterialsAsync(companyId));
			setRefreshData(false);
		}
	}, [refreshData, companyId, dispatch]);

	useEffect(() => {
		setActiveTab("materials");
	}, []);

	const tabOrder: string[] = ["materials", "labour-types"];

	const handleTabSelect = (key: string | null) => {
		if (key) {
			setActiveTabIndex(tabOrder.indexOf(key));
			setActiveTab(key);
		}
	};
	const handleCreateSuccess = () => {
		setRefreshData(true);
	};
	const handleEditSuccess = () => {
		setRefreshData(true);
	};

	console.log("Materials:", materials);
	console.log("LabourTypes:", labourTypes);

	return (
		<div>
			<SettingsLayout>
				<div className="pt-5 p-5">
					<div className="w-100 mb-5 d-inline-flex justify-content-between align-items-center">
						<h4 className="text-color-2">Materials & Labour types</h4>
						<CloseButton />
					</div>

					<Nav
						variant="underline"
						activeKey={activeTab}
						onSelect={handleTabSelect}
						className="settings-nav px-2 py-1"
					>
						<Nav.Item>
							<Nav.Link
								className="py-0 px-2 rounded fw-light"
								eventKey="materials"
								style={{
									border: "none",
									textDecoration: "none",
									color: activeTab === "materials" ? "white" : "darkgray",
									background:
										activeTab === "materials" ? "#0F6CBD" : "transparent",
								}}
							>
								Materials
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link
								className="py-0 px-2 rounded fw-light"
								eventKey="labour-types"
								style={{
									border: "none",
									color: activeTab === "labour-types" ? "white" : "darkgray",
									textDecoration: "none",
									background:
										activeTab === "labour-types" ? "#0F6CBD" : "transparent",
								}}
							>
								Labour types
							</Nav.Link>
						</Nav.Item>
					</Nav>

					<div>
						{activeTab === "materials" && (
							<div>
								<Row className="my-2">
									<Col md={12}>
										<Button
											onClick={handleOpenCreateModal}
											className="py-3 px-5 border-0 bg-color-1 float-end"
										>
											<span className="text-center">Add material</span>
										</Button>
									</Col>
								</Row>
								<Table borderless responsive className="mt-5">
									<thead>
										<tr>
											<th className="text-color-1 bg-color-3">S/N</th>
											<th className="text-color-1 bg-color-3">Material name</th>
											<th className="text-color-1 bg-color-3">
												Unit of measurement
											</th>
											<th className="text-color-1 bg-color-3">
												Material threshold
											</th>
											<th className="text-color-1 bg-color-3">Action</th>
										</tr>
									</thead>
									<tbody>
										{Array.isArray(materials) && materials.length > 0 ? (
											materials.map((material, index) => (
												<tr key={material.id}>
													<td>{index + 1}</td>
													<td>{material.material_name}</td>
													<td>{material.material_unit}</td>
													<td>{material.material_threshold}</td>
													<td style={{ padding: "0", verticalAlign: "middle" }}>
														<Dropdown
															show={dropdownOpen[index]}
															onToggle={() => toggleDropdown(index)}
														>
															<Dropdown.Toggle
																className="dropdown-toggle"
																variant="transparent"
																style={{
																	background: "none",
																	border: "none",
																	boxShadow: "none",
																}}
															>
																<img
																	src={Action}
																	alt="Action"
																	className="action-icon mb-3"
																	style={{ margin: "0", padding: "0" }}
																/>
															</Dropdown.Toggle>
															<Dropdown.Menu>
																<Dropdown.Item
																	onClick={() =>
																		handleOpenEditModal(material.id)
																	}
																>
																	Edit
																</Dropdown.Item>
															</Dropdown.Menu>
														</Dropdown>
													</td>
												</tr>
											))
										) : (
											<tr>
												<td colSpan={6} className="text-center">
													No materials found.
												</td>
											</tr>
										)}
									</tbody>
								</Table>
							</div>
						)}
					</div>
					<div>
						{activeTab === "labour-types" && (
							<div>
								<Row className="my-2">
									<Col md={12}>
										<Button
											onClick={handleOpenCreateLabourModal}
											className="py-3 px-5 border-0 bg-color-1 float-end"
										>
											<span className="text-center">Add labour</span>
										</Button>
									</Col>
								</Row>
								<Table borderless responsive className="mt-5">
									<thead>
										<tr>
											<th className="text-color-1 bg-color-3">S/N</th>
											<th className="text-color-1 bg-color-3">Labour type</th>
											<th className="text-color-1 bg-color-3">Description</th>
											<th className="text-color-1 bg-color-3">Action</th>
										</tr>
									</thead>
									<tbody>
										{Array.isArray(labourTypes) && labourTypes.length > 0 ? (
											labourTypes.map((labour, index) => (
												<tr key={labour.id}>
													<td>{index + 1}</td>
													<td>{labour.labour_type}</td>
													<td>{labour.labour_description}</td>
													<td style={{ padding: "0", verticalAlign: "middle" }}>
														<Dropdown
															show={labourDropdownOpen[index]}
															onToggle={() => toggleLabourDropdown(index)}
														>
															<Dropdown.Toggle
																className="dropdown-toggle"
																variant="transparent"
																style={{
																	background: "none",
																	border: "none",
																	boxShadow: "none",
																}}
															>
																<img
																	src={Action}
																	alt="Action"
																	className="action-icon mb-3"
																	style={{ margin: "0", padding: "0" }}
																/>
															</Dropdown.Toggle>
															<Dropdown.Menu>
																<Dropdown.Item
																	onClick={() =>
																		handleOpenEditLabourModal(labour.id)
																	}
																>
																	Edit
																</Dropdown.Item>
															</Dropdown.Menu>
														</Dropdown>
													</td>
												</tr>
											))
										) : (
											<tr>
												<td colSpan={4} className="text-center">
													No labour types found.
												</td>
											</tr>
										)}
									</tbody>
								</Table>
							</div>
						)}
					</div>
				</div>
				<CreateMaterial
					show={createModalOpen}
					closeModal={handleCloseCreateModal}
					onCreateSuccess={handleCreateSuccess}
				/>
				<EditMaterial
					show={editModalOpen}
					closeModal={handleCloseEditModal}
					materialId={selectedMaterialId!}
					onEditSuccess={handleEditSuccess}
				/>
				<CreateLabour
					show={createLabourModalOpen}
					closeModal={handleCloseCreateLabourModal}
					onCreateSuccess={handleCreateSuccess}
				/>
				<EditLabour
					show={editLabourModalOpen}
					closeModal={handleCloseEditLabourModal}
					labourId={selectedLabourId!}
					onEditSuccess={handleEditSuccess}
				/>
			</SettingsLayout>
		</div>
	);
}

export default Materials;
