import React, { useState, useEffect } from 'react'
import SettingsLayout from "../settings-layout";
import { Table, Button, Row, Col, Dropdown } from "react-bootstrap";
import Action from "../../../assets/images/action-vector.png";
import CreateStage from './modals/create-stage';
import EditStage from './modals/edit-stage';
import CloseButton from "../../../components/close-button/close-button";
import { getAllStagesAsync } from '../../../slices/settings/settingsSlice';
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";

function Stages() {
    const dispatch = useAppDispatch();
    const stages = useAppSelector((state) => state.settings.stages);
    const companyId = useAppSelector(
        (state: RootState) => state.auth.user?.company_id || "",
    );
    const [selectedStageId, setSelectedStageId] = useState<string | null>(null);

    const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
    const handleOpenCreateModal = () => setCreateModalOpen(true);
    const handleCloseCreateModal = () => setCreateModalOpen(false);

    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
    const handleOpenEditModal = (stageId: string) => {
        setSelectedStageId(stageId);
        setEditModalOpen(true);
    }
    const handleCloseEditModal = () => {
        setEditModalOpen(false);
        setSelectedStageId(null);
    }

    const [dropdownOpen, setDropdownOpen] = useState<{ [key: number]: boolean }>({});
    const toggleDropdown = (index: number) => {
        setDropdownOpen(prevState => ({ ...prevState, [index]: !prevState[index] }));
    };
    const [refreshData, setRefreshData] = useState(false);

    useEffect(() => {
        if (companyId) {
            dispatch(getAllStagesAsync(companyId));
        }
    }, [companyId, dispatch]);
    useEffect(() => {
        if (refreshData) {
            dispatch(getAllStagesAsync(companyId));
            setRefreshData(false);
        }
    }, [refreshData, companyId, dispatch]);
    const handleCreateSuccess = () => {
        setRefreshData(true);
    };
    const handleEditSuccess = () => {
        setRefreshData(true);
    };
    return (
        <div>
            <SettingsLayout>
                <div className="pt-5 p-5">
                    <div className="w-100 mb-5 d-inline-flex justify-content-between align-items-center">
                        <h4 className="text-color-2">Stages</h4>
                        <CloseButton />
                    </div>

                    <div>
                        <Row className="my-2">
                            <Col md={12}>
                                <Button
                                    onClick={handleOpenCreateModal}
                                    className="py-3 px-5 border-0 bg-color-1 float-end"
                                >
                                    <span className="text-center">Add stage</span>
                                </Button>
                            </Col>
                        </Row>
                        <Table borderless responsive className="mt-5">
                            <thead>
                                <tr>
                                    <th className="text-color-1 bg-color-3">S/N</th>
                                    <th className="text-color-1 bg-color-3">Stage</th>
                                    <th className="text-color-1 bg-color-3">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(stages) && stages.length > 0 ? (
                                    stages.map((stage, index) => (
                                        <tr key={stage.id}>
                                            <td>{index + 1}</td>
                                            <td>{stage.stage}</td>
                                            <td style={{ padding: '0', verticalAlign: 'middle' }}>
                                                <Dropdown show={dropdownOpen[index]} onToggle={() => toggleDropdown(index)}>
                                                    <Dropdown.Toggle className="dropdown-toggle" variant="transparent" style={{ background: 'none', border: 'none', boxShadow: 'none' }}>
                                                        <img src={Action} alt="Action" className="action-icon mb-3" style={{ margin: '0', padding: '0' }} />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => handleOpenEditModal(stage.id)}>Edit</Dropdown.Item>
                                                        <Dropdown.Item >Delete</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={6} className="text-center">No stages found.</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </div>
                <CreateStage
                    show={createModalOpen}
                    closeModal={handleCloseCreateModal}
                    onCreateSuccess={handleCreateSuccess}
                />
                <EditStage
                    show={editModalOpen}
                    closeModal={handleCloseEditModal}
                    stageId={selectedStageId!}
                    onEditSuccess={handleEditSuccess} />
            </SettingsLayout>
        </div>
    )
}

export default Stages