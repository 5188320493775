import React from 'react';
import { Modal, Button, Row, Col, Container, Form } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

interface EditBudgetValues {
    materialBudget: string;
    labourBudget: string;
    miscellaneousBudget: string;

}
interface EditBudgetProps {
    closeModal: () => void;
    show: boolean;

}

const EditBudget: React.FC<EditBudgetProps> = ({ closeModal, show }) => {
    const navigate = useNavigate();

    return (
        <Container fluid className=''>
            <Modal size="lg" show={show} onHide={closeModal} style={{ maxHeight: "100vh", overflowY: "auto" }}>
                <div className='px-5'>
                    <Formik
                        initialValues={{
                            materialBudget: '',
                            labourBudget: '',
                            miscellaneousBudget: '',
                        }}
                        validationSchema={Yup.object().shape({
                            materialBudget: Yup.string().required('Required'),
                            labourBudget: Yup.string().required('Required'),
                            miscellaneousBudget: Yup.string().required('Required'),

                        })}
                        onSubmit={(values: EditBudgetValues) => {
                            console.log(values);
                            navigate('/budget');

                        }}
                        validateOnChange
                        validateOnBlur
                        validateOnSubmit
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            isSubmitting,
                            values,
                            handleChange,
                        }) => (
                            <Form>
                                <Modal.Header className='text-start mt-3'>
                                    <h5>Edit budget</h5>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row>
                                        <Col className='mt-2'>
                                            <label className='mt-2'>
                                                <p>Estimated cost for material{" "}
                                                    <span className="text-danger">*</span>
                                                </p>
                                            </label>
                                            <Field
                                                type='number'
                                                name='materialBudget'
                                                className={`fluent-input  ${touched.materialBudget && errors.materialBudget ? 'is-invalid' : ''
                                                    }`}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='mt-2'>
                                            <label className='mt-2'>
                                                <p>Estimated cost for  labour{" "}
                                                    <span className="text-danger">*</span>
                                                </p>
                                            </label>
                                            <Field
                                                type='number'
                                                name='labourBudget'
                                                className={`fluent-input  ${touched.labourBudget && errors.labourBudget ? 'is-invalid' : ''
                                                    }`}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='mt-2'>
                                            <label className='mt-2'>
                                                <p>Estimated cost for miscellaneous{" "}
                                                    <span className="text-danger">*</span>
                                                </p>
                                            </label>
                                            <Field
                                                type='number'
                                                name='miscellaneousBudget'
                                                className={`fluent-input  ${touched.miscellaneousBudget && errors.miscellaneousBudget ? 'is-invalid' : ''
                                                    }`}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Modal.Footer className='mt-5'>
                                        <Row >
                                            <Col md={6}>
                                                <div className=' mt-1'>
                                                    <Button onClick={closeModal} className='cancel-btn'>
                                                        <span className="text-center">Cancel </span>                                        </Button>
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className=' mt-1'>
                                                    <Button type='submit' className=' border-0 create-btn bg-color-1'>
                                                        <span className="text-center">Edit budget</span>                                        </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Modal.Footer>
                                </Modal.Body>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </Container>
    );
};

export default EditBudget;
