import axios from "axios";

export interface Inventory {
    id: string;
    date: string;
    material: string;
    qty_ordered: string;
    qty_received: string;
    qty_remaining: string;
    status: string;
    company_id: string;
    projectId: string;
}
export interface ConfirmAnInventoryData {
    comment: string;
    company_id: string;
    confirmed_quantity: number;
    id: string;
    projectId: string;
}

export interface GetAllInventoryResponse {
    data: Inventory[];
    message: string;
}
export interface GetConfirmedInventoryResponse {
    data: ConfirmAnInventoryData[];
    message: string;
}
// export interface GetAnInventoryResponse {
//     data: Inventory;
//     message: string;
// }
export interface ConfirmAnInventoryResponse {
    data: ConfirmAnInventoryData;
    message: string;
}
export interface GetLowStockResponse {
    data: Inventory[];
    message: string;
}

export const getAllInventory = async (company_id: string, projectId: string): Promise<GetAllInventoryResponse> => {
    const response = await axios.get(`https://syteboxx-api.remsys.com.ng/api/v1/projects/${projectId}/inventory?company_id=${company_id}`);
    return response.data;
}
export const getConfirmedInventory = async (company_id: string, projectId: string): Promise<GetConfirmedInventoryResponse> => {
    const response = await axios.get(`https://syteboxx-api.remsys.com.ng/api/v1/projects/${projectId}/inventory/material-list?company_id=${company_id}`);
    return response.data;
}

// export const getAnInventory = async (company_id: string, projectId: string): Promise<GetAnInventoryResponse> => {
//     const response = await axios.get(`https://syteboxx-api.remsys.com.ng/api/v1/projects/${projectId}/inventory?company_id=${company_id}`);
//     return response.data;
// }

export const confirmAnInventory = async (data: ConfirmAnInventoryData): Promise<ConfirmAnInventoryResponse> => {
    const response = await axios.post(`https://syteboxx-api.remsys.com.ng/api/v1/projects/${data.projectId}/inventory/${data.id}/confirm?company_id=${data.company_id}&confirmed_quantity=${data.confirmed_quantity}&comment=${data.comment}`);
    return response.data;
}

export const getLowStock = async (company_id: string): Promise<GetLowStockResponse> => {
    const response = await axios.get(`https://syteboxx-api.remsys.com.ng/api/inventory/${company_id}`);
    return response.data;
}
