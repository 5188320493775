import React, { useState, useEffect } from 'react';
import Layout from '../../../components/layouts/layout';
import { Nav, Table, Dropdown } from 'react-bootstrap';
import PayMaterialRequest from "./modals/pay-material-request";
import PayLabourRequest from './modals/pay-labour-request';
import PayUtilityRequest from './modals/pay-utility-request';
import Action from "../../../assets/images/action-vector.png"
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import { getAllApprovedLabourRequestsAsync, getAllApprovedMaterialRequestsAsync, getAllApprovedPettyRequestsAsync } from '../../../slices/accounts/accountsSlice';
import { format } from 'date-fns';

function Accounts() {
    const formatCurrency = (amount: number): string => {
        return `₦${amount.toLocaleString('en-US')}`;
    };
    const dispatch = useAppDispatch();
    const { projectId } = useParams<{ projectId: string }>();
    const companyId = useAppSelector(
        (state: RootState) => state.auth.user?.company_id || "",
    );
    const approvedMaterialRequests = useAppSelector((state) => state.accounts.approvedMaterialRequests)
    const approvedLabourRequests = useAppSelector((state) => state.accounts.approvedLabourRequests)
    const approvedPettyRequests = useAppSelector((state) => state.accounts.approvedPettyRequests)
    const [refreshData, setRefreshData] = useState(false);


    const [activeTab, setActiveTab] = useState<string>('material-request');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
    useEffect(() => {
        setActiveTab('material-request');
    }, []);

    const tabOrder: string[] = ['material-request', 'labour-request', 'utility-request'];

    const handleTabSelect = (key: string | null) => {
        if (key) {
            setActiveTabIndex(tabOrder.indexOf(key));
            setActiveTab(key);
        }
    };
    const [selectedMaterialRequestId, setSelectedMaterialRequestId] = useState<string | null>(null);
    const [selectedLabourRequestId, setSelectedLabourRequestId] = useState<string | null>(null);
    const [selectedPettyRequestId, setSelectedPettyRequestId] = useState<string | null>(null);

    const [payMaterialRequestModalOpen, setPayMaterialRequestModalOpen] = useState<boolean>(false);
    const handleOpenPayMaterialRequestModal = (materialRequestId: string) => {
        setSelectedMaterialRequestId(materialRequestId);
        setPayMaterialRequestModalOpen(true);
    };
    const handleClosePayMaterialRequestModal = () => setPayMaterialRequestModalOpen(false);

    const [materialRequestDropdownOpen, setMaterialRequestDropdownOpen] = useState<{
        [key: number]: boolean;
    }>({});
    const toggleMaterialRequestDropdown = (index: number) => {
        setMaterialRequestDropdownOpen((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const [payLabourRequestModalOpen, setPayLabourRequestModalOpen] = useState<boolean>(false);
    const handleOpenPayLabourRequestModal = (labourRequestId: string) => {
        setSelectedLabourRequestId(labourRequestId);
        setPayLabourRequestModalOpen(true);
    };

    const handleClosePayLabourRequestModal = () => setPayLabourRequestModalOpen(false);

    const [labourRequestDropdownOpen, setLabourRequestDropdownOpen] = useState<{
        [key: number]: boolean;
    }>({});

    const toggleLabourRequestDropdown = (index: number) => {
        setLabourRequestDropdownOpen((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const [payUtilityRequestModalOpen, setPayUtilityRequestModalOpen] = useState<boolean>(false);
    const handleOpenPayUtilityRequestModal = (pettyRequestId: string) => {
        setSelectedPettyRequestId(pettyRequestId);
        setPayUtilityRequestModalOpen(true);
    };

    const handleClosePayUtilityRequestModal = () => setPayUtilityRequestModalOpen(false);

    const [utilityRequestDropdownOpen, setUtilityRequestDropdownOpen] = useState<{
        [key: number]: boolean;
    }>({});

    const toggleUtilityRequestDropdown = (index: number) => {
        setUtilityRequestDropdownOpen((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    useEffect(() => {
        if (companyId && projectId) {
            console.log("companyId:", companyId);
            dispatch(getAllApprovedMaterialRequestsAsync({ companyId, projectId }));
            dispatch(getAllApprovedLabourRequestsAsync({ companyId, projectId }));
            dispatch(getAllApprovedPettyRequestsAsync({ companyId, projectId }));
        }
    }, [companyId, dispatch, projectId]);

    useEffect(() => {
        if (refreshData && companyId && projectId) {
            dispatch(getAllApprovedMaterialRequestsAsync({ companyId, projectId }));
            dispatch(getAllApprovedLabourRequestsAsync({ companyId, projectId }));
            dispatch(getAllApprovedPettyRequestsAsync({ companyId, projectId }));
            setRefreshData(false);
        }
    }, [refreshData, companyId, dispatch, projectId]);

    return (
        <div>
            <Layout>
                <div className="pt-5 p-3">
                    <h3>Accounts</h3>
                    <Nav variant="underline"
                        activeKey={activeTab}
                        onSelect={handleTabSelect}
                        className="profile-nav"

                    >
                        <Nav.Item >
                            <Nav.Link eventKey="material-request"
                                style={{
                                    color:
                                        activeTab === 'material-request'
                                            ? ' #0F6CBD '
                                            : ' #747487', textDecoration: 'none',
                                    borderBottom:
                                        activeTab === 'material-request'
                                            ? '3px solid #0F6CBD '
                                            : '2px solid transparent',
                                }}>Material request</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className=''>
                            <Nav.Link eventKey="labour-request"
                                style={{
                                    color:
                                        activeTab === 'labour-request'
                                            ? ' #0F6CBD '
                                            : ' #747487', textDecoration: 'none',
                                    borderBottom:
                                        activeTab === 'labour-request'
                                            ? '3px solid #0F6CBD '
                                            : '2px solid transparent',
                                }}>Labour request</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="utility-request"
                                style={{
                                    color:
                                        activeTab === 'utility-request'
                                            ? ' #0F6CBD '
                                            : ' #747487', textDecoration: 'none',
                                    borderBottom:
                                        activeTab === 'utility-request'
                                            ? '3px solid #0F6CBD '
                                            : '2px solid transparent',
                                }}>Petty cash request</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <div>
                        {activeTab === "material-request" && (
                            <div>
                                <Table borderless responsive className='mt-5 '>
                                    <thead >
                                        <tr>
                                            <th className='text-color-1 bg-color-3'>S/N</th>
                                            <th className='text-color-1 bg-color-3'>Date </th>
                                            <th className='text-color-1 bg-color-3'>Material </th>
                                            <th className='text-color-1 bg-color-3'>Quantity </th>
                                            <th className='text-color-1 bg-color-3'>Cost </th>
                                            <th className='text-color-1 bg-color-3'>Vendor name</th>
                                            <th className='text-color-1 bg-color-3'>Approval status</th>
                                            <th className='text-color-1 bg-color-3'>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(approvedMaterialRequests) && approvedMaterialRequests.length > 0 ? (
                                            approvedMaterialRequests.map((materialRequest, index) => (
                                                <tr key={materialRequest.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{format(new Date(materialRequest.created_on), "dd/MM/yy")}</td>
                                                    <td>{materialRequest.batch_materials}</td>
                                                    <td>{materialRequest.total_quantity}</td>
                                                    <td>{formatCurrency(parseFloat(materialRequest.amount))}</td>
                                                    <td>{materialRequest.vendor_name}</td>
                                                    <td>{materialRequest.status}</td>
                                                    <td style={{ padding: '0', verticalAlign: 'middle' }}>
                                                        <Dropdown show={materialRequestDropdownOpen[index]} onToggle={() => toggleMaterialRequestDropdown(index)}>
                                                            <Dropdown.Toggle className="dropdown-toggle" variant="transparent" style={{ background: 'none', border: 'none', boxShadow: 'none' }}>
                                                                <img src={Action} alt="Action" className="action-icon mb-3" style={{ margin: '0', padding: '0' }} />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => handleOpenPayMaterialRequestModal(materialRequest.id)}>Pay</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>

                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={8} className="text-center">No material requests found.</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        )}

                    </div>
                    <div>
                        {activeTab === "labour-request" && (
                            <div>
                                <Table borderless responsive className='mt-5 '>
                                    <thead >
                                        <tr>
                                            <th className='text-color-1 bg-color-3'>S/N</th>
                                            <th className='text-color-1 bg-color-3'>Date </th>
                                            <th className='text-color-1 bg-color-3'>Labour type </th>
                                            <th className='text-color-1 bg-color-3'>Stage </th>
                                            <th className='text-color-1 bg-color-3'>Amount </th>
                                            <th className='text-color-1 bg-color-3'>Approval status</th>
                                            <th className='text-color-1 bg-color-3'>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(approvedLabourRequests) && approvedLabourRequests.length > 0 ? (
                                            approvedLabourRequests.map((labourRequest, index) => (
                                                <tr key={labourRequest.id}>
                                                    <td>{index + 1}</td>
                                                    <td> {format(new Date(labourRequest.created_on), "dd/MM/yy")} </td>
                                                    <td>{labourRequest.labour_type} </td>
                                                    <td>{labourRequest.stage}</td>
                                                    <td>{formatCurrency(parseFloat(labourRequest.amount))}</td>
                                                    <td>{labourRequest.status}</td>
                                                    <td style={{ padding: '0', verticalAlign: 'middle' }}>
                                                        <Dropdown show={labourRequestDropdownOpen[index]} onToggle={() => toggleLabourRequestDropdown(index)}>
                                                            <Dropdown.Toggle className="dropdown-toggle" variant="transparent" style={{ background: 'none', border: 'none', boxShadow: 'none' }}>
                                                                <img src={Action} alt="Action" className="action-icon mb-3" style={{ margin: '0', padding: '0' }} />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => handleOpenPayLabourRequestModal(labourRequest.id)}>Pay</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={6} className="text-center">No labour requests found.</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        )}

                    </div>
                    <div>
                        {activeTab === "utility-request" && (
                            <div>
                                <Table borderless responsive className='mt-5 '>
                                    <thead >
                                        <tr>
                                            <th className='text-color-1 bg-color-3'>S/N</th>
                                            <th className='text-color-1 bg-color-3'>Date </th>
                                            <th className='text-color-1 bg-color-3'> Title </th>
                                            <th className='text-color-1 bg-color-3'>Receiver name </th>
                                            <th className='text-color-1 bg-color-3'>Amount </th>
                                            <th className='text-color-1 bg-color-3'>Approval status</th>
                                            <th className='text-color-1 bg-color-3'>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(approvedPettyRequests) && approvedPettyRequests.length > 0 ? (
                                            approvedPettyRequests.map((pettyRequest, index) => (
                                                <tr key={pettyRequest.id}>
                                                    <td>{index + 1}</td>
                                                    <td> {format(new Date(pettyRequest.created_on), "dd/MM/yy")} </td>
                                                    <td>{pettyRequest.title}</td>
                                                    <td>{pettyRequest.receiver_name}</td>
                                                    <td>{formatCurrency(parseFloat(pettyRequest.amount))}</td>
                                                    <td>{pettyRequest.status}</td>
                                                    <td style={{ padding: '0', verticalAlign: 'middle' }}>
                                                        <Dropdown show={utilityRequestDropdownOpen[index]} onToggle={() => toggleUtilityRequestDropdown(index)}>
                                                            <Dropdown.Toggle className="dropdown-toggle" variant="transparent" style={{ background: 'none', border: 'none', boxShadow: 'none' }}>
                                                                <img src={Action} alt="Action" className="action-icon mb-3" style={{ margin: '0', padding: '0' }} />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => handleOpenPayUtilityRequestModal(pettyRequest.id)}>Pay</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={6} className="text-center">No petty cash requests found.</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        )}

                    </div>
                </div>
                <PayMaterialRequest show={payMaterialRequestModalOpen} closeModal={handleClosePayMaterialRequestModal} projectId={projectId!} materialRequestId={selectedMaterialRequestId!} />
                <PayLabourRequest show={payLabourRequestModalOpen} closeModal={handleClosePayLabourRequestModal} projectId={projectId!} labourRequestId={selectedLabourRequestId!} />
                <PayUtilityRequest show={payUtilityRequestModalOpen} closeModal={handleClosePayUtilityRequestModal} projectId={projectId!} pettyRequestId={selectedPettyRequestId!} />
            </Layout>
        </div>
    )
}

export default Accounts