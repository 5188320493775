import React, { useState } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import AddCircle from "../../../../assets/images/add-circle.svg";
import Trash from "../../../../assets/images/trash.svg";
import { Project } from "../../../../services/project/projectService";
import {
  Approver,
  createCustomApprovaer,
  CreateCustomApprover,
  Level,
} from "../../../../services/approval/approval";
import { fetchProjectApproversAsync } from "../../../../slices/approve/approveSlice";
import { useAppSelector } from "../../../../store/hooks";
import Select, { MultiValue } from "react-select";
import { RootState } from "../../../../store/store";
import { toast } from "react-toastify";

interface SetupApprovalProps {
  closeModal: () => void;
  show: boolean;
  projects: Project[];
  approver: Approver[];
  customapprover: Approver[];
  projectapprover: Approver[];
}

interface ReviewerLevel {
  level: number;
  user_ids: number[];
  approval_type: "Approval" | "Review";
}

const SetupApprovalLevel: React.FC<SetupApprovalProps> = ({
  closeModal,
  show,
  projects,
  approver,
  customapprover,


}) => {
  const [reviewerLevels, setReviewerLevels] = useState<ReviewerLevel[]>([
    { level: 1, user_ids: [], approval_type: "Review" },
  ]);

  const [finalApprover, setFinalApprover] = useState<{
    user_ids: number[];
    approval_type: "Approval" | "Review";
  }>({ user_ids: [], approval_type: "Approval" });

  const projectapprover = useAppSelector(
    (state: RootState) => state.approver.projectapprovers
  );
  const project = useAppSelector((state) => state.project.projects) || [];
  const companyId = useAppSelector(
    (state: RootState) => state.auth.user?.company_id || 0
  );
  const projectId = project.length > 0 ? projects[0].id : "";

  const handleChange = (
    selectedOptions: MultiValue<{ value: number; label: string }>,
    level?: number
  ) => {
    if (level !== undefined) {
      console.log(
        `Selected user IDs for level ${level}:`,
        selectedOptions.map((option) => option.value)
      );
      setReviewerLevels((prevLevels) => {
        return prevLevels.map((reviewerLevel) =>
          reviewerLevel.level === level
            ? {
                ...reviewerLevel,
                user_ids: selectedOptions.map((option) => option.value),
              }
            : reviewerLevel
        );
      });
    } else {
      console.log(
        `Selected user IDs for final approver:`,
        selectedOptions.map((option) => option.value)
      );
      setFinalApprover({
        user_ids: selectedOptions.map((option) => option.value),
        approval_type: "Approval",
      });
    }
  };
  const addReviewerLevel = () => {
    if (reviewerLevels.length < 4) {
      const newLevel = reviewerLevels.length + 1;
      setReviewerLevels((prevLevels) => [
        ...prevLevels,
        { level: newLevel, user_ids: [], approval_type: "Review" },
      ]);
    }
  };

  const deleteReviewerLevel = (level: number) => {
    setReviewerLevels((prevLevels) => {
      return prevLevels
        .filter((reviewerLevel) => reviewerLevel.level !== level)
        .map((reviewerLevel, index) => ({
          ...reviewerLevel,
          level: index + 1,
        }));
    });
  };

  const getAvailableOptions = (level: number) => {
    const selectedUserIds = reviewerLevels
      .filter((reviewerLevel) => reviewerLevel.level < level)
      .flatMap((reviewerLevel) => reviewerLevel.user_ids);

    return customapprover
      .filter((reviewer) => !selectedUserIds.includes(reviewer.id))
      .map((reviewer) => ({
        value: reviewer.id,
        label: `${reviewer.first_name} ${reviewer.last_name}`,
      }));
  };

  const getSelectedOptions = (level: number) => {
    const selectedValues =
      reviewerLevels.find((reviewerLevel) => reviewerLevel.level === level)
        ?.user_ids || [];

    return selectedValues.map((id) => {
      const reviewer = customapprover.find((r) => r.id === id);
      return reviewer
        ? {
            value: reviewer.id,
            label: `${reviewer.first_name} ${reviewer.last_name}`,
          }
        : { value: 0, label: "" };
    });
  };

  const getFinalApproverOptions = () => {
    const selectedUserIds = reviewerLevels.flatMap(
      (reviewerLevel) => reviewerLevel.user_ids
    );

    return projectapprover
      .filter((approver) => !selectedUserIds.includes(approver.id))
      .map((approver) => ({
        value: approver.id,
        label: `${approver.first_name} ${approver.last_name}`,
      }));
  };

  const getFinalApproverSelectedOptions = () => {
    return finalApprover.user_ids.map((id) => {
      const approver = projectapprover.find((r) => r.id === id);
      return approver
        ? {
            value: approver.id,
            label: `${approver.first_name} ${approver.last_name}`,
          }
        : { value: 0, label: "" };
    });
  };

  const handleSubmit = async () => {
    const updatedFinalApprover: Level = {
      level: reviewerLevels.length + 1,
      user_ids: finalApprover.user_ids,
      approval_type: finalApprover.approval_type as "Approval" | "Review",
    };

    const updatedLevels = [...reviewerLevels, updatedFinalApprover];

    const requestBody: CreateCustomApprover = {
      levels: updatedLevels,
      projectId: projectId,
      company_id: companyId,
    };

    console.log("Request Body:", requestBody);

    try {
      const response = await createCustomApprovaer(requestBody);
      console.log("API Response:", response);
      toast.success(" Custom Approval level created successfully");
    } catch (error: any) {
      console.error("Error creating approver:", error);
      let errorMessage = "Failed to create approver.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      } else if (error.message) {
        errorMessage = error.message;
      }

      toast.error(errorMessage);
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={closeModal}
      style={{ maxHeight: "100vh", overflowY: "auto" }}
    >
      <Modal.Header closeButton>
        <h4 className="text-color-2 px-3 py-2">Setup approval level</h4>
      </Modal.Header>
      <section className="mt-2 px-4 py-2">
        <Row className="w-100">
          <label htmlFor="project" className="mb-1">
            Select project
          </label>
          <select name="project" className={`fluent-input w-95`}>
            <option value="">Select project</option>
            {projects.map((project) => (
              <option key={project.id} value={project.id}>
                {project.project_name}
              </option>
            ))}
          </select>
        </Row>
      </section>

      <section className="mt-2 px-4 py-2">
        <h4>Levels</h4>
        <div className="overflow-scroll" style={{ maxHeight: "300px" }}>
          {reviewerLevels.map((reviewerLevel) => (
            <Row className="w-100 mb-3" key={reviewerLevel.level}>
              <label
                htmlFor={`reviewer-${reviewerLevel.level}`}
                className="mb-1 w-100"
              >
                Level {reviewerLevel.level}
              </label>
              <div className="w-90 d-inline-flex justify-content-between align-items-center">
                <Select
                  name={`reviewers-${reviewerLevel.level}`}
                  className="fluent-input w-95"
                  value={getSelectedOptions(reviewerLevel.level).filter(
                    (option) => option.value !== 0
                  )}
                  onChange={(selectedOptions) =>
                    handleChange(selectedOptions, reviewerLevel.level)
                  }
                  options={getAvailableOptions(reviewerLevel.level)}
                  placeholder="Select Reviewers"
                  isMulti
                />
                {reviewerLevel.level > 1 && (
                  <Button
                    className="bg-transparent border-0"
                    onClick={() => deleteReviewerLevel(reviewerLevel.level)}
                  >
                    <img src={Trash} alt="trash" />
                  </Button>
                )}
              </div>
            </Row>
          ))}
          <Row className="w-100">
            <label htmlFor="projApprover" className="mb-1 w-100">
              Final Approver
            </label>
            <div className="w-90">
              <Select
                name="finalApprover"
                className="fluent-input w-95"
                value={getFinalApproverSelectedOptions().filter(
                  (option) => option.value !== 0
                )}
                onChange={(selectedOptions) => handleChange(selectedOptions)}
                options={getFinalApproverOptions()}
                placeholder="Select Final Approver"
                isMulti
              />
            </div>
          </Row>
          {reviewerLevels.length < 4 && (
            <Row className="w-10">
              <Button
                className="d-inline-flex justify-content-between align-items-center bg-transparent border-0 text-color-1"
                onClick={addReviewerLevel}
              >
                <img src={AddCircle} alt="add circle" />
                <span>Add</span>
              </Button>
            </Row>
          )}
        </div>
      </section>

      <Modal.Footer>
        <Row>
          <Col md={6}>
            <div className="mt-1">
              <Button onClick={closeModal} className="cancel-btn">
                <span className="text-center">Cancel</span>
              </Button>
            </div>
          </Col>
          <Col md={6}>
            <div className="mt-1">
              <Button
                type="submit"
                className="border-0 create-btn bg-color-1"
                onClick={handleSubmit}
              >
                <span className="text-center">Create setup</span>
              </Button>
            </div>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default SetupApprovalLevel;
