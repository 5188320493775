import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface MultipleSelectProps {
    label: string;
    name: string;
    options: { value: string; label: string }[];
    value: string[];
    onChange: (value: string[]) => void;
}

function MultipleSelect({ label, name, options, value, onChange }: MultipleSelectProps) {
    const theme = useTheme();

    const handleChange = (event: SelectChangeEvent<typeof value>) => {
        const {
            target: { value: selectedValues },
        } = event;
        onChange(selectedValues as string[]);
    };

    return (
        <FormControl sx={{ width: '100%' }}>
            <InputLabel id={`${name}-label`}>{label}</InputLabel>
            <Select
                labelId={`${name}-label`}
                id={`${name}-select`}
                multiple
                value={value}
                onChange={handleChange}
                input={<OutlinedInput label={label} />}

            >
                {options.map((option) => (
                    <MenuItem
                        key={option.value}
                        value={option.value}
                        style={{
                            fontWeight:
                                value.indexOf(option.value) === -1
                                    ? theme.typography.fontWeightRegular
                                    : theme.typography.fontWeightMedium,
                        }}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default MultipleSelect;
