import React, { useEffect, useState } from "react";
import Layout from "../../../components/layouts/layout";

import { Button, Card, Image } from "react-bootstrap";
import Schedule from "../../../assets/images/schedule-icon.png";
import CreateTask from "./modals/create-schedule";
import { Task } from "../../../services/project-management/project-management-service";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import { getAllTasksAsync } from "../../../slices/project-management/project-management-slice";

function NoSchedule() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const companyId = localStorage.getItem("userCompanyID");
	const project_id = localStorage.getItem("projectId");

	const [createTaskOpen, setCreateTaskOpen] = useState<boolean>(false);
	const handleOpenCreateTask = () => setCreateTaskOpen(true);
	const handleCloseCreateTask = () => setCreateTaskOpen(false);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [refreshData, setRefreshData] = useState(false);

	const [taskTable, setTaskTable] = useState<Task[] | []>([]);

	const tasks = useAppSelector(
		(state: RootState) => state.projectManagement.tasks,
	);

	useEffect(() => {
		if (companyId && project_id) {
			dispatch(
				getAllTasksAsync({ company_id: companyId, projectId: project_id }),
			);
		}
	}, [companyId, project_id, dispatch]);

	useEffect(() => {
		if (tasks !== null && tasks !== undefined) {
			setTaskTable(tasks);
		}
	}, [tasks]);

	useEffect(() => {
		if (taskTable.length > 0) {
			navigate(`/project-management/${project_id}`);
		}
	}, [taskTable, navigate]);

	const handleCreateSuccess = () => {
		setRefreshData(true);
	};

	return (
		<div>
			<Layout>
				<div className="pt-5 p-3">
					<h3 className="mb-3">Project management</h3>
					<h5 className="text-color-2">Schedule</h5>
					<Card className=" auth-card-3 mt-4 pt-4">
						<div className="text-center ">
							<Image src={Schedule} width={152} height={160} className="my-3" />
							<h3 className="text-color-2">There is no schedule created yet</h3>
							<p className="text-color-2">
								Track and manage the progress of your construction projects
							</p>
							<Button
								onClick={handleOpenCreateTask}
								className=" my-3 py-3 px-5 border-0 bg-color-1 "
							>
								<span className="text-center">Create schedule</span>
							</Button>
						</div>
					</Card>
				</div>
				<CreateTask
					project_id={project_id}
					show={createTaskOpen}
					closeModal={handleCloseCreateTask}
					onCreateSuccess={handleCreateSuccess}
				/>
			</Layout>
		</div>
	);
}

export default NoSchedule;
