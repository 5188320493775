import React, { useState, useEffect, useRef } from "react";
import Layout from "../../../components/layouts/layout";
import { Nav, Table, Button, Row, Col, Dropdown } from "react-bootstrap";
import Action from "../../../assets/images/action-vector.png";

import CreateTask from "./modals/create-schedule";
import UpdateTask from "./modals/edit-schedule";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
	deleteTaskAsync,
	getAllTasksAsync,
} from "../../../slices/project-management/project-management-slice";

import "dhtmlx-gantt/codebase/dhtmlxgantt.css"; // Import default Gantt styles
import gantt from "dhtmlx-gantt";

import { RootState } from "../../../store/store";
import { Task } from "../../../services/project-management/project-management-service";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { format } from 'date-fns';

interface ScaleConfig {
	scale_unit: "day" | "week" | "month";
	date_scale: string;
	subscales?: Array<{
		unit: "day" | "week" | "month";
		step: number;
		date: Date | null;
	}>;
}

interface GanttTask {
	id: string;
	text: string;
	start_date: string;
	duration: number;
	color: string;
}

interface GanttChartData {
	data: GanttTask[]; // data type for gantt chart data
}

const dayScaleConfig: ScaleConfig = {
	scale_unit: "day",
	date_scale: "%d %M %Y",
};

const weekScaleConfig: ScaleConfig = {
	scale_unit: "week",
	date_scale: "Wk %W %Y",
};

const monthScaleConfig: ScaleConfig = {
	scale_unit: "month",
	date_scale: "%M %Y",
};

function ProjectManagement() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const tasks = useAppSelector(
		(state: RootState) => state.projectManagement.tasks,
	);
	const companyId = localStorage.getItem("userCompanyID");

	// const location = useLocation();
	// const project_id = location.pathname.split("/").pop();

	// const { projectId } = useParams<{ projectId: string }>();
	const project_id = localStorage.getItem("projectId");

	const [activeTab, setActiveTab] = useState<string>("scheduling");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

	const [taskTable, setTaskTable] = useState<Task[] | null>(null);

	const [createTaskOpen, setCreateTaskOpen] = useState<boolean>(false);
	const handleOpenCreateTask = () => setCreateTaskOpen(true);
	const handleCloseCreateTask = () => setCreateTaskOpen(false);

	const [editTaskOpen, setEditTaskOpen] = useState<boolean>(false);

	const [selectedTaskId, setSelectedTaskId] = useState<string | null>(null);
	const handleOpenEditTask = (taskId: string) => {
		setEditTaskOpen(true);
		setSelectedTaskId(taskId);
	};
	const handleCloseEditTask = () => {
		setSelectedTaskId(null);
		setEditTaskOpen(false);
	};

	const [taskDropdownOpen, setTaskDropdownOpen] = useState<{
		[key: number]: boolean;
	}>({});

	const toggleTaskDropdown = (index: number) => {
		setTaskDropdownOpen((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};

	useEffect(() => {
		if (companyId && project_id) {
			dispatch(
				getAllTasksAsync({ company_id: companyId, projectId: project_id }),
			);
		}
	}, [companyId, project_id, dispatch]);

	useEffect(() => {
		if (tasks !== null && tasks !== undefined) {
			setTaskTable(tasks);
		}
	}, [tasks]);

	useEffect(() => {
		if (taskTable !== null && taskTable !== undefined) {
			if (taskTable.length < 1) {
				navigate("/no-schedule/:projectId");
			}
		}
	}, [taskTable, navigate]);

	const [refreshData, setRefreshData] = useState(false);

	const handleCreateSuccess = () => {
		setRefreshData(true);
		navigate(`/project-management/${project_id}`);
	};
	const handleEditSuccess = () => {
		setRefreshData(true);
	};
	const handleDeleteTask = (id: string) => {
		if (companyId && project_id) {
			dispatch(
				deleteTaskAsync({
					id: id,
					projectId: project_id,
					company_id: companyId,
				}),
			)
				.then((response) => {
					toast.success("Task deleted successfully");
					setRefreshData(true);
				})
				.catch((error) => {
					const errorMessage = Object.values(error.message).flat().join(" ");
					toast.error(errorMessage);
				});
		}
	};

	useEffect(() => {
		if (refreshData && companyId && project_id) {
			dispatch(
				getAllTasksAsync({ company_id: companyId, projectId: project_id }),
			);
			setRefreshData(false);
		}
	}, [companyId, dispatch, project_id, refreshData]);

	useEffect(() => {
		setActiveTab("scheduling");
	}, []);

	const tabOrder: string[] = ["scheduling", "gantt-chart"];

	const handleTabSelect = (key: string | null) => {
		if (key) {
			setActiveTabIndex(tabOrder.indexOf(key));
			setActiveTab(key);
		}
	};

	const getStatusColor = (status: any) => {
		switch (status.toLowerCase()) {
			case "done":
				return "green";
			case "to do":
				return "blue";
			default:
				return "goldenrod";
		}
	};

	const formatDateToChartFormat = (dateStr: any) => {
		const date = new Date(dateStr);
		const day = String(date.getDate()).padStart(2, "0");
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const year = date.getFullYear();
		return `${day}-${month}-${year}`;
	};

	const data: GanttTask[] =
		tasks?.map((task) => {
			const startDate = new Date(task.start_date);
			const endDate = new Date(task.end_date);

			if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
				throw new Error("Invalid date");
			}

			// Calculate duration in days
			const duration = Math.ceil(
				(endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24),
			);

			return {
				id: task.id,
				text: task.task_name,
				start_date: formatDateToChartFormat(task.start_date), // Formatting the start_date to match the gantt chart format
				duration: duration,
				color: task.task_color,
			};
		}) ?? [];

	// Creating the ganttChartData object
	// Will update using useMemo() to fix the warning
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const ganttChartData: GanttChartData = {
		data,
	};

	const ganttContainer = useRef<HTMLDivElement>(null);
	const [viewConfig, setViewConfig] = useState<ScaleConfig>(monthScaleConfig);
	const [ganttWidths, setGanttWidths] = useState({
		gridWidth: 200,
		scaleWidth: 800,
	});

	useEffect(() => {
		const handleResize = () => {
			const totalWidth = window.innerWidth;
			setGanttWidths({
				gridWidth: totalWidth * 0.1, // 10% of total width
				scaleWidth: totalWidth * 0.9, // 90% of total width
			});
		};

		// Set initial sizes and update on window resize
		handleResize();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		if (ganttContainer.current) {
			gantt.init(ganttContainer.current);
			gantt.parse(ganttChartData);
		}
		return () => gantt.clearAll();
	}, [ganttChartData]);

	useEffect(() => {
		gantt.config.scale_unit = viewConfig.scale_unit;
		gantt.config.date_scale = viewConfig.date_scale;
		gantt.config.subscales = viewConfig.subscales || [];
		gantt.config.columns = [
			{
				name: "text",
				label: "Task name",
				tree: false,
				width: "*",
			},
		];
		gantt.render();
	}, [viewConfig]);

	useEffect(() => {
		gantt.config.grid_width = ganttWidths.gridWidth;
		gantt.config.scale_width = ganttWidths.scaleWidth; // Not a standard configuration in dhtmlx-gantt
		gantt.render();
	}, [ganttWidths]);

	useEffect(() => {
		gantt.config.columns = [
			{ name: "text", label: "Task name", tree: false, width: "*" },
		];
		gantt.config.buttons_left = [];
		gantt.render();
	}, []);

	return (
		<div>
			<Layout>
				<div className="pt-5 p-3">
					<h3>Project management</h3>
					<Nav
						variant="underline"
						activeKey={activeTab}
						onSelect={handleTabSelect}
						className="project-management-nav px-2 py-1"
					>
						<Nav.Item>
							<Nav.Link
								className="py-0 px-2 rounded fw-light mr-1"
								eventKey="scheduling"
								style={{
									border: "none",
									color: activeTab === "scheduling" ? "white" : "darkgray",
									textDecoration: "none",
									background:
										activeTab === "scheduling" ? "#0F6CBD " : "transparent",
								}}
							>
								Scheduling
							</Nav.Link>
						</Nav.Item>
						<Nav.Item className="">
							<Nav.Link
								className="py-0 px-2 rounded fw-light mr-1"
								eventKey="gantt-chart"
								style={{
									border: "none",
									color: activeTab === "gantt-chart" ? "white" : "darkgray",
									textDecoration: "none",
									background:
										activeTab === "gantt-chart" ? "#0F6CBD " : "transparent",
								}}
							>
								Gantt chart
							</Nav.Link>
						</Nav.Item>
					</Nav>
					<div>
						{activeTab === "scheduling" && (
							<div className="p-2 rounded align-self-center my-3">
								<Row className="my-2">
									<Col md={12}>
										<Button
											onClick={handleOpenCreateTask}
											className="py-3 px-5 border-0 bg-color-1 float-end"
										>
											<span className="text-center">Add schedule</span>
										</Button>
									</Col>
								</Row>
								<Table borderless responsive className="mt-3">
									<thead>
										<tr>
											<th className="text-color-1 bg-color-3">S/N</th>
											<th className="text-color-1 bg-color-3">Task name </th>
											<th className="text-color-1 bg-color-3">Assigned to </th>
											<th className="text-color-1 bg-color-3">
												Planned start date{" "}
											</th>
											<th className="text-color-1 bg-color-3">
												Planned end date{" "}
											</th>

											<th className="text-color-1 bg-color-3">Status</th>
											<th className="text-color-1 bg-color-3"></th>
										</tr>
									</thead>
									<tbody>
										{taskTable?.map((task, index) => (
											<tr key={task.id}>
												<td style={{ verticalAlign: "middle" }}>
													{taskTable.indexOf(task) + 1}
												</td>
												<td style={{ verticalAlign: "middle" }}>
													{task.task_name}
												</td>
												<td style={{ verticalAlign: "middle" }}>
													{task.assigned_to}
												</td>
												<td style={{ verticalAlign: "middle" }}>
													{format(new Date(task.start_date), "dd/MM/yy")}
												</td>
												<td style={{ verticalAlign: "middle" }}>
													{format(new Date(task.end_date), "dd/MM/yy")}
												</td>
												<td
													style={{
														color: getStatusColor(task.task_status),
														verticalAlign: "middle",
													}}
												>
													{task.task_status}
												</td>

												<td style={{ verticalAlign: "middle" }}>
													<Dropdown
														show={taskDropdownOpen[index]}
														onToggle={() => toggleTaskDropdown(index)}
													>
														<Dropdown.Toggle
															className="dropdown-toggle"
															variant="transparent"
															style={{
																background: "none",
																border: "none",
																boxShadow: "none",
															}}
														>
															<img
																src={Action}
																alt="Action"
																className="action-icon"
																style={{ margin: "0", padding: "0" }}
															/>
														</Dropdown.Toggle>
														<Dropdown.Menu>
															<Dropdown.Item
																onClick={() => handleOpenEditTask(task.id)}
															>
																Edit
															</Dropdown.Item>
															<Dropdown.Item
																onClick={() => handleDeleteTask(task.id)}
															>
																Delete
															</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</td>
											</tr>
										))}
									</tbody>
								</Table>
							</div>
						)}
					</div>
					<div>
						{activeTab === "gantt-chart" && (
							<div className="w-100 d-flex flex-column justify-content-center align-content-center ">
								<div className="my-3 align-self-end d-inline-flex w-25">
									<p className="py-2 px-1 mx-2">Change View: </p>
									<Dropdown>
										<Dropdown.Toggle variant="primary" id="dropdown-basic">
											{viewConfig.scale_unit === "day"
												? "Day View"
												: viewConfig.scale_unit === "week"
													? "Week View"
													: "Month View"}
										</Dropdown.Toggle>

										<Dropdown.Menu>
											<Dropdown.Item
												onClick={() => setViewConfig(dayScaleConfig)}
											>
												Day View
											</Dropdown.Item>
											<Dropdown.Item
												onClick={() => setViewConfig(weekScaleConfig)}
											>
												Week View
											</Dropdown.Item>
											<Dropdown.Item
												onClick={() => setViewConfig(monthScaleConfig)}
											>
												Month View
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</div>

								<div
									className="gantt-chart-container"
									style={{ width: "100%", height: "300px", overflow: "auto" }}
									ref={ganttContainer}
								></div>
							</div>
						)}
					</div>
				</div>
				<CreateTask
					closeModal={handleCloseCreateTask}
					onCreateSuccess={handleCreateSuccess}
					project_id={project_id}
					show={createTaskOpen}
				/>
				<UpdateTask
					closeModal={handleCloseEditTask}
					id={selectedTaskId!}
					onEditSuccess={handleEditSuccess}
					project_id={project_id}
					show={editTaskOpen}
				/>
			</Layout>
		</div>
	);
}

export default ProjectManagement;
