import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Container, Table, Button } from 'react-bootstrap';
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import {
    getAMaterialRequestAsync, setMaterialRequestId
} from '../../../../slices/requests/requestsSlice';
import ConfirmPayMaterialRequest from "./confirm-pay-material-request";
import { RootState } from "../../../../store/store";
import { getUserAsync } from "../../../../slices/auth/authSlice";


interface PayMaterialRequestProps {
    closeModal: () => void;
    show: boolean;
    materialRequestId: string;
    projectId: string;
}

const PayMaterialRequest: React.FC<PayMaterialRequestProps> = ({ closeModal, show, materialRequestId, projectId }) => {
    const formatCurrency = (amount: number): string => {
        return `₦${amount.toLocaleString('en-US')}`;
    };
    const dispatch = useAppDispatch();
    const companyId = useAppSelector(
        (state: RootState) => state.auth.user?.company_id || "",
    );
    const materialRequestData = useAppSelector((state: any) => state.requests.materialRequestData);
    const [loading, setLoading] = useState(true);
    const [selectedMaterialRequestId, setSelectedMaterialRequestId] = useState<string | null>(null);

    const [confirmPayMaterialRequestModalOpen, setConfirmPayMaterialRequestModalOpen] = useState<boolean>(false);
    const handleOpenConfirmPayMaterialRequestModal = (materialRequestId: string) => {
        setSelectedMaterialRequestId(materialRequestId);
        setConfirmPayMaterialRequestModalOpen(true);
        closeModal();
    };
    const handleCloseConfirmPayMaterialRequestModal = () => setConfirmPayMaterialRequestModalOpen(false);
    useEffect(() => {
        dispatch(getUserAsync());
    }, [dispatch]);

    useEffect(() => {
        if (materialRequestId) {
            dispatch(setMaterialRequestId(materialRequestId));
            console.log("Fetching material request with ID:", materialRequestId);
            dispatch(getAMaterialRequestAsync({ companyId, id: materialRequestId, projectId })).then(() =>
                setLoading(false),
            );
        } else {
            console.log("materialRequestId is not set");
        }
    }, [dispatch, companyId, materialRequestId, projectId]);

    if (loading) {
        return null;
    }
    console.log("materialRequestData", materialRequestData);

    return (
        <Container>
            <Modal size="lg" show={show} onHide={closeModal} style={{ maxHeight: "100vh", overflowY: "auto" }}>
                <div className='p-3 '>
                    <Modal.Header className='text-start'>
                        <h4>Pay material request</h4>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className='mt-3 pb-3'>
                            <Col md={8} className="col-separator">
                                <Table className="rectangle" borderless responsive>
                                    <thead>
                                        <tr>
                                            <td className='text-color-7'>S/N</td>
                                            <td className='text-color-7'>Material </td>
                                            <td className='text-color-7'>Quantity </td>
                                            <td className='text-color-7'>Cost </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {materialRequestData.material_requests.materials.map((material: any, index: any) => (
                                            <tr key={material.id}>
                                                <td className='text-color-7'>{index + 1}</td>
                                                <td className='text-color-7'>{material.material_name}</td>
                                                <td className='text-color-7'>{material.quantity}</td>
                                                <td className='text-color-7'>{formatCurrency(parseFloat(material.unit_cost))}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                            <Col md={4}>
                                <Row className="mt-1">
                                    <small>Request initiated by Mahirson Corp </small>
                                    <small>22/04/24 4:38 PM</small>
                                </Row>
                                <Row className="mt-1">
                                    <small>Request review pending  </small>
                                    <small>22/04/24 4:38 PM</small>
                                </Row>
                                <Row className="mt-1">
                                    <small>Request approval pending  </small>
                                    <small>22/04/24 4:38 PM</small>
                                </Row>
                            </Col>
                        </Row>
                        <Modal.Footer className='mt-5'>
                            <Row >
                                <Col md={6}>
                                    <div className=' mt-1'>
                                        <Button onClick={closeModal} className='cancel-btn'>
                                            <span className="text-center">Cancel </span>                                        </Button>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className=' mt-1'>
                                        <Button type='submit' className=' border-0 create-btn bg-color-1' onClick={() => handleOpenConfirmPayMaterialRequestModal(materialRequestId)}>
                                            <span className="text-center"> Pay</span>                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Modal.Footer>
                    </Modal.Body>
                </div>
            </Modal>
            <ConfirmPayMaterialRequest show={confirmPayMaterialRequestModalOpen} closeModal={handleCloseConfirmPayMaterialRequestModal} projectId={projectId!} materialRequestId={selectedMaterialRequestId!} />
        </Container>
    );
}

export default PayMaterialRequest;
