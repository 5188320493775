import React from 'react';
import Logo from "../../../assets/images/logo.png";
import { Image, Container, Navbar, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function Navigation(): JSX.Element {
    return (
        <Navbar>
            <Container>
                <Navbar.Brand href="/">
                    <Image src={Logo} width={145} height={47} />
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text>
                        <Button variant='transparent' className=''>
                            Pricing
                        </Button>
                        <Button variant='transparent' className='mx-3'>
                            <Link to="/sign-in" className='link-styles text-black'> Sign in</Link>
                        </Button>
                        <Button className='bg-color-1 px-4 py-2 border-0 text-white'>
                            <Link to="/sign-up" className='link-styles text-white'>  Get started</Link>
                        </Button>
                    </Navbar.Text>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation;
