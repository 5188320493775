import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  Approver,
  CreateApprovalLevelData,
  CreateApprovalLevelResponse,
  createApprovalLevel,
  getAllCompanyApprovers,
  createCustomApprovaer,
  getAllProjectReviewers,
  CreateCustomApprover,
  CustomApproverResponse,
  CustomApprovalLevelData,
  getAllProjectsApprover,
} from "../../services/approval/approval";

export interface ApprovalState {
  createApprovalLevelResponse?: CreateApprovalLevelResponse | null;
  CustomApproverResponse?: CustomApproverResponse | null;
  approvers: Approver[];
  customapprovers: Approver[];
  projectapprovers: Approver[];
  loading: boolean;
  error: string | null;
}

const initialState: ApprovalState = {
  createApprovalLevelResponse: null,
  CustomApproverResponse: null,
  approvers: [],
  customapprovers: [],
  projectapprovers: [],
  loading: false,
  error: null,
};

export const createCustomApproverAsync = createAsyncThunk<CustomApproverResponse, CreateCustomApprover, {
  rejectValue: { error: boolean; message: string }}>(
    "approver/createCustomApprover",
    async (data: CreateCustomApprover
    , { rejectWithValue }) => {
      try {
        const response = await createCustomApprovaer(data);
        return response;
      } catch (error: any) {
        if (error.response && error.response.data) {
          return rejectWithValue(error.response.data);
        }
        return rejectWithValue({
          error: true,
          message: "An unexpected error occurred",
        });
      }
    }
  );


export const createApprovalLevelAsync = createAsyncThunk<
  CreateApprovalLevelResponse,
  CreateApprovalLevelData,
  { rejectValue: { error: boolean; message: string } }
>(
  "approver/createApprovalLevel",
  async (data: CreateApprovalLevelData, { rejectWithValue }) => {
    try {
      const response = await createApprovalLevel(data);
      return response;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({
        error: true,
        message: "An unexpected error occurred",
      });
    }
  }
);

export const fetchCostomReviewersAsync = createAsyncThunk<
  Approver[],
  { projectId: string; companyId: number },
  { rejectValue: { error: boolean; message: string } }
>(
  "approver/getAllProjectReviewers",
  async ({ projectId, companyId }, { rejectWithValue }) => {
    try {
      const response = await getAllProjectReviewers(projectId, companyId);
      return response;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({
        error: true,
        message: "An unexpected error occurred",
      });
    }
  }
);

export const fetchProjectApproversAsync = createAsyncThunk<
  Approver[],
  { projectId: string; companyId: number },
  { rejectValue: { error: boolean; message: string } }
>(
  "approver/getAllProjectsApprover",
  async ({ projectId, companyId }, { rejectWithValue }) => {
    try {
      const response = await getAllProjectsApprover(projectId, companyId);
      return response;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({
        error: true,
        message: "An unexpected error occurred",
      });
    }
  }
);

export const fetchCompanyApproversAsync = createAsyncThunk<
  Approver[],
  string,
  { rejectValue: { error: boolean; message: string } }
>("approver/fetchCompanyApprovers", async (id: string, { rejectWithValue }) => {
  try {
    const response = await getAllCompanyApprovers(id);
    return response;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue({
      error: true,
      message: "An unexpected error occurred",
    });
  }
});

const approvalSlice = createSlice({
  name: "approver",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createApprovalLevelAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createApprovalLevelAsync.fulfilled, (state, action) => {
        state.createApprovalLevelResponse = action.payload;
        state.loading = false;
      })
      .addCase(createApprovalLevelAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload
          ? action.payload.message
          : "Failed to create approval level";
      })
      .addCase(fetchCompanyApproversAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCompanyApproversAsync.fulfilled, (state, action) => {
        state.approvers = action.payload;
        state.loading = false;
      })
      .addCase(fetchCompanyApproversAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload
          ? action.payload.message
          : "Failed to fetch approvers";
      })
      .addCase(createCustomApproverAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCustomApproverAsync.fulfilled, (state, action) => {
        state.CustomApproverResponse = action.payload;
        state.loading = false;
      })
      .addCase(createCustomApproverAsync.rejected, (state, action) =>{
        state.loading = false;
        state.error = action.payload
          ? action.payload.message
          : "Failed to create custom approver";
      })
      .addCase(fetchCostomReviewersAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCostomReviewersAsync.fulfilled, (state, action) => {
        state.customapprovers = action.payload;
        state.loading = false;
      })
      .addCase(fetchCostomReviewersAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload
          ? action.payload.message
          : "Failed to fetch approvers";
      })
      .addCase(fetchProjectApproversAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProjectApproversAsync.fulfilled, (state, action) => {
        state.projectapprovers = action.payload;
        state.loading = false;
      })
      .addCase(fetchProjectApproversAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload
          ? action.payload.message
          : "Failed to fetch project approvers";
      })
      
  },
});

export default approvalSlice.reducer;
