import React from 'react';
import ResetIcon from '../../../assets/images/reset-password-icon.png';
import { Container, Button, Image, Card } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { forgotPasswordAsync } from '../../../slices/auth/authSlice';
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { toast } from "react-toastify";

interface ForgotPasswordFormValues {
    email: string;
}

const ForgotPassword: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const forgotPasswordResponse = useAppSelector(
        (state: any) => state.auth.forgotPassword
    );

    console.log('Component Rendered, Forgot Password Response:', forgotPasswordResponse);

    React.useEffect(() => {
        if (forgotPasswordResponse && !forgotPasswordResponse.error) {
            console.log('Navigating to password-verification');
            navigate('/password-verification');
        }
    }, [forgotPasswordResponse, navigate]);

    const initialValues: ForgotPasswordFormValues = {
        email: '',
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
    });

    return (
        <Container fluid>
            <div className='bg-color-2'>
                <Card className='auth-card-3 p-5'>
                    <div className='text-center'>
                        <Image src={ResetIcon} className='mt-5' />
                        <h1 className='my-3'>Reset password</h1>
                        <p>
                            Enter your email address and we'll send you a <br />
                            code to reset your password.
                        </p>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values: ForgotPasswordFormValues, { setSubmitting }) => {
                                console.log("Submitting email:", values.email);
                                dispatch(forgotPasswordAsync(values))
                                    .unwrap()
                                    .then((response) => {
                                        console.log('Success:', response);
                                        toast.success(response.message || "Password reset link sent successfully");
                                    })
                                    .catch((error) => {
                                        console.error('Error:', error);
                                        const errorMessage = Object.values(error.message).flat().join(" ");
                                        toast.error(errorMessage);
                                    })
                                    .finally(() => {
                                        setSubmitting(false);
                                    });
                            }}

                        >
                            {({ errors, touched, handleSubmit }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div className='text-center mt-2'>
                                        <div className='text-start'>
                                            <label>
                                                <p className=''>Email address</p>
                                            </label>
                                        </div>
                                        <Field
                                            type='email'
                                            name='email'
                                            placeholder='Please enter your email'
                                            className={`fluent-input ${touched.email && errors.email ? 'is-invalid' : ''
                                                }`}
                                            required
                                        />
                                    </div>
                                    <div className='d-grid'>
                                        <Button
                                            type='submit'
                                            className='my-4 py-3 border-0 bg-color-1'
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                    <div className=' '>
                                        <Link to='/sign-in' className='link-styles mt-5'>
                                            Back to <span className='text-color-1'> Sign in </span>
                                        </Link>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Card>
            </div>

        </Container>
    );
};

export default ForgotPassword;
