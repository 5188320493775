import axios from 'axios';

axios.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export interface CompanyProfile {
    id: string;
    company_name: string;
    company_email: string;
    company_size: string;
    company_phone_number: string;
    company_address: string;
    company_state: string;
    company_country: string;
}
export interface UpdateCompanyProfileData {
    id: string;
    company_id: string;
    company_name: string;
    company_email: string;
    company_phone_number: string;
}
export interface UpdateCompanyProfileResponse {
    data: UpdateCompanyProfileData;
    message: string;
    error: boolean;
}

export interface GetCompanyProfileResponse {
    data: CompanyProfile;
    message: string;
}

export interface UpdateProfileData {
    id: string;
    company_id: string;
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
}
export interface UpdateProfileResponse {
    data: UpdateProfileData;
    message: string;
    error: boolean;
}
export interface Material {
    company_id: string;
    id: string;
    material_name: string;
    material_unit: string;
    material_threshold: string;
    material_status: string;
}
export interface CreateMaterialData {
    company_id: string;
    material_name: string;
    material_unit: string;
    material_threshold: string;
}
export interface CreateMaterialResponse {
    data: Material;
    message: string;
    error: boolean;
}
export interface UpdateMaterialData {
    company_id: string;
    id: string;
    material_unit: string;
    material_threshold: string;
}
export interface UpdateMaterialResponse {
    data: Material;
    message: string;
    error: boolean;
}
export interface GetAllMaterialsResponse {
    data: Material[];
    message: string;
}
export interface GetAMaterialResponse {
    data: Material;
    message: string;
}
export interface Labour {
    company_id: string;
    labour_type: string;
    id: string;
    labour_description: string;
}
export interface CreateLabourData {
    company_id: string;
    labour_type: string;
    labour_description: string;
}
export interface CreateLabourResponse {
    data: Labour;
    message: string;
    error: boolean;
}
export interface UpdateLabourData {
    company_id: string;
    labour_type: string;
    id: string;
    labour_description: string;
}
export interface UpdateLabourResponse {
    data: Labour;
    message: string;
    error: boolean;
}
export interface GetAllLabourResponse {
    data: Labour[];
    message: string;
}
export interface GetALabourResponse {
    data: Labour;
    message: string;
}
export interface Role {
    company_id: string;
    id: string;
    role_name: string;
    permissions: string[];
}
export interface CreateRoleData {
    company_id: string;
    role_name: string;
    permissions: string[];
}
export interface CreateRoleResponse {
    data: Role;
    message: string;
    error: boolean;
}
export interface UpdateRoleData {
    company_id: string;
    id: string;
    role_name: string;
    permissions: string[];
}
export interface UpdateRoleResponse {
    data: Role;
    message: string;
    error: boolean;
}
export interface GetAllRolesResponse {
    data: Role[];
    message: string;
}
export interface GetARoleResponse {
    data: Role;
    message: string;
}

export interface Vendor {
    company_id: string;
    id: string;
    vendor_name: string;
    vendor_email: string;
    vendor_phone_number: string;
    vendor_bank_id: string;
    vendor_bank_name: string;
    vendor_bank_account: string;
}
export interface CreateVendorData {
    company_id: string;
    vendor_name: string;
    vendor_email: string;
    vendor_phone_number: string;
    vendor_bank_id: string;
    vendor_bank_account: string;
}
export interface CreateVendorResponse {
    data: Vendor;
    message: string;
    error: boolean;
}
export interface UpdateVendorData {
    company_id: string;
    id: string;
    vendor_name: string;
    vendor_email: string;
    vendor_phone_number: string;
    vendor_bank_id: string;
    vendor_bank_account: string;
}
export interface UpdateVendorResponse {
    data: Vendor;
    message: string;
    error: boolean;
}
export interface GetAllVendorsResponse {
    data: Vendor[];
    message: string;
}
export interface GetAVendorResponse {
    data: Vendor;
    message: string;
}
export interface Stage {
    company_id: string;
    stage: string;
    id: string;
}
export interface CreateStageData {
    company_id: string;
    stage: string;
}
export interface CreateStageResponse {
    data: Stage;
    message: string;
    error: boolean;
}
export interface UpdateStageData {
    company_id: string;
    stage: string;
    id: string;
}
export interface UpdateStageResponse {
    data: Stage;
    message: string;
    error: boolean;
}
export interface GetAllStagesResponse {
    data: Stage[];
    message: string;
}
export interface GetAStageResponse {
    data: Stage;
    message: string;
}
export const getAllBanks = async () => {
    const response = await axios.get('https://syteboxx-api.remsys.com.ng/api/v1/banks');
    return response.data;
};
export const getAllPermissions = async () => {
    const response = await axios.get('https://syteboxx-api.remsys.com.ng/api/v1/permissions');
    return response.data;
};

export const getCompanyProfile = async (companyId: string): Promise<GetCompanyProfileResponse> => {
    const response = await axios.get<GetCompanyProfileResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/config/company/${companyId}`);
    return response.data;
};

export const updateProfile = async (values: UpdateProfileData): Promise<UpdateProfileResponse> => {
    const response = await axios.put<UpdateProfileResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/users/update-admin/${values.id}`, values);
    return response.data;
}
export const updateCompanyProfile = async (values: UpdateCompanyProfileData): Promise<UpdateCompanyProfileResponse> => {
    const response = await axios.put<UpdateCompanyProfileResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/config/company/update-company/${values.company_id}`, values);
    return response.data;
}

export const createMaterial = async (values: CreateMaterialData): Promise<CreateMaterialResponse> => {
    const response = await axios.post<CreateMaterialResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/config/materials/create-material?company_id=${values.company_id}`, values);
    return response.data;
}
export const updateMaterial = async (values: UpdateMaterialData, id: string): Promise<UpdateMaterialResponse> => {
    const response = await axios.put<UpdateMaterialResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/config/materials/update-material/${id}?company_id=${values.company_id}`, values);
    return response.data;
}
export const getAllMaterials = async (companyId: string): Promise<Material[]> => {
    const response = await axios.get<GetAllMaterialsResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/config/materials/?company_id=${companyId}`);
    return response.data.data;
}
export const getAMaterial = async (companyId: string, id: string): Promise<Material> => {
    const response = await axios.get<GetAMaterialResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/config/materials/${id}/?company_id=${companyId}`);
    return response.data.data;
}
export const createLabour = async (values: CreateLabourData): Promise<CreateLabourResponse> => {
    const response = await axios.post<CreateLabourResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/config/labour-types/create-labour-type?company_id=${values.company_id}`, values);
    return response.data;
}
export const updateLabour = async (values: UpdateLabourData, id: string): Promise<UpdateLabourResponse> => {
    const response = await axios.put<UpdateLabourResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/config/labour-types/update-labour-type/${id}?company_id=${values.company_id}`, values);
    return response.data;
}
export const getAllLabour = async (companyId: string): Promise<Labour[]> => {
    const response = await axios.get<GetAllLabourResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/config/labour-types/?company_id=${companyId}`);
    return response.data.data;
}
export const getALabour = async (companyId: string, id: string): Promise<Labour> => {
    const response = await axios.get<GetALabourResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/config/labour-types/${id}/?company_id=${companyId}`);
    return response.data.data;
}
export const createRole = async (values: CreateRoleData): Promise<CreateRoleResponse> => {
    const response = await axios.post<CreateRoleResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/config/roles/create-role?company_id=${values.company_id}`, values);
    return response.data;
}
export const updateRole = async (values: UpdateRoleData, id: string): Promise<UpdateRoleResponse> => {
    const response = await axios.put<UpdateRoleResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/config/roles/update-role/${id}?company_id=${values.company_id}`, values);
    return response.data;
}
export const getAllRoles = async (companyId: string): Promise<Role[]> => {
    const response = await axios.get<GetAllRolesResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/config/roles/?company_id=${companyId}`);
    return response.data.data;
}
export const getARole = async (companyId: string, id: string): Promise<Role> => {
    const response = await axios.get<GetARoleResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/config/roles/${id}/?company_id=${companyId}`);
    return response.data.data;
}
export const createVendor = async (values: CreateVendorData): Promise<CreateVendorResponse> => {
    const response = await axios.post<CreateVendorResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/config/vendors/create-vendor?company_id=${values.company_id}`, values);
    return response.data;
}
export const updateVendor = async (values: UpdateVendorData, id: string): Promise<UpdateVendorResponse> => {
    const response = await axios.put<UpdateVendorResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/config/vendors/update-vendor/${id}?company_id=${values.company_id}`, values);
    return response.data;
}
export const getAllVendors = async (companyId: string): Promise<Vendor[]> => {
    const response = await axios.get<GetAllVendorsResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/config/vendors/?company_id=${companyId}`);
    return response.data.data;
}
export const getAVendor = async (companyId: string, id: string): Promise<Vendor> => {
    const response = await axios.get<GetAVendorResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/config/vendors/${id}/?company_id=${companyId}`);
    return response.data.data;
}
export const createStage = async (values: CreateStageData): Promise<CreateStageResponse> => {
    const response = await axios.post<CreateStageResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/config/stages/create-stage?company_id=${values.company_id}`, values);
    return response.data;
}
export const updateStage = async (values: UpdateStageData, id: string): Promise<UpdateStageResponse> => {
    const response = await axios.put<UpdateStageResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/config/stages/update-stage/${id}?company_id=${values.company_id}`, values);
    return response.data;
}
export const getAllStages = async (companyId: string): Promise<Stage[]> => {
    const response = await axios.get<GetAllStagesResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/config/stages/?company_id=${companyId}`);
    return response.data.data;
}
export const getAStage = async (companyId: string, id: string): Promise<Stage> => {
    const response = await axios.get<GetAStageResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/config/stages/${id}/?company_id=${companyId}`);
    return response.data.data;
}