import React, { useEffect } from "react";
import { Modal, Button, Row, Col, Container, Form } from "react-bootstrap";
import { Formik, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import {
	createRoleAsync,
	getAllPermissionsAsync,
	getAllRolesAsync,
} from "../../../../slices/settings/settingsSlice";
import { getUserAsync } from "../../../../slices/auth/authSlice";
import { RootState } from "../../../../store/store";
import { toast } from "react-toastify";

interface CreateRoleValues {
	role_name: string;
	company_id: string;
	permissions: string[];
}

interface CreateRoleProps {
	closeModal: () => void;
	show: boolean;
	onCreateSuccess: () => void;
}

const CreateRole: React.FC<CreateRoleProps> = ({ closeModal, show, onCreateSuccess }) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(getUserAsync());
	}, [dispatch]);

	const companyId = useAppSelector(
		(state: RootState) => state.auth.user?.company_id || "",
	);
	const permissions =
		useAppSelector((state: RootState) => state.settings.permissions) || [];

	useEffect(() => {
		dispatch(getAllPermissionsAsync());
	}, [dispatch]);

	const getPermissionsByCategory = (category: string) => {
		return permissions.filter((permission) =>
			permission.permission_name.includes(category),
		);
	};
	console.log("Permissions:", permissions);
	return (
		<Container fluid className="">
			<Modal
				size="lg"
				show={show}
				onHide={closeModal}
				style={{ maxHeight: "100vh", overflowY: "auto" }}
			>
				<div className="px-5">
					<Formik
						initialValues={{
							role_name: "",
							company_id: companyId || "",
							permissions: [],
						}}
						validationSchema={Yup.object().shape({
							role_name: Yup.string().required("Required"),
							company_id: Yup.string().required("Required"),
							permissions: Yup.array().of(Yup.string()).required("Required"),
						})}
						onSubmit={(values: CreateRoleValues, { setSubmitting }) => {
							console.log("Submitting values:", values);
							dispatch(
								createRoleAsync({
									...values,
									company_id: companyId || "",
									permissions: values.permissions.map(String),
								}),
							).unwrap()
								.then((response) => {
									toast.success("Role created successfully");
									dispatch(getAllRolesAsync(companyId));
									onCreateSuccess();
									closeModal();
								})
								.catch((error) => {
									const errorMessage = Object.values(error.message).flat().join(" ");
									toast.error(errorMessage);
								})
								.finally(() => {
									setSubmitting(false);
								});
						}}
					>
						{({
							errors,
							touched,
							handleSubmit,
							isSubmitting,
							values,
							handleChange,
						}) => (
							<Form onSubmit={handleSubmit}>
								<Modal.Header className="text-start mt-2">
									<h5>Create a role</h5>
								</Modal.Header>
								<Modal.Body>
									<Row>
										<Col md={6} className="mt-2">
											<label className="mt-2">
												<p>
													Role <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="role_name"
												placeholder="Enter role name"
												className={`fluent-input ${touched.role_name && errors.role_name
													? "is-invalid"
													: ""
													}`}
												required
											/>
										</Col>
									</Row>
									<Row>
										<Col md={12} className="mt-3">
											<p className="mt-2 fw-bold">Permissions</p>
											<FieldArray
												name="permissions"
												render={(arrayHelpers) => (
													<div>
														{/* Request Permissions */}
														<Row>
															<Col md={2} className="mt-2">
																<p className="fw-bold">Request</p>
															</Col>
															{getPermissionsByCategory("request").map(
																(permission) => (
																	<Col
																		className="mt-1 text-center"
																		key={permission.id}
																	>
																		<span>
																			{permission.permission_name.split("-")[0]}
																		</span>
																		<Form.Check
																			type="checkbox"
																			name="permissions"
																			value={String(permission.id)}
																			checked={values.permissions.includes(
																				String(permission.id),
																			)}
																			onChange={(e) => {
																				if (e.target.checked) {
																					arrayHelpers.push(
																						String(permission.id),
																					);
																				} else {
																					const idx =
																						values.permissions.indexOf(
																							String(permission.id),
																						);
																					arrayHelpers.remove(idx);
																				}
																			}}
																		/>
																	</Col>
																),
															)}
														</Row>
														{/* Budget Permissions */}
														<Row className="mt-2">
															<Col md={2} className="mt-2">
																<p className="fw-bold">Budget</p>
															</Col>
															{getPermissionsByCategory("budget").map(
																(permission) => (
																	<Col
																		md={1}
																		className="mt-1 text-center"
																		key={permission.id}
																	>
																		<span>
																			{permission.permission_name.split("-")[0]}
																		</span>
																		<Form.Check
																			type="checkbox"
																			name="permissions"
																			value={String(permission.id)}
																			checked={values.permissions.includes(
																				String(permission.id),
																			)}
																			onChange={(e) => {
																				if (e.target.checked) {
																					arrayHelpers.push(
																						String(permission.id),
																					);
																				} else {
																					const idx =
																						values.permissions.indexOf(
																							String(permission.id),
																						);
																					arrayHelpers.remove(idx);
																				}
																			}}
																		/>
																	</Col>
																),
															)}
														</Row>
														{/* Scheduling Permissions */}
														<Row className="mt-2">
															<Col md={2} className="mt-2">
																<p className="fw-bold">Scheduling</p>
															</Col>
															{getPermissionsByCategory("schedule").map(
																(permission) => (
																	<Col
																		md={1}
																		className="mt-1 text-center"
																		key={permission.id}
																	>
																		<span>
																			{permission.permission_name.split("-")[0]}
																		</span>
																		<Form.Check
																			type="checkbox"
																			name="permissions"
																			value={String(permission.id)}
																			checked={values.permissions.includes(
																				String(permission.id),
																			)}
																			onChange={(e) => {
																				if (e.target.checked) {
																					arrayHelpers.push(
																						String(permission.id),
																					);
																				} else {
																					const idx =
																						values.permissions.indexOf(
																							String(permission.id),
																						);
																					arrayHelpers.remove(idx);
																				}
																			}}
																		/>
																	</Col>
																),
															)}
														</Row>
														{/* Report Permissions */}
														<Row className="mt-2">
															<Col md={2} className="mt-2">
																<p className="fw-bold">Report</p>
															</Col>
															{getPermissionsByCategory("report").map(
																(permission) => (
																	<Col
																		md={1}
																		className="mt-1 text-center"
																		key={permission.id}
																	>
																		<span>
																			{permission.permission_name.split("-")[0]}
																		</span>
																		<Form.Check
																			type="checkbox"
																			name="permissions"
																			value={String(permission.id)}
																			checked={values.permissions.includes(
																				String(permission.id),
																			)}
																			onChange={(e) => {
																				if (e.target.checked) {
																					arrayHelpers.push(
																						String(permission.id),
																					);
																				} else {
																					const idx =
																						values.permissions.indexOf(
																							String(permission.id),
																						);
																					arrayHelpers.remove(idx);
																				}
																			}}
																		/>
																	</Col>
																),
															)}
														</Row>
														{/* Inventory Permissions */}
														<Row className="mt-2">
															<Col md={2} className="mt-2">
																<p className="fw-bold">Inventory</p>
															</Col>
															{getPermissionsByCategory("inventory").map(
																(permission) => (
																	<Col
																		md={1}
																		className="mt-1 text-center"
																		key={permission.id}
																	>
																		<span>
																			{permission.permission_name.split("-")[0]}
																		</span>
																		<Form.Check
																			type="checkbox"
																			name="permissions"
																			value={String(permission.id)}
																			checked={values.permissions.includes(
																				String(permission.id),
																			)}
																			onChange={(e) => {
																				if (e.target.checked) {
																					arrayHelpers.push(
																						String(permission.id),
																					);
																				} else {
																					const idx =
																						values.permissions.indexOf(
																							String(permission.id),
																						);
																					arrayHelpers.remove(idx);
																				}
																			}}
																		/>
																	</Col>
																),
															)}
														</Row>
														{/* Accounts Permissions */}
														<Row className="mt-2">
															<Col md={2} className="mt-2">
																<p className="fw-bold">Accounts</p>
															</Col>
															{getPermissionsByCategory("account").map(
																(permission) => (
																	<Col
																		md={1}
																		className="mt-1 text-center"
																		key={permission.id}
																	>
																		<span>
																			{permission.permission_name.split("-")[0]}
																		</span>
																		<Form.Check
																			type="checkbox"
																			name="permissions"
																			value={String(permission.id)}
																			checked={values.permissions.includes(
																				String(permission.id),
																			)}
																			onChange={(e) => {
																				if (e.target.checked) {
																					arrayHelpers.push(
																						String(permission.id),
																					);
																				} else {
																					const idx =
																						values.permissions.indexOf(
																							String(permission.id),
																						);
																					arrayHelpers.remove(idx);
																				}
																			}}
																		/>
																	</Col>
																),
															)}
														</Row>
														{/* Project Permissions */}
														<Row className="mt-2">
															<Col md={2} className="mt-2 text-center">
																<p className="fw-bold">Project</p>
															</Col>
															{getPermissionsByCategory("project").map(
																(permission) => (
																	<Col
																		md={1}
																		className="mt-1 text-center"
																		key={permission.id}
																	>
																		<span>
																			{permission.permission_name.split("-")[0]}
																		</span>
																		<Form.Check
																			type="checkbox"
																			name="permissions"
																			value={String(permission.id)}
																			checked={values.permissions.includes(
																				String(permission.id),
																			)}
																			onChange={(e) => {
																				if (e.target.checked) {
																					arrayHelpers.push(
																						String(permission.id),
																					);
																				} else {
																					const idx =
																						values.permissions.indexOf(
																							String(permission.id),
																						);
																					arrayHelpers.remove(idx);
																				}
																			}}
																		/>
																	</Col>
																),
															)}
														</Row>
													</div>
												)}
											/>
										</Col>
									</Row>
									<Modal.Footer className="mt-5">
										<Row>
											<Col md={6}>
												<div className="mt-1">
													<Button onClick={closeModal} className="cancel-btn">
														<span className="text-center">Cancel</span>
													</Button>
												</div>
											</Col>
											<Col md={6}>
												<div className="mt-1">
													<Button
														type="submit"
														className="border-0 create-btn bg-color-1"
													>
														<span className="text-center">Create role</span>
													</Button>
												</div>
											</Col>
										</Row>
									</Modal.Footer>
								</Modal.Body>
							</Form>
						)}
					</Formik>
				</div>
			</Modal>
		</Container>
	);
};

export default CreateRole;
