import React, { useEffect } from "react";
import { Modal, Button, Row, Col, Container, Form } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import {
	createUserAsync,
	getAllUsersAsync,
} from "../../../../slices/user/userSlice";
import { getAllRolesAsync } from "../../../../slices/settings/settingsSlice";
import { getAllProjectsAsync } from "../../../../slices/project/projectSlice";
import { getUserAsync } from "../../../../slices/auth/authSlice";
import { RootState } from "../../../../store/store";
import MultipleSelect from "../../../../components/form/custom-select";
import { Select, FormControl, InputLabel, MenuItem } from "@mui/material";
import { toast } from "react-toastify";

interface CreateTeamValues {
	company_id: string;
	first_name: string;
	last_name: string;
	email: string;
	phone_number: string;
	role_id: string;
	projects: string[];
	status_id: number;
}

interface CreateTeamProps {
	closeModal: () => void;
	show: boolean;
	onCreateSuccess: () => void;
}

const CreateTeam: React.FC<CreateTeamProps> = ({
	closeModal,
	show,
	onCreateSuccess,
}) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(getUserAsync());
	}, [dispatch]);

	const companyId = useAppSelector(
		(state: RootState) => state.auth.user?.company_id || "",
	);
	const roles = useAppSelector((state) => state.settings.roles);
	const projects = useAppSelector((state) => state.project.projects);

	useEffect(() => {
		if (companyId) {
			dispatch(getAllRolesAsync(companyId));
			dispatch(getAllProjectsAsync(companyId));
		}
	}, [dispatch, companyId]);

	console.log("Roles:", roles);
	console.log("Projects:", projects);

	const handlePhoneNumberChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const { value } = event.target;
		let numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters
		if (numericValue.length > 11) {
			numericValue = numericValue.slice(0, 11); // Truncate to 11 digits
		}
		event.target.value = numericValue;
	};

	return (
		<Container fluid className="">
			<Modal
				size="lg"
				show={show}
				onHide={closeModal}
				style={{ maxHeight: "100vh", overflowY: "auto" }}
			>
				<div className="px-5">
					<Formik
						initialValues={{
							company_id: companyId || "",
							first_name: "",
							last_name: "",
							email: "",
							phone_number: "",
							role_id: "",
							projects: [],
							status_id: 1,
						}}
						validationSchema={Yup.object().shape({
							first_name: Yup.string().required("Required"),
							last_name: Yup.string().required("Required"),
							email: Yup.string()
								.email("Invalid email")
								.required("Email is required"),
							phone_number: Yup.string()
								.matches(
									/^(070|080|081|090|091)[0-9]{8}$/,
									"Please enter a valid 11 digit phone number in Nigerian format e.g 08012345678",
								)
								.required("Please enter a valid 11 digit phone number"),
							role_id: Yup.string().required("Required"),
							projects: Yup.array()
								.of(Yup.string())
								.min(1, "At least one project is required")
								.required("Required"),
						})}
						onSubmit={(values: CreateTeamValues, { setSubmitting }) => {
							console.log("Submitting values:", values);
							dispatch(
								createUserAsync({
									...values,
									company_id: companyId || "",
								}),
							)
								.unwrap()
								.then((response) => {
									toast.success("Member created successfully");
									dispatch(getAllUsersAsync(companyId));
									onCreateSuccess();
									closeModal();
								})
								.catch((error) => {
									const errorMessage = Object.values(error.message)
										.flat()
										.join(" ");
									toast.error(errorMessage);
								})
								.finally(() => {
									setSubmitting(false);
								});
						}}
					>
						{({
							errors,
							touched,
							handleSubmit,
							isSubmitting,
							values,
							handleChange,
							setFieldValue,
						}) => (
							<Form onSubmit={handleSubmit}>
								<Modal.Header className="text-start mt-2">
									<h5>Add new member</h5>
								</Modal.Header>
								<Modal.Body>
									<Row>
										<Col md={6} className="mt-2">
											<label className="mt-2">
												<p>
													First name <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="first_name"
												placeholder="Enter first name"
												className={`fluent-input ${
													touched.first_name && errors.first_name
														? "is-invalid"
														: ""
												}`}
												required
											/>
											<div className="invalid-feedback d-block">
												{touched.first_name && errors.first_name}
											</div>
										</Col>
										<Col md={6} className="mt-2">
											<label className="mt-2">
												<p>
													Last name <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="last_name"
												placeholder="Enter last name"
												className={`fluent-input ${
													touched.last_name && errors.last_name
														? "is-invalid"
														: ""
												}`}
												required
											/>
											<div className="invalid-feedback d-block">
												{touched.last_name && errors.last_name}
											</div>
										</Col>
									</Row>
									<Row>
										<Col md={6} className="mt-2">
											<label className="mt-2">
												<p>
													Email address <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="email"
												name="email"
												placeholder="Enter email address"
												className={`fluent-input ${
													touched.email && errors.email ? "is-invalid" : ""
												}`}
												required
											/>
											<div className="invalid-feedback d-block">
												{touched.email && errors.email}
											</div>
										</Col>
										<Col md={6} className="mt-2">
											<label className="mt-2">
												<p>
													Phone Number <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="phone_number"
												placeholder="Enter phone number"
												className={`fluent-input ${
													touched.phone_number && errors.phone_number
														? "is-invalid"
														: ""
												}`}
												onInput={handlePhoneNumberChange}
												required
											/>
											<div className="invalid-feedback d-block">
												{touched.phone_number && errors.phone_number}
											</div>
										</Col>
									</Row>
									<Row>
										<Col md={6} className="mt-2">
											<label className="mt-2">
												<p>
													Role <span className="text-danger">*</span>
												</p>
											</label>
											<FormControl fullWidth>
												<InputLabel>Select role</InputLabel>
												<Select
													label="Select role"
													name="role_id"
													value={values.role_id}
													onChange={handleChange}
												>
													{roles.map((role) => (
														<MenuItem key={role.id} value={role.id}>
															{role.role_name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
											<div className="invalid-feedback d-block">
												{touched.role_id && errors.role_id}
											</div>
										</Col>
										<Col md={6} className="mt-2">
											<label className="mt-2">
												<p>
													Assign project <span className="text-danger">*</span>
												</p>
											</label>
											<MultipleSelect
												label="Select project(s)"
												name="projects"
												options={projects.map((project: any) => ({
													value: project.id,
													label: project.project_name,
												}))}
												value={values.projects}
												onChange={(selectedProjects: string[]) =>
													setFieldValue("projects", selectedProjects)
												}
											/>
											<div className="invalid-feedback d-block">
												{touched.projects && errors.projects}
											</div>
										</Col>
									</Row>
									<Modal.Footer className="mt-5">
										<Row>
											<Col md={6}>
												<div className="mt-1">
													<Button onClick={closeModal} className="cancel-btn">
														<span className="text-center">Cancel</span>
													</Button>
												</div>
											</Col>
											<Col md={6}>
												<div className="mt-1">
													<Button
														type="submit"
														className="border-0 create-btn bg-color-1"
													>
														<span className="text-center">Create member</span>
													</Button>
												</div>
											</Col>
										</Row>
									</Modal.Footer>
								</Modal.Body>
							</Form>
						)}
					</Formik>
				</div>
			</Modal>
		</Container>
	);
};

export default CreateTeam;
