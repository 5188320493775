import React, { useEffect } from "react";
import { Modal, Button, Row, Col, Container, Form } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { createStageAsync, getAllStagesAsync } from "../../../../slices/settings/settingsSlice";
import { getUserAsync } from "../../../../slices/auth/authSlice";
import { RootState } from "../../../../store/store";
import { toast } from "react-toastify";
interface CreateStageValues {
    stage: string;
    company_id: string;
}
interface CreateStageProps {
    closeModal: () => void;
    show: boolean;
    onCreateSuccess: () => void;
}

const CreateStage: React.FC<CreateStageProps> = ({ closeModal, show, onCreateSuccess }) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getUserAsync());
    }, [dispatch]);

    const companyId = useAppSelector(
        (state: RootState) => state.auth.user?.company_id || "",
    );

    return (
        <Container fluid>
            <Modal
                size="lg"
                show={show}
                onHide={closeModal}
                style={{ maxHeight: "100vh", overflowY: "auto" }}>
                <div className="px-5">
                    <Formik
                        initialValues={{
                            stage: "",
                            company_id: companyId || "",
                        }}
                        validationSchema={
                            Yup.object().shape({
                                stage: Yup.string().required("Required"),
                                company_id: Yup.string().required("Required"),
                            })}
                        onSubmit={(values: CreateStageValues, { setSubmitting }) => {
                            console.log("Submitting values:", values);
                            dispatch(
                                createStageAsync({
                                    ...values,
                                    company_id: companyId || "",
                                }),
                            ).unwrap()
                                .then((response) => {
                                    toast.success("Stage created successfully");
                                    dispatch(getAllStagesAsync(companyId));
                                    onCreateSuccess();
                                    closeModal();
                                })
                                .catch((error) => {
                                    const errorMessage = Object.values(error.message).flat().join(" ");
                                    toast.error(errorMessage);
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                });
                        }}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            isSubmitting,
                            values,
                            handleChange,
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <Modal.Header className="text-start mt-3">
                                    <h5>Add new stage</h5>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row>
                                        <Col className="mt-2">
                                            <label className="mt-2">
                                                <p>
                                                    Stage
                                                </p>
                                            </label>
                                            <Field
                                                type="text"
                                                name="stage"
                                                className={`fluent-input  ${touched.stage &&
                                                    errors.stage
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}

                                            />
                                        </Col>
                                    </Row>
                                    <Modal.Footer className="mt-5">
                                        <Row>
                                            <Col md={6}>
                                                <div className=" mt-1">
                                                    <Button onClick={closeModal} className="cancel-btn">
                                                        <span className="text-center">Cancel </span>{" "}
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className=" mt-1">
                                                    <Button
                                                        type="submit"
                                                        className=" border-0 create-btn bg-color-1"
                                                        disabled={isSubmitting}
                                                    >
                                                        <span className="text-center">Create stage</span>{" "}
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Modal.Footer>
                                </Modal.Body>
                            </Form>
                        )}
                    </Formik>
                </div>

            </Modal>
        </Container>
    );
};

export default CreateStage;