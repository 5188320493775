import React, { ReactNode } from 'react';
import Navigation from './navigation';
import Footer from './footer';

interface LandingLayoutProps {
    children: ReactNode;
}

function LandingLayout({ children }: LandingLayoutProps): JSX.Element {
    return (
        <div>
            <Navigation />
            <div>{children}</div>
            <Footer />
        </div>
    );
}

export default LandingLayout;
