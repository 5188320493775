import React from "react";
import { ReactNode } from "react";
import SideBar from "../sidebar/sidebar";

interface Props {
	children: ReactNode;
}

const Layout: React.FC<Props> = ({ children }) => {
	return (
		<div className="settings-layout">
			<SideBar />
			<main className="content">
				<div>{children}</div>
			</main>
		</div>
	);
};

export default Layout;
