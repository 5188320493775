import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { login, signup, checkVerification, forgotPassword, resetPassword, resend, logout, SetNewPasswordResponse, SetNewPasswordData, setNewPassword } from "../../services/auth/authService";
import {
    SignUpData, SignUpResponse, CheckVerificationResponse, ResendData, ResendResponse, LogInData, LogInResponse, LogoutResponse,
    ForgotPasswordData, ForgotPasswordResponse, ResetPasswordData, ResetPasswordResponse
} from "../../services/auth/authService";
import { getUser, GetUserResponse, UserData } from "../../services/auth/authService";

export interface AuthState {
    signUpResponse?: SignUpResponse | null;
    getUserResponse: GetUserResponse | null;
    isVerified: CheckVerificationResponse | boolean;
    resendResponse: ResendResponse | null;
    logInResponse?: LogInResponse | null;
    logoutResponse?: LogoutResponse | null;
    forgotPassword?: ForgotPasswordResponse | null;
    resetPassword?: ResetPasswordResponse | null;
    setNewPassword?: SetNewPasswordResponse | null;
    user?: UserData | null;
    email?: string;
    token?: string;
    companyId?: string;
}

const initialState: AuthState = {
    signUpResponse: null,
    isVerified: false,
    getUserResponse: null,
    resendResponse: null,
    logInResponse: null,
    logoutResponse: null,
    forgotPassword: null,
    resetPassword: null,
    setNewPassword: null,
    user: null,
    email: "",
    token: "",
    companyId: "",
};

export const getUserAsync = createAsyncThunk(
    'auth/getUser',
    async () => {
        const response = await getUser();
        return response;
    }
);

export const signupAsync = createAsyncThunk(
    'auth/signup',
    async (signUpData: SignUpData, { dispatch, rejectWithValue }) => {
        try {
            const response = await signup(signUpData);
            console.log('Signup response:', response);
            if (response.data && response.data.user && response.data.user.email) {
                const userEmail = response.data.user.email;
                localStorage.setItem('userVerificationEmail', userEmail);
                dispatch(setEmail(userEmail));
            }
            return response;
        } catch (error: any) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ error: true, message: "An unexpected error occurred" });
        }
    }
);

export const checkUserVerificationAsync = createAsyncThunk(
    'auth/checkVerification',
    async () => {
        const response = await checkVerification();
        return response;
    }
);

export const resendAsync = createAsyncThunk(
    'auth/resend',
    async (resendData: ResendData) => {
        const response = await resend(resendData);
        return response;
    }
);

export const loginAsync = createAsyncThunk(
    'auth/login',
    async (logInData: LogInData, { dispatch, rejectWithValue }) => {
        try {
            const response = await login(logInData);
            console.log('Login response:', response);
            if (response.data && response.data.user && response.data.user.email) {
                const userEmail = response.data.user.email;
                const userCompanyID = response.data.user.company_id;
                const token = response.data.token;
                localStorage.setItem('userLoginEmail', userEmail);
                localStorage.setItem('userCompanyID', userCompanyID);
                localStorage.setItem('token', token);
                dispatch(setEmail(userEmail));
                dispatch(setToken(token));
            }
            return response;
        } catch (error: any) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ error: true, message: "An unexpected error occurred" });
        }
    }
);

export const logoutAsync = createAsyncThunk(
    'auth/logout',
    async (): Promise<LogoutResponse> => {
        const response = await logout();
        return response;
    }
);

export const forgotPasswordAsync = createAsyncThunk(
    'auth/forgotPassword',
    async (forgotPasswordData: ForgotPasswordData, { dispatch, rejectWithValue }) => {
        try {
            const response = await forgotPassword(forgotPasswordData);
            console.log('API Response:', response); // Log the API response
            if (response.data) {
                const resetToken = response.data.token;
                const email = forgotPasswordData.email;  // Use the email from the input data
                localStorage.setItem('resetToken', resetToken);
                localStorage.setItem('resetEmail', email);
                console.log('Email saved in local storage:', email);
                dispatch(setToken(resetToken));
                dispatch(setEmail(email));
            }
            return response;
        } catch (error: any) {
            console.error('API Error:', error); // Log the error
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ error: true, message: "An unexpected error occurred" });
        }
    }
);

export const resetPasswordAsync = createAsyncThunk(
    'auth/resetPassword',
    async (resetPasswordData: ResetPasswordData, { rejectWithValue }) => {
        try {
            const response = await resetPassword(resetPasswordData);
            return response;
        } catch (error: any) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ error: true, message: "An unexpected error occurred" });
        }
    }
);

export const setNewPasswordAsync = createAsyncThunk(
    'auth/setNewPassword',
    async (setNewPasswordData: SetNewPasswordData, { rejectWithValue }) => {
        try {
            const response = await setNewPassword(setNewPasswordData);
            return response;
        } catch (error: any) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ error: true, message: "An unexpected error occurred" });
        }
    }
);

// export const setNewPasswordAsync = createAsyncThunk(
//     'auth/setNewPassword',
//     async (setNewPasswordData: Partial<SetNewPasswordData>) => {

//         const completeData: SetNewPasswordData = {
//             ...setNewPasswordData,
//         } as SetNewPasswordData;
//         const response = await setNewPassword(completeData);
//         return response;
//     }
// )

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUserAsync.fulfilled, (state, action) => {
            state.user = action.payload.data;
        });
        builder.addCase(signupAsync.fulfilled, (state, action) => {
            state.signUpResponse = action.payload;
            if (action.payload.data) {
                state.email = action.payload.data.user.email;
                state.companyId = action.payload.data.user.company_id;
            }
        });
        builder.addCase(checkUserVerificationAsync.fulfilled, (state, action) => {
            state.isVerified = action.payload.isVerified;
        });
        builder.addCase(checkUserVerificationAsync.rejected, (state) => {
            state.isVerified = false;
        });
        builder.addCase(resendAsync.fulfilled, (state, action) => {
            state.resendResponse = action.payload;
        });
        builder.addCase(loginAsync.fulfilled, (state, action) => {
            state.logInResponse = action.payload;
            if (action.payload.data) {
                state.email = action.payload.data.user.email;
                state.companyId = action.payload.data.user.company_id;
                state.token = action.payload.data.token;
            }
        });
        builder.addCase(logoutAsync.fulfilled, (state, action) => {
            state.logoutResponse = action.payload;
        });
        builder.addCase(forgotPasswordAsync.fulfilled, (state, action: PayloadAction<ForgotPasswordResponse>) => {
            console.log('Forgot Password Fulfilled:', action.payload); // Log the fulfilled action
            state.forgotPassword = action.payload;
            if (action.payload.data) {
                state.email = action.payload.data.email;
            }
        });
        builder.addCase(resetPasswordAsync.fulfilled, (state, action) => {
            state.resetPassword = action.payload;
            if (action.payload.data) {
            }
        });
        builder.addCase(setNewPasswordAsync.fulfilled, (state, action) => {
            state.resetPassword = action.payload;
        });
    },
});

export const { setEmail, setToken } = authSlice.actions;
export default authSlice.reducer;
