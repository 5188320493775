import axios from 'axios';

export interface ApprovedMaterialRequests {
    company_id: string;
    created_on: string;
    vendor_id: string;
    vendor_name: string;
    material_id: string;
    total_quantity: string;
    batch_materials: string;
    materials: {
        material_name: string;
        material_id: string;
        quantity: string;
        unit_cost: string;
    }[]; quantity: string;
    amount: string;
    status: string;
    projectId: string;
    id: string;
}
export interface PayApprovedMaterialRequestsData {
    company_id: string;
    projectId: string;
    id: string;
}
export interface PayApprovedMaterialRequestsResponse {
    data: ApprovedMaterialRequests;
    message: string;
}
export interface GetAllApprovedMaterialRequestsResponse {
    data: ApprovedMaterialRequests[];
    message: string;
}
export interface ApprovedLabourRequest {
    company_id: string;
    created_on: string;
    head_name: string;
    stage: string;
    stage_id: string;
    labour_type: string;
    labour_type_id: string;
    bank: string;
    bank_id: string;
    account_number: string;
    amount: string;
    status: string;
    projectId: string;
    id: string;
}
export interface PayApprovedLabourRequestsData {
    company_id: string;
    projectId: string;
    id: string;
}
export interface PayApprovedLabourRequestsResponse {
    data: ApprovedLabourRequest;
    message: string;
}
export interface GetAllApprovedLabourRequestsResponse {
    data: ApprovedLabourRequest[];
    message: string;
}
export interface ApprovedPettyRequest {
    company_id: string;
    created_on: string;
    title: string;
    receiver_name: string;
    bank_id: string;
    account_number: string;
    amount: string;
    status: string;
    projectId: string;
    id: string;
}
export interface PayApprovedPettyRequestsData {
    company_id: string;
    projectId: string;
    id: string;
}
export interface PayApprovedPettyRequestsResponse {
    data: ApprovedPettyRequest;
    message: string;
}
export interface GetAllApprovedPettyRequestsResponse {
    data: ApprovedPettyRequest[];
    message: string;
}

export const getAllApprovedMaterialRequests = async (companyId: string, projectId: string): Promise<ApprovedMaterialRequests[]> => {
    const response = await axios.get<GetAllApprovedMaterialRequestsResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/projects/${projectId}/material-requests/approved?company_id=${companyId}`);
    return response.data.data;
}
export const payApprovedMaterialRequest = async (values: PayApprovedMaterialRequestsData): Promise<PayApprovedMaterialRequestsResponse> => {
    const response = await axios.put<PayApprovedMaterialRequestsResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/projects/${values.projectId}/material-requests/${values.id}/pay`, values);
    return response.data;
}
export const getAllApprovedLabourRequests = async (companyId: string, projectId: string): Promise<ApprovedLabourRequest[]> => {
    const response = await axios.get<GetAllApprovedLabourRequestsResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/projects/${projectId}/labour-requests/approved?company_id=${companyId}`);
    return response.data.data;
}
export const payApprovedLabourRequest = async (values: PayApprovedLabourRequestsData): Promise<PayApprovedLabourRequestsResponse> => {
    const response = await axios.put<PayApprovedLabourRequestsResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/projects/${values.projectId}/labour-requests/${values.id}/pay`, values);
    return response.data;
}
export const getAllApprovedPettyRequests = async (companyId: string, projectId: string): Promise<ApprovedPettyRequest[]> => {
    const response = await axios.get<GetAllApprovedPettyRequestsResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/projects/${projectId}/petty-cash-requests/approved?company_id=${companyId}`);
    return response.data.data;
}
export const payApprovedPettyRequest = async (values: PayApprovedPettyRequestsData): Promise<PayApprovedPettyRequestsResponse> => {
    const response = await axios.put<PayApprovedPettyRequestsResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/projects/${values.projectId}/petty-cash-requests/${values.id}/pay`, values);
    return response.data;
}