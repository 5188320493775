import React, { useState, useEffect } from 'react';
import Layout from '../../../components/layouts/layout'
import { Nav, Table, Button, Row, Col, Dropdown } from 'react-bootstrap';
import CreateMaterialRequest from './modals/create-material-request';
import EditMaterialRequest from './modals/edit-material-request';
import ViewMaterialRequest from './modals/view-material-request';
import ReviewMaterialRequest from './modals/review-material-request';
import ApproveMaterialRequest from './modals/approve-material-request';
import RejectMaterialRequest from './modals/reject-material-request';
import CreateLabourRequest from './modals/create-labour-request';
import EditLabourRequest from './modals/edit-labour-request';
import ViewLabourRequest from './modals/view-labour-request';
import ReviewLabourRequest from './modals/review-labour-request';
import ApproveLabourRequest from './modals/approve-labour-request';
import RejectLabourRequest from './modals/reject-labour-request';
import CreateUtilityRequest from './modals/create-utility-request';
import EditUtilityRequest from './modals/edit-utility-request';
import ViewUtilityRequest from './modals/view-utility-request';
import ReviewUtilityRequest from './modals/review-utility-request';
import ApproveUtilityRequest from './modals/approve-utility-request';
import RejectUtilityRequest from './modals/reject-utility-request';
import Action from "../../../assets/images/action-vector.png"
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import { getAllLabourRequestsAsync, getAllMaterialRequestsAsync, getAllPettyRequestsAsync } from '../../../slices/requests/requestsSlice';
import { format } from 'date-fns';


function Procurement() {
    const formatCurrency = (amount: number): string => {
        return `₦${amount.toLocaleString('en-US')}`;
    };
    const dispatch = useAppDispatch();
    const { projectId } = useParams<{ projectId: string }>();
    const companyId = useAppSelector(
        (state: RootState) => state.auth.user?.company_id || "",
    );
    const materialRequests = useAppSelector((state) => state.requests.materialRequests)
    const labourRequests = useAppSelector((state) => state.requests.labourRequests)
    const pettyRequests = useAppSelector((state) => state.requests.pettyRequests)
    const [refreshData, setRefreshData] = useState(false);

    const [activeTab, setActiveTab] = useState<string>('material-request');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

    const [selectedMaterialRequestId, setSelectedMaterialRequestId] = useState<string | null>(null);
    const [selectedLabourRequestId, setSelectedLabourRequestId] = useState<string | null>(null);
    const [selectedPettyRequestId, setSelectedPettyRequestId] = useState<string | null>(null);

    const [createMaterialRequestModalOpen, setCreateMaterialRequestModalOpen] =
        useState<boolean>(false);
    const handleOpenCreateMaterialRequestModal = () =>
        setCreateMaterialRequestModalOpen(true);
    const handleCloseCreateMaterialRequestModal = () =>
        setCreateMaterialRequestModalOpen(false);

    const [editMaterialRequestModalOpen, setEditMaterialRequestModalOpen] = useState<boolean>(false);
    const handleOpenEditMaterialRequestModal = (materialRequestId: string) => {
        setSelectedMaterialRequestId(materialRequestId);
        setEditMaterialRequestModalOpen(true);
    };
    const handleCloseEditMaterialRequestModal = () => setEditMaterialRequestModalOpen(false);

    const [viewMaterialRequestModalOpen, setViewMaterialRequestModalOpen] = useState<boolean>(false);
    const handleOpenViewMaterialRequestModal = (materialRequestId: string) => {
        setSelectedMaterialRequestId(materialRequestId);
        setViewMaterialRequestModalOpen(true);
    };
    const handleCloseViewMaterialRequestModal = () => setViewMaterialRequestModalOpen(false);

    const [reviewMaterialRequestModalOpen, setReviewMaterialRequestModalOpen] = useState<boolean>(false);
    const handleOpenReviewMaterialRequestModal = (materialRequestId: string) => {
        setSelectedMaterialRequestId(materialRequestId);
        setReviewMaterialRequestModalOpen(true);
    };
    const handleCloseReviewMaterialRequestModal = () => setReviewMaterialRequestModalOpen(false);

    const [approveMaterialRequestModalOpen, setApproveMaterialRequestModalOpen] = useState<boolean>(false);
    const handleOpenApproveMaterialRequestModal = (materialRequestId: string) => {
        setSelectedMaterialRequestId(materialRequestId);
        setApproveMaterialRequestModalOpen(true);
    };
    const handleCloseApproveMaterialRequestModal = () => setApproveMaterialRequestModalOpen(false);

    const [rejectMaterialRequestModalOpen, setRejectMaterialRequestModalOpen] = useState<boolean>(false);
    const handleOpenRejectMaterialRequestModal = (materialRequestId: string) => {
        setSelectedMaterialRequestId(materialRequestId);
        setRejectMaterialRequestModalOpen(true);
    };
    const handleCloseRejectMaterialRequestModal = () => setRejectMaterialRequestModalOpen(false);

    const [materialRequestDropdownOpen, setMaterialRequestDropdownOpen] = useState<{
        [key: number]: boolean;
    }>({});
    const toggleMaterialRequestDropdown = (index: number) => {
        setMaterialRequestDropdownOpen((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const [createLabourRequestModalOpen, setCreateLabourRequestModalOpen] =
        useState<boolean>(false);
    const handleOpenCreateLabourRequestModal = () =>
        setCreateLabourRequestModalOpen(true);
    const handleCloseCreateLabourRequestModal = () =>
        setCreateLabourRequestModalOpen(false);

    const [editLabourRequestModalOpen, setEditLabourRequestModalOpen] = useState<boolean>(false);
    const handleOpenEditLabourRequestModal = (labourRequestId: string) => {
        setSelectedLabourRequestId(labourRequestId);
        setEditLabourRequestModalOpen(true);
    };

    const handleCloseEditLabourRequestModal = () => setEditLabourRequestModalOpen(false);

    const [viewLabourRequestModalOpen, setViewLabourRequestModalOpen] = useState<boolean>(false);
    const handleOpenViewLabourRequestModal = (labourRequestId: string) => {
        setSelectedLabourRequestId(labourRequestId);
        setViewLabourRequestModalOpen(true);
    };

    const handleCloseViewLabourRequestModal = () => setViewLabourRequestModalOpen(false);

    const [reviewLabourRequestModalOpen, setReviewLabourRequestModalOpen] = useState<boolean>(false);
    const handleOpenReviewLabourRequestModal = (labourRequestId: string) => {
        setSelectedLabourRequestId(labourRequestId);
        setReviewLabourRequestModalOpen(true);
    };

    const handleCloseReviewLabourRequestModal = () => setReviewLabourRequestModalOpen(false);

    const [approveLabourRequestModalOpen, setApproveLabourRequestModalOpen] = useState<boolean>(false);
    const handleOpenApproveLabourRequestModal = (labourRequestId: string) => {
        setSelectedLabourRequestId(labourRequestId);
        setApproveLabourRequestModalOpen(true);
    };

    const handleCloseApproveLabourRequestModal = () => setApproveLabourRequestModalOpen(false);

    const [rejectLabourRequestModalOpen, setRejectLabourRequestModalOpen] = useState<boolean>(false);
    const handleOpenRejectLabourRequestModal = (labourRequestId: string) => {
        setSelectedLabourRequestId(labourRequestId);
        setRejectLabourRequestModalOpen(true);
    };

    const handleCloseRejectLabourRequestModal = () => setRejectLabourRequestModalOpen(false);

    const [labourRequestDropdownOpen, setLabourRequestDropdownOpen] = useState<{
        [key: number]: boolean;
    }>({});

    const toggleLabourRequestDropdown = (index: number) => {
        setLabourRequestDropdownOpen((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };


    const [createUtilityRequestModalOpen, setCreateUtilityRequestModalOpen] =
        useState<boolean>(false);
    const handleOpenCreateUtilityRequestModal = () =>
        setCreateUtilityRequestModalOpen(true);
    const handleCloseCreateUtilityRequestModal = () =>
        setCreateUtilityRequestModalOpen(false);

    const [editUtilityRequestModalOpen, setEditUtilityRequestModalOpen] = useState<boolean>(false);
    const handleOpenEditUtilityRequestModal = (pettyRequestId: string) => {
        setSelectedPettyRequestId(pettyRequestId);
        setEditUtilityRequestModalOpen(true);
    };
    const handleCloseEditUtilityRequestModal = () => setEditUtilityRequestModalOpen(false);

    const [viewUtilityRequestModalOpen, setViewUtilityRequestModalOpen] = useState<boolean>(false);
    const handleOpenViewUtilityRequestModal = (pettyRequestId: string) => {
        setSelectedPettyRequestId(pettyRequestId);
        setViewUtilityRequestModalOpen(true);
    };

    const handleCloseViewUtilityRequestModal = () => setViewUtilityRequestModalOpen(false);

    const [reviewUtilityRequestModalOpen, setReviewUtilityRequestModalOpen] = useState<boolean>(false);
    const handleOpenReviewUtilityRequestModal = (pettyRequestId: string) => {
        setSelectedPettyRequestId(pettyRequestId);
        setReviewUtilityRequestModalOpen(true);
    };

    const handleCloseReviewUtilityRequestModal = () => setReviewUtilityRequestModalOpen(false);

    const [approveUtilityRequestModalOpen, setApproveUtilityRequestModalOpen] = useState<boolean>(false);
    const handleOpenApproveUtilityRequestModal = (pettyRequestId: string) => {
        setSelectedPettyRequestId(pettyRequestId);
        setApproveUtilityRequestModalOpen(true);
    };

    const handleCloseApproveUtilityRequestModal = () => setApproveUtilityRequestModalOpen(false);

    const [rejectUtilityRequestModalOpen, setRejectUtilityRequestModalOpen] = useState<boolean>(false);
    const handleOpenRejectUtilityRequestModal = (pettyRequestId: string) => {
        setSelectedPettyRequestId(pettyRequestId);
        setRejectUtilityRequestModalOpen(true);
    };

    const handleCloseRejectUtilityRequestModal = () => setRejectUtilityRequestModalOpen(false);

    const [utilityRequestDropdownOpen, setUtilityRequestDropdownOpen] = useState<{
        [key: number]: boolean;
    }>({});

    const toggleUtilityRequestDropdown = (index: number) => {
        setUtilityRequestDropdownOpen((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    useEffect(() => {
        if (companyId && projectId) {
            console.log("companyId:", companyId);
            dispatch(getAllMaterialRequestsAsync({ companyId, projectId }));
            dispatch(getAllLabourRequestsAsync({ companyId, projectId }));
            dispatch(getAllPettyRequestsAsync({ companyId, projectId }));
        }
    }, [companyId, dispatch, projectId]);

    useEffect(() => {
        if (refreshData && companyId && projectId) {
            dispatch(getAllMaterialRequestsAsync({ companyId, projectId }));
            dispatch(getAllLabourRequestsAsync({ companyId, projectId }));
            dispatch(getAllPettyRequestsAsync({ companyId, projectId }));
            setRefreshData(false);
        }
    }, [refreshData, companyId, dispatch, projectId]);

    useEffect(() => {
        setActiveTab("material-request");
    }, []);

    const tabOrder: string[] = [
        "material-request",
        "labour-request",
        "utility-request",
    ];

    const handleTabSelect = (key: string | null) => {
        if (key) {
            setActiveTabIndex(tabOrder.indexOf(key));
            setActiveTab(key);
        }
    };

    const handleCreateSuccess = () => {
        setRefreshData(true);
    };

    const handleEditSuccess = () => {
        setRefreshData(true);
    };
    console.log("companyId:", companyId);
    console.log("projectId:", projectId);
    return (
        <div>
            <Layout>
                <div className="pt-5 p-3">
                    <h3>Requests</h3>
                    <Nav variant="underline"
                        activeKey={activeTab}
                        onSelect={handleTabSelect}
                        className="profile-nav"

                    >
                        <Nav.Item >
                            <Nav.Link eventKey="material-request"
                                style={{
                                    color:
                                        activeTab === 'material-request'
                                            ? ' #0F6CBD '
                                            : ' #747487', textDecoration: 'none',
                                    borderBottom:
                                        activeTab === 'material-request'
                                            ? '3px solid #0F6CBD '
                                            : '2px solid transparent',
                                }}>Material request</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className=''>
                            <Nav.Link eventKey="labour-request"
                                style={{
                                    color:
                                        activeTab === 'labour-request'
                                            ? ' #0F6CBD '
                                            : ' #747487', textDecoration: 'none',
                                    borderBottom:
                                        activeTab === 'labour-request'
                                            ? '3px solid #0F6CBD '
                                            : '2px solid transparent',
                                }}>Labour request</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="utility-request"
                                style={{
                                    color:
                                        activeTab === 'utility-request'
                                            ? ' #0F6CBD '
                                            : ' #747487', textDecoration: 'none',
                                    borderBottom:
                                        activeTab === 'utility-request'
                                            ? '3px solid #0F6CBD '
                                            : '2px solid transparent',
                                }}>Petty cash request</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <div>
                        {activeTab === "material-request" && (
                            <div>
                                <Row className='my-2'>
                                    <Col md={12}>
                                        <Button onClick={handleOpenCreateMaterialRequestModal} className='py-3 px-5 border-0 bg-color-1 float-end'>
                                            <span className='text-center'>Create request</span>
                                        </Button>
                                    </Col>
                                </Row>
                                <Table borderless responsive className='mt-5 request-table'>
                                    <thead >
                                        <tr>
                                            <th className='text-color-1 bg-color-3'>S/N</th>
                                            <th className='text-color-1 bg-color-3'>Date </th>
                                            <th className='text-color-1 bg-color-3'>Material </th>
                                            <th className='text-color-1 bg-color-3'>Quantity </th>
                                            <th className='text-color-1 bg-color-3'>Cost </th>
                                            <th className='text-color-1 bg-color-3'>Vendor name</th>
                                            <th className='text-color-1 bg-color-3'>Approval status</th>
                                            <th className='text-color-1 bg-color-3'>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(materialRequests) && materialRequests.length > 0 ? (
                                            materialRequests.map((materialRequest, index) => (
                                                <tr key={materialRequest.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{format(new Date(materialRequest.created_on), "dd/MM/yy")}</td>
                                                    <td>{materialRequest.batch_materials}</td>
                                                    <td>{materialRequest.total_quantity}</td>
                                                    <td>{formatCurrency(parseFloat(materialRequest.amount))}</td>
                                                    <td>{materialRequest.vendor_name}</td>
                                                    <td>{materialRequest.status}</td>
                                                    <td style={{ padding: '0', verticalAlign: 'middle' }}>
                                                        <Dropdown show={materialRequestDropdownOpen[index]} onToggle={() => toggleMaterialRequestDropdown(index)}>
                                                            <Dropdown.Toggle className="dropdown-toggle" variant="transparent" style={{ background: 'none', border: 'none', boxShadow: 'none' }}>
                                                                <img src={Action} alt="Action" className="action-icon mb-3" style={{ margin: '0', padding: '0' }} />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => handleOpenEditMaterialRequestModal(materialRequest.id)}>Edit</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => handleOpenViewMaterialRequestModal(materialRequest.id)}>View</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => handleOpenReviewMaterialRequestModal(materialRequest.id)}>Review</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => handleOpenApproveMaterialRequestModal(materialRequest.id)}>Approve</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => handleOpenRejectMaterialRequestModal(materialRequest.id)}>Reject</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>

                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={8} className="text-center">No material requests found.</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        )}

                    </div>
                    <div>
                        {activeTab === "labour-request" && (
                            <div>
                                <Row className='my-2'>
                                    <Col md={12}>
                                        <Button onClick={handleOpenCreateLabourRequestModal} className='py-3 px-5 border-0 bg-color-1 float-end'>
                                            <span className='text-center'>Create request</span>
                                        </Button>
                                    </Col>
                                </Row>
                                <Table borderless responsive className='mt-5 request-table'>
                                    <thead >
                                        <tr>
                                            <th className='text-color-1 bg-color-3'>S/N</th>
                                            <th className='text-color-1 bg-color-3'>Date </th>
                                            <th className='text-color-1 bg-color-3'>Labour type </th>
                                            <th className='text-color-1 bg-color-3'>Stage </th>
                                            <th className='text-color-1 bg-color-3'>Amount </th>
                                            <th className='text-color-1 bg-color-3'>Approval status</th>
                                            <th className='text-color-1 bg-color-3'>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(labourRequests) && labourRequests.length > 0 ? (
                                            labourRequests.map((labourRequest, index) => (
                                                <tr key={labourRequest.id}>
                                                    <td>{index + 1}</td>
                                                    <td> {format(new Date(labourRequest.created_on), "dd/MM/yy")} </td>
                                                    <td>{labourRequest.labour_type} </td>
                                                    <td>{labourRequest.stage}</td>
                                                    <td>{formatCurrency(parseFloat(labourRequest.amount))}</td>
                                                    <td>{labourRequest.status}</td>
                                                    <td style={{ padding: '0', verticalAlign: 'middle' }}>
                                                        <Dropdown show={labourRequestDropdownOpen[index]} onToggle={() => toggleLabourRequestDropdown(index)}>
                                                            <Dropdown.Toggle className="dropdown-toggle" variant="transparent" style={{ background: 'none', border: 'none', boxShadow: 'none' }}>
                                                                <img src={Action} alt="Action" className="action-icon mb-3" style={{ margin: '0', padding: '0' }} />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => handleOpenEditLabourRequestModal(labourRequest.id)}>Edit</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => handleOpenViewLabourRequestModal(labourRequest.id)}>View</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => handleOpenReviewLabourRequestModal(labourRequest.id)}>Review</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => handleOpenApproveLabourRequestModal(labourRequest.id)}>Approve</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => handleOpenRejectLabourRequestModal(labourRequest.id)}>Reject</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={6} className="text-center">No labour requests found.</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        )}

                    </div>
                    <div>
                        {activeTab === "utility-request" && (
                            <div>
                                <Row className='my-2'>
                                    <Col md={12}>
                                        <Button onClick={handleOpenCreateUtilityRequestModal} className='py-3 px-5 border-0 bg-color-1 float-end'>
                                            <span className='text-center'>Create request</span>
                                        </Button>
                                    </Col>
                                </Row>
                                <Table borderless responsive className='mt-5 request-table'>
                                    <thead >
                                        <tr>
                                            <th className='text-color-1 bg-color-3'>S/N</th>
                                            <th className='text-color-1 bg-color-3'>Date </th>
                                            <th className='text-color-1 bg-color-3'> Title </th>
                                            <th className='text-color-1 bg-color-3'>Receiver name </th>
                                            <th className='text-color-1 bg-color-3'>Amount </th>
                                            <th className='text-color-1 bg-color-3'>Approval status</th>
                                            <th className='text-color-1 bg-color-3'>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(pettyRequests) && pettyRequests.length > 0 ? (
                                            pettyRequests.map((pettyRequest, index) => (
                                                <tr key={pettyRequest.id}>
                                                    <td>{index + 1}</td>
                                                    <td> {format(new Date(pettyRequest.created_on), "dd/MM/yy")} </td>
                                                    <td>{pettyRequest.title}</td>
                                                    <td>{pettyRequest.receiver_name}</td>
                                                    <td>{formatCurrency(parseFloat(pettyRequest.amount))}</td>
                                                    <td>{pettyRequest.status}</td>
                                                    <td style={{ padding: '0', verticalAlign: 'middle' }}>
                                                        <Dropdown show={utilityRequestDropdownOpen[index]} onToggle={() => toggleUtilityRequestDropdown(index)}>
                                                            <Dropdown.Toggle className="dropdown-toggle" variant="transparent" style={{ background: 'none', border: 'none', boxShadow: 'none' }}>
                                                                <img src={Action} alt="Action" className="action-icon mb-3" style={{ margin: '0', padding: '0' }} />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => handleOpenEditUtilityRequestModal(pettyRequest.id)}>Edit</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => handleOpenViewUtilityRequestModal(pettyRequest.id)}>View</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => handleOpenReviewUtilityRequestModal(pettyRequest.id)}>Review</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => handleOpenApproveUtilityRequestModal(pettyRequest.id)}>Approve</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => handleOpenRejectUtilityRequestModal(pettyRequest.id)}>Reject</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={6} className="text-center">No petty cash requests found.</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        )}

                    </div>
                </div>
                <CreateMaterialRequest show={createMaterialRequestModalOpen} closeModal={handleCloseCreateMaterialRequestModal} onCreateSuccess={handleCreateSuccess} projectId={projectId} />
                <EditMaterialRequest show={editMaterialRequestModalOpen} closeModal={handleCloseEditMaterialRequestModal} onEditSuccess={handleEditSuccess} projectId={projectId!} materialRequestId={selectedMaterialRequestId!} />
                <ViewMaterialRequest show={viewMaterialRequestModalOpen} closeModal={handleCloseViewMaterialRequestModal} projectId={projectId!} materialRequestId={selectedMaterialRequestId!} />
                <ReviewMaterialRequest show={reviewMaterialRequestModalOpen} closeModal={handleCloseReviewMaterialRequestModal} projectId={projectId!} materialRequestId={selectedMaterialRequestId!} />
                <ApproveMaterialRequest show={approveMaterialRequestModalOpen} closeModal={handleCloseApproveMaterialRequestModal} projectId={projectId!} materialRequestId={selectedMaterialRequestId!} />
                <RejectMaterialRequest show={rejectMaterialRequestModalOpen} closeModal={handleCloseRejectMaterialRequestModal} projectId={projectId!} materialRequestId={selectedMaterialRequestId!} />
                <CreateLabourRequest show={createLabourRequestModalOpen} closeModal={handleCloseCreateLabourRequestModal} onCreateSuccess={handleCreateSuccess} projectId={projectId} />
                <EditLabourRequest show={editLabourRequestModalOpen} closeModal={handleCloseEditLabourRequestModal} onEditSuccess={handleEditSuccess} projectId={projectId!} labourRequestId={selectedLabourRequestId!} />
                <ViewLabourRequest show={viewLabourRequestModalOpen} closeModal={handleCloseViewLabourRequestModal} projectId={projectId!} labourRequestId={selectedLabourRequestId!} />
                <ReviewLabourRequest show={reviewLabourRequestModalOpen} closeModal={handleCloseReviewLabourRequestModal} projectId={projectId!} labourRequestId={selectedLabourRequestId!} />
                <ApproveLabourRequest show={approveLabourRequestModalOpen} closeModal={handleCloseApproveLabourRequestModal} projectId={projectId!} labourRequestId={selectedLabourRequestId!} />
                <RejectLabourRequest show={rejectLabourRequestModalOpen} closeModal={handleCloseRejectLabourRequestModal} projectId={projectId!} labourRequestId={selectedLabourRequestId!} />
                <CreateUtilityRequest show={createUtilityRequestModalOpen} closeModal={handleCloseCreateUtilityRequestModal} onCreateSuccess={handleCreateSuccess} projectId={projectId} />
                <EditUtilityRequest show={editUtilityRequestModalOpen} closeModal={handleCloseEditUtilityRequestModal} onEditSuccess={handleEditSuccess} projectId={projectId!} pettyRequestId={selectedPettyRequestId!} />
                <ViewUtilityRequest show={viewUtilityRequestModalOpen} closeModal={handleCloseViewUtilityRequestModal} projectId={projectId!} pettyRequestId={selectedPettyRequestId!} />
                <ReviewUtilityRequest show={reviewUtilityRequestModalOpen} closeModal={handleCloseReviewUtilityRequestModal} projectId={projectId!} pettyRequestId={selectedPettyRequestId!} />
                <ApproveUtilityRequest show={approveUtilityRequestModalOpen} closeModal={handleCloseApproveUtilityRequestModal} projectId={projectId!} pettyRequestId={selectedPettyRequestId!} />
                <RejectUtilityRequest show={rejectUtilityRequestModalOpen} closeModal={handleCloseRejectUtilityRequestModal} projectId={projectId!} pettyRequestId={selectedPettyRequestId!} />
            </Layout>
        </div>)
}

export default Procurement;
