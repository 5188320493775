import React from "react";
import { Modal, Button, Row, Col, Container, Form } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";

import { toast } from "react-toastify";
import { useAppDispatch } from "store/hooks";
import { confirmAnInventoryAsync } from "slices/inventory/inventorySlice";

interface ConfirmOrderValues {
	confirmed_quantity: number;
	comment: string;
}
interface ConfirmOrderProps {
	closeModal: () => void;
	show: boolean;
	inventoryItemId: string | null;
	onConfirmSuccess: () => void;
	projectId: string | null | undefined;
}

const ConfirmOrder: React.FC<ConfirmOrderProps> = ({
	closeModal,
	show,
	projectId,
	inventoryItemId,
	onConfirmSuccess,
}) => {
	const companyId = localStorage.getItem("userCompanyID");
	const dispatch = useAppDispatch();

	return (
		<Container fluid className="">
			<Modal
				size="lg"
				show={show}
				onHide={closeModal}
				style={{ maxHeight: "100vh", overflowY: "auto" }}
			>
				<div className="px-5">
					<Formik
						initialValues={{
							confirmed_quantity: 0,
							comment: "",
						}}
						validationSchema={Yup.object().shape({
							confirmed_quantity: Yup.number().required("Required").min(0),
							comment: Yup.string(),
						})}
						onSubmit={(values: ConfirmOrderValues, { setSubmitting }) => {
							// console.log({
							// 	...values,
							// 	projectId: `${projectId}`,
							// 	company_id: `${companyId}`,
							// 	id: `${inventoryItemId}`,
							// });
							if (companyId && projectId) {
								dispatch(
									confirmAnInventoryAsync({
										...values,
										projectId: `${projectId}`,
										company_id: `${companyId}`,
										id: `${inventoryItemId}`,
									}),
								)
									.unwrap()
									.then((response) => {
										toast.success(response.message);
										onConfirmSuccess();
										closeModal();
									})
									.catch((error) => {})
									.catch((error) => {
										const errorMessage = Object.values(error.message)
											.flat()
											.join(" ");
										toast.error(errorMessage);
									})
									.finally(() => {
										setSubmitting(false);
									});
							} else {
								console.error("project_id or company_id is undefined");
							}
						}}
						validateOnChange
						validateOnBlur
					>
						{({ errors, touched, handleSubmit, isSubmitting }) => (
							<Form onSubmit={handleSubmit}>
								<Modal.Header className="text-start mt-3">
									<h5>Confirm order</h5>
								</Modal.Header>
								<Modal.Body>
									<Row>
										<Col className="mt-2">
											<label className="mt-2">
												<p>
													Quantity received{" "}
													<span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="number"
												name="confirmed_quantity"
												placeholder="Enter quantity received"
												className={`fluent-input  $</Col>{
													touched.confirmed_quantity &&
													errors.confirmed_quantity
														? "is-invalid"
														: ""
												}`}
												min={0}
												required
											/>
										</Col>
									</Row>
									<Row>
										<Col className="mt-2">
											<label className="mt-2">
												<p>
													Comment <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="comment"
												className={`fluent-input  ${
													touched.comment && errors.comment ? "is-invalid" : ""
												}`}
											/>
										</Col>
									</Row>
									<div className="d-grid mt-1">
										<Button
											className="my-4 py-3 border-0 bg-color-1"
											onClick={() => handleSubmit()}
											// disabled={isSubmitting}
										>
											Confirm
										</Button>
									</div>
								</Modal.Body>
							</Form>
						)}
					</Formik>
				</div>
			</Modal>
		</Container>
	);
};

export default ConfirmOrder;
