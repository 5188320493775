import React from "react";
import { Image } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import DashVector from "../../assets/images/dash-vector.png";

function SideBar() {
	const location = useLocation();
	const storedProjectId = localStorage.getItem("projectId");
	const activeProjectId = storedProjectId;

	return (
		<aside className="sidebar shadow">
			<div className="text-center pt-5">
				<Image src={Logo} width="90px" />
			</div>
			<div className="sidebar-menu">
				<Link
					to={`/dashboard/${activeProjectId}`}
					className={`sidebar-link ${location.pathname === `/dashboard/${activeProjectId}` ? "active" : ""}`}
				>
					<span className="sidebar-text sidebar-link">
						<Image src={DashVector} className="sidebar-icon" />
						Dashboard
					</span>
				</Link>
				<Link
					to={`/no-schedule/${activeProjectId}`}
					className={`sidebar-link ${location.pathname === `/no-schedule/${activeProjectId}` ? "active" : ""}`}
				>
					<span className="sidebar-text sidebar-link">
						<Image src={DashVector} className="sidebar-icon" />
						Project management
					</span>
				</Link>
				<Link
					to={`/budget/${activeProjectId}`}
					className={`sidebar-link ${location.pathname === `/budget/${activeProjectId}` ? "active" : ""}`}
				>
					<span className="sidebar-text sidebar-link">
						<Image src={DashVector} className="sidebar-icon" />
						Budget
					</span>
				</Link>
				<Link
					to={`/procurement/${activeProjectId}`}
					className={`sidebar-link ${location.pathname === `/procurement/${activeProjectId}` ? "active" : ""}`}
				>
					<span className="sidebar-text sidebar-link">
						<Image src={DashVector} className="sidebar-icon" />
						Procurement
					</span>
				</Link>
				<Link
					to={`/accounts/${activeProjectId}`}
					className={`sidebar-link ${location.pathname === `/accounts/${activeProjectId}` ? "active" : ""}`}
				>
					<span className="sidebar-text sidebar-link">
						<Image src={DashVector} className="sidebar-icon" />
						Accounts
					</span>
				</Link>
				<Link
					to={`/reports/${activeProjectId}`}
					className={`sidebar-link ${location.pathname === `/reports/${activeProjectId}` ? "active" : ""}`}
				>
					<span className="sidebar-text sidebar-link">
						<Image src={DashVector} className="sidebar-icon" />
						Reports
					</span>
				</Link>
				<Link
					to={`/inventory/${activeProjectId}`}
					className={`sidebar-link ${location.pathname === `/inventory/${activeProjectId}` ? "active" : ""}`}
				>
					<span className="sidebar-text sidebar-link">
						<Image src={DashVector} className="sidebar-icon" />
						Inventory
					</span>
				</Link>
			</div>
		</aside>
	);
}

export default SideBar;
