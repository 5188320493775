import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Container } from "react-bootstrap";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import {
    getAVendorAsync,
    setVendorId,
} from "../../../../slices/settings/settingsSlice";
import { RootState } from "../../../../store/store";

interface ViewVendorProps {
    closeModal: () => void;
    show: boolean;
    vendorId: string;
}

const ViewVendor: React.FC<ViewVendorProps> = ({ closeModal, show, vendorId, }) => {
    const dispatch = useAppDispatch();
    const companyId = useAppSelector(
        (state: RootState) => state.auth.user?.company_id || "",
    );
    const vendorData = useAppSelector((state: any) => state.settings.vendorData);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (vendorId) {
            dispatch(setVendorId(vendorId));
            console.log("Fetching vendor with ID:", vendorId);
            dispatch(getAVendorAsync({ companyId, id: vendorId })).then(() =>
                setLoading(false),
            );
        } else {
            console.log("vendorId is not set");
        }
    }, [dispatch, companyId, vendorId]);

    if (loading) {
        return null;
    }
    return (
        <div>
            <Container>
                <Modal className="modal-container" size="lg" show={show} onHide={closeModal} style={{ maxHeight: "100vh", overflowY: "auto" }}>
                    <div className='px-3'>
                        <Modal.Header className='text-start'>
                            <h4 className="confirm-header">View vendor</h4>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="rectangle pt-3 px-5">
                                <Row className="mt-1">
                                    <Col>
                                        <p>Vendor name :</p>
                                    </Col>
                                    <Col>
                                        <p>{vendorData.vendor_name}</p>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <p>Account No. :</p>
                                    </Col>
                                    <Col>
                                        <p>{vendorData.vendor_bank_account}</p>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <p>Bank :</p>
                                    </Col>
                                    <Col>
                                        <p>{vendorData.vendor_bank_name}</p>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <p>Email :</p>
                                    </Col>
                                    <Col>
                                        <p>{vendorData.vendor_email}</p>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <p>Phone Number :</p>
                                    </Col>
                                    <Col>
                                        <p>{vendorData.vendor_phone_number}</p>
                                    </Col>
                                </Row>
                            </div>
                        </Modal.Body>
                    </div>
                </Modal>
            </Container>
        </div>
    )
}

export default ViewVendor