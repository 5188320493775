import React, { useEffect } from 'react';
import { Modal, Button, Row, Col, Container, Form, Image } from 'react-bootstrap';
import { Formik, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import Delete from "../../../../assets/images/delete-vector.png";
import { getAllMaterialsAsync, getAllVendorsAsync } from '../../../../slices/settings/settingsSlice';
import { createMaterialRequestAsync } from '../../../../slices/requests/requestsSlice';
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { RootState } from "../../../../store/store";
import { Select, FormControl, InputLabel, MenuItem } from "@mui/material";
import { toast } from "react-toastify";
// import CurrencyInput from '../../../../components/form/currency-input';

interface CreateMaterialRequestValues {
    company_id: string;
    vendor_id: string;
    materials: {
        material_id: string;
        quantity: string;
        unit_cost: string;
    }[];
}

interface CreateMaterialRequestProps {
    closeModal: () => void;
    show: boolean;
    onCreateSuccess: () => void;
    projectId: string | undefined | null;
}

const CreateMaterialRequest: React.FC<CreateMaterialRequestProps> = ({ closeModal, show, onCreateSuccess, projectId }) => {
    const dispatch = useAppDispatch();
    const vendors = useAppSelector((state) => state.settings.vendors);
    const materials = useAppSelector((state) => state.settings.materials);
    const companyId = useAppSelector(
        (state: RootState) => state.auth.user?.company_id || "",
    );
    useEffect(() => {
        if (companyId) {
            dispatch(getAllVendorsAsync(companyId));
        }
    }, [companyId, dispatch]);
    useEffect(() => {
        if (companyId) {
            dispatch(getAllMaterialsAsync(companyId));
        }
    }, [companyId, dispatch]);


    console.log("vendors", vendors);
    console.log("materials", materials);

    return (
        <Container fluid className=''>
            <Modal size="lg" show={show} onHide={closeModal} style={{ maxHeight: "100vh", overflowY: "auto" }}>
                <div className='px-5'>
                    <Formik
                        initialValues={{
                            company_id: companyId || '',
                            vendor_id: '',
                            materials: [{ material_id: '', quantity: '', unit_cost: '' }],
                            projectId: projectId || '',
                        }}
                        validationSchema={Yup.object().shape({
                            materialRequest: Yup.array().of(
                                Yup.object().shape({
                                    material_id: Yup.string().required('Required'),
                                    quantity: Yup.string().required('Required'),
                                    unit_cost: Yup.string().required('Required'),
                                })
                            ),
                            vendor_id: Yup.string().required('Required'),

                        })}
                        onSubmit={(values: CreateMaterialRequestValues, { setSubmitting }) => {
                            console.log(values);

                            const flattenedValues = {
                                company_id: companyId,
                                vendor_id: values.vendor_id,
                                projectId: projectId ?? '',
                                materials: values.materials.map((material) => ({
                                    material_id: material.material_id,
                                    quantity: material.quantity,
                                    unit_cost: material.unit_cost,
                                })),
                            };
                            const addSpacesToMessage = (message: any) => {
                                return message.replace(/([a-z])([A-Z])/g, '$1 $2');
                            };
                            dispatch(
                                createMaterialRequestAsync(flattenedValues)
                            ).unwrap()
                                .then((response) => {
                                    toast.success("Material request created successfully");
                                    onCreateSuccess();
                                    closeModal();
                                })
                                .catch((error) => {
                                    const errorMessage = error.message ? addSpacesToMessage(error.message) : "An unexpected error occurred";
                                    toast.error(errorMessage);
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                });
                        }}

                    >
                        {({ errors, touched, handleSubmit, values, handleChange, handleBlur }) => (
                            <Form onSubmit={handleSubmit}>
                                <Modal.Header className='text-start mt-2'>
                                    <h4 className="confirm-header">Create material request</h4>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row>
                                        <Col md={12} className='mt-2'>
                                            <label className='mt-2'>
                                                <p>Vendor name {" "}
                                                    <span className="text-danger">*</span>
                                                </p>
                                            </label>
                                            <FormControl fullWidth>
                                                <InputLabel>Select vendor</InputLabel>
                                                <Select
                                                    label="Select vendor"
                                                    name="vendor_id"
                                                    value={values.vendor_id}
                                                    onChange={handleChange}
                                                >
                                                    {vendors.map((vendor) => (
                                                        <MenuItem key={vendor.id} value={vendor.id}>
                                                            {vendor.vendor_name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                        </Col>
                                    </Row>
                                    <FieldArray name="materials">
                                        {({ push, remove }) => (
                                            <>
                                                {values.materials.map((_, index) => (
                                                    <Row key={index}>
                                                        <Col md={4} className='mt-2'>
                                                            <label className='mt-2'>
                                                                <p>Material {" "}
                                                                    <span className="text-danger">*</span>
                                                                </p>
                                                            </label>
                                                            <FormControl fullWidth>
                                                                <InputLabel>Select material</InputLabel>
                                                                <Select
                                                                    label="Select material"
                                                                    name={`materials[${index}].material_id`}
                                                                    value={values.materials[index].material_id}
                                                                    onChange={handleChange}
                                                                >
                                                                    {materials.map((material) => (
                                                                        <MenuItem key={material.id} value={material.id}>
                                                                            {material.material_name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>

                                                        </Col>
                                                        <Col md={4} className='mt-2'>
                                                            <label className='mt-2'>
                                                                <p>Quantity {" "}
                                                                    <span className="text-danger">*</span>
                                                                </p>
                                                            </label>
                                                            <Field
                                                                type='number'
                                                                name={`materials[${index}].quantity`}
                                                                className={`fluent-input  ${touched.materials?.[index]?.quantity && errors.materials?.[index]?.toString ? 'is-invalid' : ''}`}
                                                                required
                                                            />
                                                        </Col>
                                                        <Col md={3} className='mt-2'>
                                                            <label className='mt-2'>
                                                                <p>Cost {" "}
                                                                    <span className="text-danger">*</span>
                                                                </p>
                                                            </label>
                                                            <Field
                                                                type='number'
                                                                name={`materials[${index}].unit_cost`}
                                                                className={`fluent-input  ${touched.materials?.[index]?.unit_cost && errors.materials?.[index]?.toString ? 'is-invalid' : ''}`}
                                                                required
                                                            />
                                                            {/* <CurrencyInput
                                                                name={`materials[${index}].unit_cost`}
                                                                placeholder="Enter amount"
                                                                className={`fluent-input  ${touched.materials?.[index]?.unit_cost && errors.materials?.[index].toString ? 'is-invalid' : ''}`}
                                                                value={values.materials[index].unit_cost}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            /> */}
                                                        </Col>
                                                        <Col md={1} className='mt-5'>
                                                            {index > 0 && (
                                                                <Button type="button" onClick={() => remove(index)} className='border-0 mt-3 bg-color-4'>
                                                                    <Image src={Delete} width={30} height={30} />
                                                                </Button>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                ))}
                                                <Row>
                                                    <Col>
                                                        <div className='mt-3'>
                                                            <Button type='button' onClick={() => push({ material_id: '', quantity: '', unit_cost: '' })} className='border-0 add-more-btn'>
                                                                <span className="text-center">Add </span>
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                    </FieldArray>
                                </Modal.Body>
                                <Modal.Footer className='mt-5'>
                                    <Row >
                                        <Col md={6}>
                                            <div className=' mt-1'>
                                                <Button onClick={closeModal} className='cancel-btn'>
                                                    <span className="text-center">Cancel </span>
                                                </Button>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className=' mt-1'>
                                                <Button type='submit' className=' border-0 create-btn bg-color-1'>
                                                    <span className="text-center">Create request</span>
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </Container>
    );
};

export default CreateMaterialRequest;
